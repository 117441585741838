/* eslint-disable */

/**
 * @file Renders managed service table according to selected type
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import arrowLeft from '../static/icon_arrow_left.svg';
import iconEdit from '../static/icon_editar.svg';
import iconDelete from '../static/icon_lixo.svg';
import iconLeft from '../static/icon_left.svg';
import iconRight from '../static/icon_right.svg';
import moment from 'moment';
import Select from 'react-select';
import { useLocation, useParams, useHistory } from "react-router-dom";

import {
  Container,
  Wrapper,
  Section,
  H3,
  H6,
  Loading,
  Checkbox,
  FileUpload,
} from '../atoms';

import {
  Modal,
  Table,
} from '../molecules';


/**
 * Manages rendering of managed service table according to selected type
 * 
 * @component ManagementType
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */
export default function ManagementType(props) {

/**
 * NEEDED INITIALIZATIONS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [token, setToken] = useState('');
  

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */    

  let location = useLocation();
  let params = useParams();
  let history = useHistory();
  let bilheteiraId = sessionStorage.getItem('bilheteira');

  const {
    pathname
  } = location; 

  let serviceId = params.id
  //let serviceId = props.match.params.id;
  let user = props.user;
  let userProfile = props.userProfile;    
  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;

  console.log('management type BACKEND_DOMAIN -> ',BACKEND_DOMAIN )

  let id
  
  if (params.idSeason) {
    id = params.idSeason
  }

  let typeManagement = params.type;

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  let optionsRows = [
    { value: 5, label: '5 items p/page' },
    { value: 10, label: '10 items p/page' },
    { value: 25, label: '25 items p/page' },
    { value: 50, label: '50 items p/page' },
  ];

  let tokenRequest = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [dataBlocks, setDataBlocks] = useState([]);
  const [dataEvents, setDataEvents] = useState([]);
  const [dataDiscounts, setDataDiscounts] = useState([]);
  const [dataDays, setDataDays] = useState([]);
  const [dataCoupons, setDataCoupons] = useState([]);
  const [dataSeasons, setDataSeasons] = useState([]);
  const [dataSlotsLanguages, setDataSlotsLanguages] = useState([]);
  const [dataTicketTypes, setDataTicketTypes] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [columns, setColumns] = useState([]);

  const [date, setDate] = useState(tomorrow);

  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [noContent, setNoContent] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50);

  const [dayWeek, setDayWeek] = useState([
    { value: 'segunda', label: 'segunda' },
    { value: 'terca', label: 'terca' },
    { value: 'quarta', label: 'quarta' },
    { value: 'quinta', label: 'quinta' },
    { value: 'sexta', label: 'sexta' },
    { value: 'sabado', label: 'sabado' },
    { value: 'domingo', label: 'domingo' }
  ]);

  const [dayWeekFiltred, setDayWeekFiltred] = useState([]);


/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  useEffect(() => {
    getCredentials();
  }, [typeManagement, id]);


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */


/**
 * @callback handleClick
 * 
 * @param ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const handleClick = event => {
    setCurrentPage(Number(event.target.id));
  };

 /**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */  


/**
 * @function checkNameColumns
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const checkNameColumns = (type) => {
    if (type === 'blocks') {
      setTitle('Blocks');
      setColumns([
        { title: '#id' },
        { title: 'Date' },
        { title: 'Slot' },
        { title: 'Visible' },
        { title: '' }
      ])
    } else if (type === 'events') {
      setTitle('Single Events');
      setColumns([
        { title: '#id' },
        { title: 'Date' },
        { title: 'Slot' },
        { title: '' }
      ])
    } else if (type === 'discounts') {
      setTitle('Discounts');
      setColumns([
        { title: '#id' },
        { title: 'About' },
        { title: 'Comparator' },
        { title: 'Number Tickets' },
        { title: 'Value p/ticket' },
        { title: '' }
      ])
    } else if (type === 'days') {
      setTitle('Days');
      setColumns([
        { title: '#id' },
        { title: 'Day week' },
        { title: 'Start time' },
        { title: 'Finish time' },
        { title: 'Number Tickets' },
        { title: 'Active' },
        { title: '' }
      ])
    } else if (type === 'coupons') {
      setTitle('Coupons');
      setColumns([
        { title: '#id' },
        { title: 'Name' },
        { title: 'Code' },
        { title: 'Price p/ticket' },
        { title: 'Start date' },
        { title: 'End date' },
        { title: 'Utilizations' },
        { title: 'Active' },
        { title: '' }
      ])
    } else if (type === 'seasons') {
      setTitle('Seasons');
      setColumns([
        { title: '#id' },
        { title: 'Title' },
        { title: 'Start date' },
        { title: 'End date' },
        { title: 'Ticket Types' },
        { title: 'Ticket Price' },
        { title: 'Discount Price' },  
        { title: 'Discount Percentage' },
        { title: 'Discount Quantity' },
        { title: 'Language' },        
        { title: '' }
      ]);
    } else if (type === 'slotsLanguages') {
      setTitle('Slots Languages');
      setColumns([
        { title: '#id' },
        { title: 'Date' },
        { title: 'Start Time' },
        { title: 'Language Code' },       
        { title: 'Language' },
        { title: '' }
      ])
    } else if (type === 'languages') {
      setTitle('Languages');
      setColumns([
        { title: '#id' },
        { title: 'Service' },        
        { title: 'Language' },
        { title: 'Code' }
      ])
    } else if (type === 'ticketTypes') {
      setTitle('Ticket Types');
      setColumns([
        { title: '#id' },
        { title: 'Ticket Type' },
        { title: 'Price' },
        { title: 'Season' },       
        { title: 'Active' },
        { title: '' }
      ])
    }
  };


/**
 * @function changeCheckAtive
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const changeCheckAtive = (data) => {
    axios
      .put(BACKEND_DOMAIN + "/dias/" + data.id,
        {
          ativo: !data.ativo
        }, tokenRequest
      )
      .then(response => {
        setDataDays(dataDays.map(x => {
          if (x.id !== data.id) return x
          return { ...x, ativo: !data.ativo }
        }));
      })
      .catch(error => {
      });
  }

/**
 * @function changeCheckAtiveCoupon
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const changeCheckAtiveCoupon = (data) => {
    axios
      .put(BACKEND_DOMAIN + "/codigos/" + data.id,
        {
          ativo: !data.ativo
        }, tokenRequest
      )
      .then(response => {
        setDataCoupons(dataCoupons.map(x => {
          if (x.id !== data.id) return x
          return { ...x, ativo: !data.ativo }
        }));
      })
      .catch(error => {
      });
  }

/**
 * @function getCredentials
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token');
    setDataBlocks([]);
    setDataEvents([]);    
    setDataDiscounts([]);
    setDataDays([]);
    setDataSeasons([]);
    setToken(temp);
    getData(temp);
  }

/**
 * @function getData
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getData = (key) => {
    setLoading(true);
    if (typeManagement === 'blocks') {
      checkNameColumns('blocks')

      axios
        .get(BACKEND_DOMAIN + "/bloqueios?servico=" + serviceId + '&_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)

          let tempArr = response.data

          tempArr.sort(function (a, b) {
            if (a.data > b.data) {
              return -1
            }
            if (a.data < b.data) {
              return 1
            }
            return 0
          })

          console.log(tempArr)

          setDataBlocks(tempArr)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
        })
    } else if (typeManagement === 'events') {
      checkNameColumns('events')

      axios
        .get(BACKEND_DOMAIN + "/slots/?servico=" + serviceId + '&_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)

          let tempArr = response.data

          tempArr.sort(function (a, b) {
            if (a.data > b.data) {
              return -1
            }
            if (a.data < b.data) {
              return 1
            }
            return 0
          })

          console.log(tempArr)
          
          setDataEvents(tempArr)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false);
        });
    } else if (typeManagement === 'discounts') {
      checkNameColumns('discounts')

      axios
        .get(BACKEND_DOMAIN + "/epocas/" + id + '?_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          setDataDiscounts(response.data.descontos);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    } else if (typeManagement === 'days') {
      checkNameColumns('days')

      axios
        .get(BACKEND_DOMAIN + "/epocas/" + id + '?_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          setDataDays(response.data.dias);
          let temp = [];
          temp = dayWeek;
          for (let i = 0; i < response.data.dias.length; i++) {
            temp = temp.filter(function (obj) {
              return obj.value !== response.data.dias[i].diaSemana;
            });
          }
          setDayWeekFiltred(temp);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    } else if (typeManagement === 'seasons') {
      checkNameColumns('seasons');
      axios
        .get(BACKEND_DOMAIN + "/epocas?servico=" + serviceId + '&_limit=-1',
          {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          })
        .then(response => {
          console.log(response.data)

          setDataSeasons(response.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    } else if (typeManagement === 'slotsLanguages') {
      checkNameColumns('slotsLanguages');
        axios.get(BACKEND_DOMAIN + "/lingua-slots?servico=" + serviceId +  '&_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)

          let tempArr = response.data

          tempArr.sort(function (a, b) {
            if (a.id > b.id) {
              return -1;
            }
            if (a.id < b.id) {
              return 1;
            }
            return 0;
          });

          console.log(tempArr)          

          setDataSlotsLanguages(tempArr)

          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });   
    } else if (typeManagement === 'ticketTypes') {
      checkNameColumns('ticketTypes');
        axios.get(BACKEND_DOMAIN + "/tipo-bilhetes?servico=" + serviceId +  '&_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data);

          let tempArr = response.data;
          tempArr.sort(function (a, b) {
            if (a.Data > b.id) {
              return -1;
            }
            if (a.Data < b.id) {
              return 1;
            }
            return 0;
          });

          console.log(tempArr)

          setDataTicketTypes(tempArr);

          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });     
    } else if (typeManagement === 'coupons') {
      checkNameColumns('coupons');
      axios
        .get(BACKEND_DOMAIN + "/codigos?servico=" + serviceId + '&_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          setDataCoupons(response.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    } else if (typeManagement === 'languages') {
      checkNameColumns('languages');
      axios
        .get(BACKEND_DOMAIN + "/linguas/", {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          setAvailableLanguages(response.data);
          console.log(response.data);
          setLoading(false);
        })
        .then(console.log(availableLanguages))
        .catch(error => {
          setLoading(false);
        });
    } else {
      setNoContent(true);
    }
  }

/**
 * @function passData
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const passData = (data, type) => {
    setDataModal(
      {
        content: data,
        typeContent: type,
        token: token,
        seasonId: id ? id : '',
        daysAvailable: dayWeekFiltred.length > 0 ? dayWeekFiltred : undefined
      }
    );

    setTimeout(toogleModal(), 500);
  };

/**
 * @function toogleModal
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };


  let currentData = {};
  let pageNumbers = [];
  let indexOfLast = currentPage * itemPerPage;
  let indexOfFirst = indexOfLast - itemPerPage;

  console.log(dataSlotsLanguages);
  console.log(dataBlocks);

  if (dataBlocks && dataBlocks.length > 0) {
    currentData = dataBlocks.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataBlocks.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (dataEvents && dataEvents.length > 0) {
    currentData = dataEvents.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataEvents.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (dataDiscounts && dataDiscounts.length > 0) {
    currentData = dataDiscounts.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataDiscounts.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (dataDays && dataDays.length > 0) {
    currentData = dataDays.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataDays.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (dataSeasons && dataSeasons.length > 0) {
    currentData = dataSeasons.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataSeasons.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (dataCoupons && dataCoupons.length > 0) {
    currentData = dataCoupons.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataCoupons.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (dataSlotsLanguages && dataSlotsLanguages.length > 0) {
    currentData = dataSlotsLanguages.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataSlotsLanguages.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (availableLanguages && availableLanguages.length > 0) {
    currentData = availableLanguages.slice(indexOfFirst, indexOfLast)

    console.log(currentData);
    for (let i = 1; i <= Math.ceil(availableLanguages.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  } else if (dataTicketTypes && dataTicketTypes.length > 0) {
    currentData = dataTicketTypes.slice(indexOfFirst, indexOfLast)

    for (let i = 1; i <= Math.ceil(dataTicketTypes.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  }


/**
 * @function prevPage
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


/**
 * @function nextPage
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */   
  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };


/**
 * @function daysSeason
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const daysSeason = (body) => {
    history.push({
      pathname: '/service/' + serviceId + '/management/season/' + body.id + '/days',
      seasonId: body.id,
      token: token
    });
  }


/**
 * @function discountsSeason
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const discountsSeason = (body) => {
    history.push({
      pathname: '/service/' + serviceId + '/management/season/' + body.id + '/discounts',
      seasonId: body.id,
      token: token
    });
  }


  return (
    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper>
          <Container>
            {(typeManagement === 'days' || typeManagement === 'discounts') &&
              <BackButton onClick={() => history.push('/service/' + serviceId + '/management/seasons')}><IconSVG src={arrowLeft} />Back</BackButton>
            }
            {(typeManagement !== 'days' && typeManagement !== 'discounts') &&
              <BackButton onClick={() => history.push('/service/' + serviceId + '/management')}><IconSVG src={arrowLeft} />Back</BackButton>
            }
            <DivHeader>
              <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
                {title}
              </H3>
              {typeManagement === 'events' &&
                <DivButtonsDays>
                  <ButtonAdd onClick={() => passData('', 'rangeEvents')}>
                    Create Range
                  </ButtonAdd>
                  <ButtonAdd onClick={() => passData('', 'addEvent')}>
                    Create New
                  </ButtonAdd>
                </DivButtonsDays>
              }              
              {typeManagement === 'blocks' &&
                <DivButtonsDays>
                  <ButtonAdd onClick={() => passData('', 'bulkBlock')}>
                    Block Range
                  </ButtonAdd>
                  <ButtonAdd onClick={() => passData('', 'addBlock')}>
                    Create New
                  </ButtonAdd>
                </DivButtonsDays>
              }
              {typeManagement === 'discounts' &&
                <ButtonAdd onClick={() => passData('', 'addDiscount')}>
                  Create New
                </ButtonAdd>
              }
              {typeManagement === 'coupons' &&
                <ButtonAdd onClick={() => passData('', 'addCoupon')}>
                  Create New
                </ButtonAdd>
              }
              {typeManagement === 'seasons' &&
                <ButtonAdd onClick={() => passData('', 'addSeason')}>
                  Create New
                </ButtonAdd>
              }
              {typeManagement === 'slotsLanguages' &&
                <ButtonAdd onClick={() => passData('', 'addSlotLanguage')}>
                  Create New
                </ButtonAdd>
              }
              {typeManagement === 'ticketTypes' &&
                <ButtonAdd onClick={() => passData('', 'addTicketType')}>
                  Create New
                </ButtonAdd>
              }
              {typeManagement === 'days' &&
                <>
                  {(dataDays && dataDays.length === 0) &&
                    <DivButtonsDays>
                      <ButtonAdd onClick={() => passData('', 'bulkDay')}>
                        Add Working Days
                      </ButtonAdd>
                      <ButtonAdd onClick={() => passData('', 'addDay')} disabled={dayWeek.length > 0 ? false : true}>
                        Create New
                      </ButtonAdd>
                    </DivButtonsDays>
                  }
                  {(dataDays && dataDays.length > 0) &&
                    <ButtonAdd onClick={() => passData('', 'addDay')} disabled={dayWeek.length > 0 ? false : true}>
                      Create New
                    </ButtonAdd>
                  }
                </>

              }

            </DivHeader>
            <DivTabela>
              <Table>
                <thead>
                  <tr>
                    {columns.map((header, index) => (
                      <th key={String(index)}>{header.title}</th>
                    ))}
                  </tr>
                </thead>

                {console.log(currentData)}  
                {(typeManagement == 'events' && dataEvents.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      <tr key={String(index)}>                      
                        <td>{body.id}</td>
                        <td>{body.data}</td>
                        <td>{body.hora !== null && (/\d{2}:\d{2}/.exec(body.hora))}</td>                       
                        <td className='buttons'>
                          <ButtonEdit onClick={() => passData(body, 'editEvent')}>
                            <Icon src={iconEdit} />Edit
                        </ButtonEdit>
                        <ButtonDelete onClick={() => passData(body, 'deleteEvent')}>
                            <Icon src={iconDelete} />Delete
                        </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
                {(typeManagement == 'blocks' && dataBlocks.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      <tr key={String(index)}>                      
                        <td>{body.id}</td>
                        <td>{body.data}</td>
                        <td>{body.slot !== null && (/\d{2}:\d{2}/.exec(body.slot))}</td>
                        <td>{body.visivel ? "Sim" : "Não"}</td>                        
                        <td className='buttons'>
                          <ButtonEdit onClick={() => passData(body, 'editBlock')}>
                            <Icon src={iconEdit} />Edit
                        </ButtonEdit>
                        <ButtonDelete onClick={() => passData(body, 'deleteBlock')}>
                            <Icon src={iconDelete} />Delete
                        </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
                {(typeManagement == 'discounts' && dataDiscounts.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      <tr key={String(index)}>
                        <td>{body.id}</td>
                        <td>{body.Sobre}</td>
                        <td>{body.comparador}</td>
                        <td>{body.valor}</td>
                        <td>{body.desconto}</td>
                        <td className='buttons'>
                          <ButtonEdit onClick={() => passData(body, 'editDiscount')}>
                            <Icon src={iconEdit} />Edit
                        </ButtonEdit>
                          <ButtonDelete onClick={() => passData(body, 'deleteDiscount')}>
                            <Icon src={iconDelete} />Delete
                        </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
                {(typeManagement == 'days' && dataDays.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      <tr key={String(index)}>
                        <td>{body.id}</td>
                        <td>{body.diaSemana}</td>
                        <td>{moment.utc(body.horaInicio, 'HH:mm').format('HH:mm')}</td>
                        <td>{moment.utc(body.horaFim, 'HH:mm').format('HH:mm')}</td>
                        <td>{body.numBilhetes}</td>
                        <td>
                          <DivCheckbox checked={body.ativo} onClick={() => changeCheckAtive(body)} >
                            <Checkbox checked={body.ativo} />Yes
                          </DivCheckbox>
                        </td>
                        <td className='buttons'>
                          <ButtonEdit onClick={() => passData(body, 'editDay')}>
                            <Icon src={iconEdit} />Edit
                        </ButtonEdit>
                          <ButtonDelete onClick={() => passData(body, 'deleteDay')}>
                            <Icon src={iconDelete} />Delete
                        </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
                {(typeManagement == 'coupons' && dataCoupons.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      <tr key={String(index)}>
                        <td>{body.id}</td>
                        <td>{body.nome}</td>
                        <td>{body.codigo}</td>
                        <td>{body.desconto}</td>
                        <td>{body.dataInicio}</td>
                        <td>{body.dataFim}</td>
                        <td>{body.utilizacoes}</td>
                        <td>
                          <DivCheckbox checked={body.ativo} onClick={() => changeCheckAtiveCoupon(body)} >
                            <Checkbox checked={body.ativo} />Yes
                        </DivCheckbox>
                        </td>
                        <td className='buttons'>
                          <ButtonEdit onClick={() => passData(body, 'editCoupon')}>
                            <Icon src={iconEdit} />Edit
                        </ButtonEdit>
                          <ButtonDelete onClick={() => passData(body, 'deleteCoupon')}>
                            <Icon src={iconDelete} />Delete
                        </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
                {(typeManagement == 'seasons' && dataSeasons.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      <tr key={String(index)}>
                        <td>{body.id}</td>
                        <td>{body.Text}</td>
                        <td>{body.DataInicio}</td>
                        <td>{body.DataFim}</td>
                        <td>{(body.variedadeBilhetes == 'Multiple' && 'Multiple')||'Unique'}</td>
                        <td>{(body.variedadeBilhetes == 'Multiple' && 'N/A') || body.PrecoUnitario}</td>
                        <td>{body.PrecoDesconto}</td>
                        <td>{body.PercentagemDesconto}</td>
                        <td>{body.QuantidadeDesconto}</td>                        
                        <td>{body.lingua == undefined ? '' : body.lingua.Codigo}</td>
                        <td className='buttons'>
                        {/*}  <ButtonEdit onClick={() => discountsSeason(body)} style={{ marginRight: '20px' }}>
                            Discounts
                          </ButtonEdit>*/}
                          <ButtonEdit onClick={() => daysSeason(body)} style={{ marginRight: '20px' }}>
                            Days
                          </ButtonEdit>
                          <ButtonEdit onClick={() => passData(body, 'editSeason')}>
                            <Icon src={iconEdit} />Edit
                          </ButtonEdit>
                          <ButtonDelete onClick={() => passData(body, 'deleteSeason')}>
                            <Icon src={iconDelete} />Delete
                          </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }               
                {(typeManagement == 'slotsLanguages' && dataSlotsLanguages.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      <tr key={String(index)}>
                        <td>{body.id}</td>
                        <td>{body.Data}</td>
                        <td>{body.slot !== null && (/\d{2}:\d{2}/.exec(body.slot))}</td>
                        <td>{body.lingua ? body.lingua.Codigo : ""}</td>
                        <td className='buttons'>
                          <ButtonEdit onClick={() => passData(body, 'editSlotLanguage')}>
                            <Icon src={iconEdit} />Edit
                          </ButtonEdit>
                          <ButtonDelete onClick={() => passData(body, 'deleteSlotLanguage')}>
                            <Icon src={iconDelete} />Delete
                          </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
                {(typeManagement == 'ticketTypes' && dataTicketTypes.length > 0) &&
                  <tbody>
                    {currentData.map((body, index) => (
                      body.epoca != null &&
                      <tr key={String(index)}>
                        <td>{body.id}</td>
                        <td>{body.tipoBilheteEN}</td>
                        <td>{body.precoBilhete}</td>
                        <td>{body.epoca.Text}</td>
                        <td>{body.tipoBilheteAtivo && <span> ✅ </span>}</td>
                        <td className='buttons'>
                          <ButtonEdit onClick={() => passData(body, 'editTicketType')}>
                            <Icon src={iconEdit} />Edit
                          </ButtonEdit>
                          <ButtonDelete onClick={() => passData(body, 'deleteTicketType')}>
                            <Icon src={iconDelete} />Delete
                          </ButtonDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }                     
              </Table>
            </DivTabela>
            {currentData.length === 0 &&
              <H6 style={{ padding: '40px 0', border: '1px solid #E4EBF0' }} font='SFRegular' align="center" dangerouslySetInnerHTML={{ __html: 'No items to display' }} />
            }
            <DivFooter>
              <DropdownItems
                rows={true}
                placeholder='Rows'
                name='rows'
                classNamePrefix='selectBox'
                options={optionsRows}
                onChange={optionsRows => setItemPerPage(optionsRows.value)}
                value={optionsRows.filter(data => (data.value === itemPerPage)) ? optionsRows.filter(data => (data.value === itemPerPage)) : ''}
              />
              <DivPagination>
                <Arrow onClick={prevPage} left>
                  <img src={iconLeft} />
                </Arrow>
                <Arrow onClick={nextPage} right>
                  <img src={iconRight} />
                </Arrow>
                {pageNumbers.map((number, index) => (
                  <NumberText
                    key={String(index)}
                    id={number.value}
                    onClick={handleClick}
                    className={currentPage === number.value ? "selected" : ""}
                  >
                    {number.label}
                  </NumberText>
                ))}
              </DivPagination>
            </DivFooter>
            {noContent &&
              <>no content</>
            }
          </Container>
          {isOpen == true &&
            <Modal
              isOpen={isOpen}
              {...dataModal}
              idBilheteira={bilheteiraId}
              serviceId={serviceId}
              toogleModal={toogleModal}
              blocks={dataBlocks}
              setBlocks={setDataBlocks}
              events={dataEvents}
              setEvents={setDataEvents}
              discounts={dataDiscounts}
              setDiscounts={setDataDiscounts}
              days={dataDays}
              setDays={setDataDays}
              seasons={dataSeasons}
              setSeasons={setDataSeasons}
              coupons={dataCoupons}
              setCoupons={setDataCoupons}
              slotsLanguages={dataSlotsLanguages}
              setSlotsLanguages={setDataSlotsLanguages}   
              ticketTypes={dataTicketTypes}
              setTicketTypes={setDataTicketTypes}           
              BACKEND_DOMAIN={BACKEND_DOMAIN}
            />
          }
        </Wrapper>
      }
    </Section>
  )
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */
 
const BackButton = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:114px;
  padding:12px 24px;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  background: #EBEBEB;
  transition: all 0.5s ease;
  :hover{
    background: #A9A9A9;
    cursor:pointer;
  }
`;
const IconSVG = styled.img`
  width:5px;
  height:9px;
`;
const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin:24px 0;
  @media screen and (max-width:570px){
    flex-flow:column;
    align-items:flex-start;
  }
`;
const DivTabela = styled.div`
width:100%;
overflow:scroll;
height:calc(300px + (650 - 400) * ((100vw - 760px) / (1920 - 760)));
@media screen and (max-width: 759px){
  height:350px;
}
`;
const DivCheckbox = styled.div`
  max-width: 78px;
  display:flex;
  flex-flow:row;
  align-items:center;
  padding: 8px 17px;
  box-sizing:border-box;
  background: #EBEBEB;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #A9A9A9;
  :hover{
    background: #F8C100;
    cursor:pointer;
  }
  ${({ checked }) => checked && css`
    background:#FFCE20;
    color: #373737;
  `}
`;

const Icon = styled.img`
  width:14px;
  height:14px;
  margin-right:8px;
`;
const DivButtonsDays = styled.div`
  display:flex;
  flex-flow:row;
  div{
    :first-child{
      margin-right:16px;
    }
  }
  @media screen and (max-width:570px){
    flex-flow:column;
    div{
      :first-child{
        margin-right:0;
        margin-bottom:16px;
      }
    }
  }
`;
const ButtonAdd = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 16px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  height:48px;
  box-sizing: border-box;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
  ${({ disabled }) => disabled == true && css`
    pointer-events: none;
    background: #A9A9A9;
    :hover{
      background: #A9A9A9;
      cursor: block;
    }
  `}
  @media screen and (max-width:570px){
    padding: 16px 40px;
    margin-top:8px;
  }
`;
const ButtonEdit = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;
const ButtonDelete = styled.div`
  margin-left: 20px;
  background: #EBEBEB;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #A9A9A9;
    cursor: pointer;
  }
`;
const DivPagination = styled.div`
  margin-top:-10px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50px;
  flex-wrap:wrap;
`;
const NumberText = styled.div`
  height:48px;
  width:48px;
  margin-right: 10px;
  margin-top: 10px;
  background: #EBEBEB;
  border:1px solid #EBEBEB;
  color: #A9A9A9;
  box-sizing: border-box;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.4s ease;
  &.selected {
    color: #373737;
    background: #FFCE20;
  }
  :last-of-type {
    margin-right: 0;
  }
  :hover{
    cursor: pointer;
    color: #373737;
    border:1px solid #A9A9A9;
  }
`;
const Arrow = styled.div`
  height:48px;
  width:48px;
  border:1px solid #EBEBEB;
  background: #EBEBEB;
  box-sizing:border-box;
  border-radius: 2px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 10px;
  color:#A9A9A9;
  transition: all 0.4s ease;
  ${({ left }) => left && css`
    margin-right: 10px;
  `}
  ${({ right }) => right && css`
    margin-right: 10px;
  `}
  :hover{
    cursor:pointer;
    border:1px solid #A9A9A9;
    color:#373737;
  }
`;
const DropdownItems = styled(Select)`
  width: 250px;
  min-width:200px;
  font-family: SFMedium;
  margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  margin-right: ${({ rows }) => rows ? '16px' : '0'};
  div{
    border-color: #E4EBF0;
    min-height:16px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    top:-160px;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        min-height:36px;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  }
`;
const DivFooter = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:14px;
`;