/* eslint-disable */

/**
 * @file Renders mail instuctions modal
 */

import React, { useState, useEffect, useReducer } from "react";
import styled, { css } from "styled-components";
import Select from 'react-select';
import { createGlobalStyle } from 'styled-components';
import iconClose from '../../static/icon_close.svg';

import {
  Margin,
  H3,
  H5,
  H6,
  Text14,
  Text12,
  Text11,
  Text10,
} from '../../atoms';


/**
 * Manages rendering mail instructions modal
 * 
 * @component Schedule
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - management.js  
 * 
 */

export default function ModalEmail(props) {

  const {
    isOpen,
    setInstructions,
    serviceId,
  } = props;


  return (
    <DivModal isOpen={isOpen}>
      <Content>
        <ButtonClose onClick={() => setInstructions(false)}>
          <img src={iconClose}/>
        </ButtonClose>
        <Container>
          <H5
            dangerouslySetInnerHTML={{ __html: 'Instructions for Email' }}
          />
          <Margin margin={2}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[name] : matches the name made on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[date] : matches the date choosen on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[slot] : matches the slot choosen on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[amount] : matches the amount made on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[unitPrice] : matches the unit price shown at the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[totalPrice] : matches the total price shown at the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[email] : matches the email made on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[country] : matches the country inserted on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[contact] : matches the contact inserted on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[taxID] : matches the tax id inserted on the reservation' }}
          />
          <Margin margin={1}/>
          <Text12
            dangerouslySetInnerHTML={{ __html: '[specialRequest] : matches the special request inserted on the reservation' }}
          />
        // Custom fields for Alfandega da Fé - Grande Encontro Stand-up Paddle e Kayak
          {serviceId == 20 &&
            <>
              <Text12
                dangerouslySetInnerHTML={{ __html: '[age] : matches the total price shown at the reservation' }}
              />
              <Margin margin={1}/>
              <Text12
                dangerouslySetInnerHTML={{ __html: '[sex] : matches the email made on the reservation' }}
              />
              <Text12
                dangerouslySetInnerHTML={{ __html: '[id] : matches the email made on the reservation' }}
              />              
              <Margin margin={1}/>
              <Text12
                dangerouslySetInnerHTML={{ __html: '[location] : matches the country inserted on the reservation' }}
              />
              <Margin margin={1}/>
              <Text12
                dangerouslySetInnerHTML={{ __html: '[emergencyContact] : matches the contact inserted on the reservation' }}
              />
              <Margin margin={1}/>
              <Text12
                dangerouslySetInnerHTML={{ __html: '[modality] : matches the tax id inserted on the reservation' }}
              />
              <Margin margin={1}/>
              <Text12
                dangerouslySetInnerHTML={{ __html: '[equipment] : matches the special request inserted on the reservation' }}
              /> 
            </>
          }         

          <Margin margin={1}/>
        </Container>
      </Content>
      {isOpen && <Global />}
    </DivModal>
  )
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const Global = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`
const DivModal = styled.div`
  background: rgba(78, 93, 107, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  opacity:1;
  z-index:999;
  ${({ isOpen }) => !isOpen && css`
    opacity:0;
    z-index: -1;
  `}
`;
const Content = styled.div`
  position:relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 32px 40px;
  display: flex;
  flex-flow: column;
  width: 372px;
  max-height: 95%;
  overflow-y: auto;
  @media screen and (max-width:570px){
    width:350px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
`;
const ButtonClose = styled.div`
  position:absolute;
  top:10px;
  right:10px;
  :hover{
    cursor:pointer;
  }
`;