import React, { useRef, useEffect } from 'react'

const Canvas = props => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

  //Our first draw
		ctx.strokeStyle = "orange";
		ctx.lineWidth = 3;
		ctx.strokeRect(50,40, 200, 200); 
    ctx.strokeRect(50,40, 200, 100); 
    ctx.strokeStyle = "white";
    ctx.lineWidth = 50;
    ctx.strokeRect(25,15, 250, 250);
  }, [])
 
  
  return <canvas width="300" height="275" ref={canvasRef} {...props}/>
}

export default Canvas