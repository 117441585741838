/**
 * @file Defines Container style
 */


import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 1800px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1920px) {
    width: calc(100% - 250px);
    margin-left: 125px;
    margin-right: 125px;
  }
  @media only screen and (max-width: 1440px) {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 1280px) {
    width: calc(100% - 140px);
    margin-left: 70px;
    margin-right: 70px;
  }
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-right: 40px;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-right: 40px;
  }
  @media only screen and (max-width: 578px) {
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export default Container;