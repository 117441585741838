/* eslint-disable */

/**
 * @file Renders backoffice navigation menu bar
 */

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { removeCookie } from "../../auth";
import axios from 'axios';
import IconLogout from '../../static/icon_logout.svg';
import IconUser from '../../static/user.png';
import IconHelp from '../../static/icon_help.png';
import logoEventline from '../../static/logo_eventline.png';
import logoPbc from '../../static/logo_bridge.png';
import IconSelect from '../../static/arrow-down-sign-to-navigate.png';
import { H5, Margin } from '../../atoms';
import Select from 'react-select';


/**
 * Manages rendering of backoffice navigation menu bar
 * 
 * @component Navbar
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */
export default function Navbar(props) {
  console.log('PROPSNAV:'+JSON.stringify(props))

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */ 

  let location = props.location.pathname;

  console.log('LOCATION:' + location)  


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [navbar, setNavbar] = useState(true);
  const [isHome, setIsHome] = useState(false);
  const [id, setId] = useState(0);
  const [path, setPath] = useState('');  
  const [userProfile, setUserProfile] = useState('');
  const [user, setUser] = useState(''); 
  const [configData, setConfigData] = useState('');  

  let token = sessionStorage.getItem('token');
  let bilheteiraId = sessionStorage.getItem('bilheteira');  
  let temp
  let logo 


/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  useEffect(() => {
    checkNav()
    //getConfigData()    
  }, []);

  useEffect(() => {
    checkNav();

    if (props.userProfile != null) {
      setUserProfile(props.userProfile);
    }
  }, [location]);


/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */

/**
 * @function getConfigData - Gets bilheteira id
 *
 * CALLED BY:
 * - 
 * TO IMPROVE:
 * -
 */ 
  /*const getConfigData = () => {
    console.log('GET CONFIG DATA:')

    axios.get(BACKEND_DOMAIN + '/configuracoes/?bilheteira=' + bilheteiraId + '?_limit=-1', token)
    .then(response => {
      console.log(response.data)

      setConfigData(response.data) 
    })
    .catch(error => {
      console.log(error)
    })
  }*/       

/**
 * @function checkNav
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const checkNav = () => {
    temp = location.split('/');
    if (temp.length <= 3) {
      setNavbar(true);
      setIsHome(true);
      setPath(temp[1]);
    } else if (temp.length < 5) {
      setNavbar(true);
      setIsHome(false);
      setId(temp[2]);
      setOptionsService([
        { value: 'home', label: 'Home' },
        { value: 'service/' + temp[2] + '/management', label: 'Management' },
      ])
      setPath(temp[3]);
    } else {
      setNavbar(false);
    }
  }

  const [options, setOptions] = useState([
    { value: 'home', label: 'Home' },
    { value: 'reservations', label: 'Reservations' },
    { value: 'schedule', label: 'Schedule' },
    { value: 'invoices', label: 'Invoices' },    
    { value: 'scancode', label: 'Scanner' },    
    { value: 'settings', label: 'Settings' },
    { value: 'users', label: 'Users' },   
  ]);
  const [optionsService, setOptionsService] = useState([
    { value: 'home', label: 'Home' },
    { value: 'service/' + id + '/management', label: 'Management' },
  ]);

  useEffect(() => {
    checkNav();

    if (props.userProfile != null) {
      setUserProfile(props.userProfile);
      setUser(props.user);
    }
  }, [location]);

  useEffect(() => {
  //    getBilheteira();
  }, [temp]);

  useEffect(() => {
    console.log(bilheteiraId)

    if (bilheteiraId == 4) {
      logo = logoEventline
    } else if (bilheteiraId == 1){
      logo = logoPbc
    }

    console.log(logo)
  }, [bilheteiraId])




/**
 * @function logout
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const logout = () => {
    removeCookie();
    props.history.push('/');
  };

/**
 * @function changePath
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changePath = (path) => {
    console.log(props.history)
    
    props.history.push(`/${path}`);
  }

  return (
    <>
      {navbar &&
        <Header>
          {console.log('NAV USER PROFILE: ' + userProfile)}
          {console.log('NAV USER:' + user)}

          <Container>
            {isHome &&
              <DivHeader>
                <DivTabs className='desktop'>
                  <TitleTab active={path == 'home'} onClick={() => changePath('home')}>
                    Home
                  </TitleTab>
                  <TitleTab active={path === 'reservations'} onClick={() => changePath('reservations')}>
                    Reservations
                  </TitleTab>
                  <TitleTab active={path === 'vouchers'} onClick={() => changePath('vouchers')}>
                    Vouchers
                  </TitleTab>                  
                  <TitleTab active={path === 'schedule'} onClick={() => changePath('schedule')}>
                    Schedule
                  </TitleTab>
                  {(userProfile == 'Administrator' || 
                    userProfile == 'Manager') &&
                    <TitleTab active={path === 'invoices'} onClick={() => changePath('invoices')}>
                      Invoices
                    </TitleTab>
                  } 
                  {(userProfile == 'Administrator' || 
                    userProfile == 'Manager' ||
                    userProfile == 'Reservations + Scan') &&                 
                    <TitleTab active={path === 'scanCode'} onClick={() => changePath('scanCode')}>
                      Scanner
                    </TitleTab> 
                  } 
                  {(userProfile == 'Administrator') &&                                                    
                    <TitleTab active={path === 'settings'} onClick={() => changePath('settings')}>
                      Settings
                    </TitleTab>
                  }
                  {(userProfile == 'Administrator' || 
                    userProfile == 'Manager') &&                    
                    <TitleTab active={path === 'users'} onClick={() => changePath('users')}>
                      Users
                    </TitleTab>  
                  }                
                </DivTabs>
                <DivTabs className='mobile'>
                  <Dropdown
                    placeholder='Tab'
                    name='slot'
                    classNamePrefix='selectBox'
                    options={options}
                    onChange={options => changePath(options.value)}
                    value={options.filter(data => (data.value == path)) ? options.filter(data => (data.value == path)) : ''}
                  />
                </DivTabs>
                <DivHelp>
                  <DivImg 
                    src={IconHelp} 
                    style={{ heigth: '30px', width: '30px' }}
                    active={path === 'documentation'} 
                    onClick={() => changePath('documentation')}
                  />   
                </DivHelp>  
                <DivUser><IconSvgMobile src={IconUser}/>{user}</DivUser>
                <ButtonLogout onClick={() => logout()}><IconSVG src={IconLogout} />Logout</ButtonLogout>
                <ButtonLogoutMobile onClick={() => logout()}><IconSvgMobile src={IconLogout} /></ButtonLogoutMobile>              
                {bilheteiraId == 1 &&
                  <DivImg src={logoPbc} style={{ heigth: '40px', width: '40px' }}/>
                }
                {bilheteiraId == 4 &&
                  <DivImg src={logoEventline}/>
                }                             
              </DivHeader>
            }
            {!isHome &&
              <DivHeader>
                <DivTabs className='desktop'>
                  <ButtonLogout onClick={() => changePath('home')} style={{ marginRight: '32px' }}>Go back</ButtonLogout>
                  <TitleTab active={path === 'management'} onClick={() => changePath('service/' + id + '/management')}>
                    Management
                  </TitleTab>
                  <TitleTab active={path === 'management-email'} onClick={() => changePath('service/' + id + '/management-email')}>
                    Emails
                  </TitleTab>
                </DivTabs>
                <DivTabs className='mobile'>
                  <ButtonLogout onClick={() => changePath('home')} style={{ marginRight: '32px' }}>Go back</ButtonLogout>
                  <TitleTab active={path === 'management'} onClick={() => changePath('service/' + id + '/management')}>
                    Management
                  </TitleTab>
                  <TitleTab active={path === 'management-email'} onClick={() => changePath('service/' + id + '/management-email')}>
                    Emails
                  </TitleTab>                  
                </DivTabs>                
                <ButtonLogout onClick={() => logout()}><IconSVG src={IconLogout} />Logout</ButtonLogout>
              </DivHeader>
            }
          </Container>
        </Header>
      }
    </>
  )
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const Container = styled.div`
  position: relative;
  width: 1800px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1920px) {
    width: calc(100% - 250px);
    margin-left: 125px;
    margin-right: 125px;
  }
  @media only screen and (max-width: 1440px) {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
  }
  @media only screen and (max-width: 1280px) {
    width: calc(100% - 140px);
    margin-left: 70px;
    margin-right: 70px;
  }
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-right: 40px;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-right: 40px;
  }
  @media only screen and (max-width: 578px) {
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
  }
`;
const Header = styled.div`
  background-color:#fff;
  padding-top: 30px;
`;
const DivHeader = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom:16px;

  @media screen and (max-width:1350px){
    flex-flow:column-reverse;
    align-items:flex-start;
    position:relative;
  }
`;

const Dropdown = styled(Select)`
  z-index: 800; 
  display: block;
  width: 200px;
  max-width: 85vw;
  font-family: SFMedium;

  div{
    border-color: #E4EBF0;
    min-height:36px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {         
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-top:8px;
  }
`;
const ButtonLogout = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:114px;
  padding:12px 24px;
  margin: 0px 24px 0px 0px;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  background: #EBEBEB;
  transition: all 0.5s ease;
  :hover{
    background: #A9A9A9;
    cursor:pointer;
  }
  @media screen and (max-width:1350px){
    margin: 0px 10px;
    display:none;    
    visibility: hidden;  
`;
const ButtonLogoutMobile = styled(ButtonLogout)`
  display:none;    
  visibility: hidden;
  width:70px; 
  color: #fff;
  background: #fff;  
  @media screen and (max-width:1350px){
    position: absolute;
    right: 0;
    top: 10px;    
    display: block;  
    visibility: visible;    
    :hover{
      background: #fff;
    }
  }
`;

const DivUser = styled.div`
  display:flex;
  line-height: 24px;
  margin: 0px 18px;
  @media screen and (max-width:1350px){
    position: absolute;
    top: 23px;
    right: 80px;
    display:flex;
    line-height: 24px;
  }
`;

const DivHelp = styled.div`
  margin: auto;
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width:1350px){
    position: absolute;
    bottom: 5px; 
    right: 29px;   
    z-index:999; 
  }
`;

const DivImg = styled.img`
  display: block;
  width:90px;
  object-fit: contain;
  height:60px;
`;
const DivTabs = styled.div`
  display:flex;
  flex-flow:row;
  @media screen and (min-width:900px){
    &.desktop{
      display:flex;
      align-items:center;
    }
    &.mobile{
      display:none;
    }
  }
  @media screen and (max-width:900px){
    &.desktop{
      display:none;
    }
    &.mobile{
      display:flex;
      margin-top:8px;
      align-items:center;
    }
  }
`;
const TitleTab = styled(H5)`
  color: ${({ active }) => active ? '#373737' : '#A9A9A9'};
  margin-right:14px;
  transition: all 0.5s ease;
  :last-child {
    margin-right:0;
  }
  :hover {
    color: #8B8B8B;
    cursor: pointer;
  }
`;
const IconSVG = styled.img`
  width:14px;
  height:14px;
`;
const IconSvgMobile = styled.img`
  width:24px;
  height:24px;
  padding-right: 10px;
`;

