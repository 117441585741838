/**
 * @file Defines Section style
 */

import styled from 'styled-components';

const Section = styled.div`
  position:relative;
  width:100%;
  flex:1;
  box-sizing:border-box;
`;

export default Section;