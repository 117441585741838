/**
 * @file Defines component that renders selected service management page
 */

/* eslint-disable */

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import styled, { css } from "styled-components";
import Switch from "react-switch";
import { useParams, useHistory } from "react-router-dom";

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H2,
  H3,
  H5,
  H6,
  Text12,
  Text11,
  Loading
} from '../atoms';

import {
  ModalEmail,
  ModalNotification
} from '../molecules';


/**
 * Manages rendering of selected service management page
 * 
 * @component Management
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 * 
 */ 
export default function Management(props) {

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  let params = useParams();
  let history = useHistory();  

  const bilheteiraId = sessionStorage.getItem('bilheteira')  

  console.log(bilheteiraId)  
  
  console.log('SERVICE ID')
  let serviceId = params.id
  //let serviceId = props.match.params.id;

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;
  const FRONTEND_DOMAIN = props.FRONTEND_DOMAIN;

  let user = props.user;
  let userProfile = props.userProfile;    

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const settingsEmail = [
    ['undo', 'redo', 'fontSize', 'formatBlock', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview']
  ]  


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [events, setEvents] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [days, setDays] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [slotsLanguages, setSlotsLanguages] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [emails, setEmails] = useState([]);
  const [url, setUrl] = useState([]);
  const [servicePath, setServicePath] = useState('');              // Selected service url path component

  const [date, setDate] = useState(tomorrow);

  const [htmlPrePt, setHtmlPrePt] = useState('');
  const [htmlPosPt, setHtmlPosPt] = useState('');
  const [htmlPreEn, setHtmlPreEn] = useState('');
  const [htmlPosEn, setHtmlPosEn] = useState('');  
  const [htmlPreFr, setHtmlPreFr] = useState('');
  const [htmlPosFr, setHtmlPosFr] = useState('');
  const [htmlPrePtTitle, setHtmlPrePtTitle] = useState('');
  const [htmlPosPtTitle, setHtmlPosPtTitle] = useState('');
  const [htmlPreEnTitle, setHtmlPreEnTitle] = useState('');
  const [htmlPosEnTitle, setHtmlPosEnTitle] = useState('');
  const [htmlPreFrTitle, setHtmlPreFrTitle] = useState('');
  const [htmlPosFrTitle, setHtmlPosFrTitle] = useState('');  

  const [isOpen, setIsOpen] = useState(false);

  const [notification, setNotification] = useState(false);
  const [statusEmail, setStatusEmail] = useState(false);

  const [duration, setDuration] = useState(0);
  const [interval, setInterval] = useState(0);


/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  useEffect(() => {
    getCredentials();
  }, []);


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

//NONE

/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */


/**
 * @function getCredentials
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token');
    setToken(temp)
    getData(temp)
    getDataEmails(temp)
  }


/**
 * @function getData
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getData = async (key) => {
    setLoading(true);
    await Promise.all([
      //getUrl(key),
      getServicePath(key),
      getEvents(key),      
      getBlocks(key),
      getDiscounts(key), 
      getDays(key),
      getSeasons(key),
      getSlotsLanguages(key),
      getDurationVisits(key),
      getCoupons(key),
      getTicketTypes(key)
    ]);
  }

/**
 * @function getSeasons
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getSeasons = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/epocas?servico=" + serviceId + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data);
        setSeasons(response.data);
      })
      .catch(error => {

      });
  }


/**
 * @function getSlotsLanguages
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getSlotsLanguages = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/lingua-slots?servico="  + serviceId + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        setSlotsLanguages(response.data);
      })
      .catch(error => {

      });
  }


/**
 * @function getTicketTypes
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getTicketTypes = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/tipo-bilhetes?servico="  + serviceId + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        setTicketTypes(response.data);
      })
      .catch(error => {

      });
  }


/**
 * @function getEvents
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getEvents = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/slots?servico="  + serviceId + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        setEvents(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }


/**
 * @function getBlocks
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getBlocks = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/bloqueios?servico="  + serviceId + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        setBlocks(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }


/**
 * @function getDiscounts
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const getDiscounts = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/descontos" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        setDiscounts(response.data);
      })
      .catch(error => {

      });
  }


/**
 * @function getDays
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const getDays = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/dias" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        setDays(response.data);
        setLoading(false);
      })
      .catch(error => {

      });
  }


/**
 * @function getCoupons
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const getCoupons = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/codigos?servico="  + serviceId + '&_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        let tempArr = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].ativo === true) {
              tempArr.push(response.data[i]);
            }
          }
        }
        setCoupons(tempArr);
        setLoading(false);
      })
      .catch(error => {

      });
  }


/**
 * @function getSeasonIndex
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const getSeasonIndex = () => {
    for (i = 0;i <= seasons.length; i++) {
      
    }

  }


/**
 * @function getDurationVisits
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const getDurationVisits = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/servicos/" + serviceId + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        setDuration(response.data.duracao);
        setInterval(response.data.intervalo);
        setLoading(false);
      })
      .catch(error => {
      });
  }


/**
 * @function setDurationVisits
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const setDurationVisits = () => {
    axios
      .put(BACKEND_DOMAIN + "/servicos/" + serviceId,
        {
          duracao: duration,
          intervalo: interval
        }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(response => {
        setLoading(false);
      })
      .catch(error => {
      });
  }


/**
 * @function detailPage
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const detailPage = (type) => {
    if (type === 'blocks') {
      history.push({
        pathname: '/service/' + serviceId + '/management/blocks',
        blocks: blocks,
        token: token
      });
    } else if (type === 'discounts') {
      history.push({
        pathname: '/service/' + serviceId + '/management/discounts',
        discounts: discounts,
        token: token
      });
    } else if (type === 'coupons') {
      history.push({
        pathname: '/service/' + serviceId + '/management/coupons',
        days: coupons,
        token: token
      });
    } else if (type === 'seasons') {
      history.push({
        pathname: '/service/' + serviceId + '/management/seasons',
        days: seasons,
        token: token
      });
    } else if (type === 'events') {
      history.push({
        pathname: '/service/' + serviceId + '/management/events',
        events: events,
        token: token
      });
    } else if (type === 'slotsLanguages') {
      history.push({
        pathname: '/service/' + serviceId + '/management/slotsLanguages',
        slotsLanguages: slotsLanguages,
        token: token
      });
    } else if (type === 'ticketTypes') {
      history.push({
        pathname: '/service/' + serviceId + '/management/ticketTypes',
        ticketTypes: ticketTypes,
        token: token
      });    
    }
  }


/**
 * @function getDataEmails
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const getDataEmails = (key) => {
    axios.get(BACKEND_DOMAIN + "/emails" + '?_limit=-1', {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
      .then(function (response) {
        if (response.data && response.data.length > 0) {
          console.log(response.data)
          console.log(response.data.filter(x=>Number(x.servico.id) === Number(serviceId)))
          const emailsFilter = response.data.filter(x=>Number(x.servico.id) === Number(serviceId))
          setEmails(response.data.filter(x=>Number(x.servico.id) === Number(serviceId)))
          for (let i = 0; i < emailsFilter.length; i++) {
            if (emailsFilter[i].type === 'lembrete') {
              if (emailsFilter[i].Linguagem === 'pt') {
                  setHtmlPosPt(emailsFilter[i].Body);
                  setHtmlPosPtTitle(emailsFilter[i].Subject);
              } else if (emailsFilter[i].Linguagem === 'en') {
                  setHtmlPosEn(emailsFilter[i].Body);
                  setHtmlPosEnTitle(emailsFilter[i].Subject);
              }
            } else if (emailsFilter[i].type === 'reserva') {
              if (emailsFilter[i].Linguagem === 'pt') {
                  setHtmlPrePt(emailsFilter[i].Body);
                  setHtmlPrePtTitle(emailsFilter[i].Subject);
              } else if (emailsFilter[i].Linguagem === 'en') {
                  setHtmlPreEn(emailsFilter[i].Body);
                  setHtmlPreEnTitle(emailsFilter[i].Subject);
              }
            }
          }
        }
      })
      .catch(function (error) {
      })
  }


/**
 * @function sendEmail
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const sendEmail = (type, lang) => {
    let tempId;
    let body;
    let title;
    if(emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang ).length > 0) {
      console.log(emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang ))
      
      if (type === 'reserva') {
        if (lang === 'pt') {
          tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang )[0].id ;
          body = htmlPrePt;
          title = htmlPrePtTitle;
        } else if (lang === 'en') {
          tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang )[0].id;
          body = htmlPreEn;
          title = htmlPreEnTitle;
        } else if (lang === 'fr') {
          tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang )[0].id;
          body = htmlPreFr;
          title = htmlPreFrTitle;
        }        

      } else if (type === 'lembrete') {
        if (lang === 'pt') {
          tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang )[0].id;
          body = htmlPosPt;
          title = htmlPosPtTitle;
        } else if (lang === 'en') {
          tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang )[0].id;
          body = htmlPosEn;
          title = htmlPosEnTitle;
        } else if (lang === 'fr') {
          tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang )[0].id;
          body = htmlPosFr;
          title = htmlPosFrTitle;
        }        
      }
      axios.put(BACKEND_DOMAIN + "/emails/" + tempId, {
        type: type,
        Body: body,
        Subject: title,
        Linguagem: lang,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(function (response) {
          setNotification(true);
          setStatusEmail(true);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
        })
        .catch(function (error) {
          setNotification(true);
          setStatusEmail(false);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
        })
        return;
    }else{
      if (type === 'reserva') {
        if (lang === 'pt') {
          body = htmlPrePt;
          title = htmlPrePtTitle;
        } else if (lang === 'en') {
          body = htmlPreEn;
          title = htmlPreEnTitle;
        } else if (lang === 'fr') {
          body = htmlPreFr;
          title = htmlPreFrTitle;
        }        
      } else if (type === 'lembrete') {
        if (lang === 'pt') {
          body = htmlPosPt;
          title = htmlPosPtTitle;
        } else if (lang === 'en') {
          body = htmlPosEn;
          title = htmlPosEnTitle;
        } else if (lang === 'fr') {
          body = htmlPosFr;
          title = htmlPosFrTitle;
        }
      }
      axios.post(BACKEND_DOMAIN + "/emails", {
        type: type,
        Body: body,
        Subject: title,
        Linguagem: lang,
        servico:serviceId,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(function (response) {
          setNotification(true);
          setStatusEmail(true);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
        })
        .catch(function (error) {
          setNotification(true);
          setStatusEmail(false);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
        })
        return;
    }

  }


/**
 * @function toogleModal
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };


/**
 * @function getUrl
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
 /* const getUrl = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {

        console.log(response.data)

        setUrl(response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  }*/ 

/**
 * @function getServicePath
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getServicePath = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/servicos/" + serviceId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        setUrl(response.data.urlDomain)        
        setServicePath (response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  } 

  return (
    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper>
          <Container>

            {console.log('URL')}
            {console.log(url)}  

            <H2
              font='SFRegular'
              style={{fontSize: '1.2em', margin: '-35px 0 -20px 0', display: 'inline-block'}}
            >
              Service Url: 
            </H2>
            <div style={{paddingLeft: '20px', display: 'inline-block'}}>{url + "?srv=" + servicePath}</div>
            <DivCards>
              <Cards>
                <Card>
                  <DivTextCardReservation>
                    <NumberReservation font='SFRegular'>
                      Seasons
                    </NumberReservation>
                    <LabelReservation font='SFRegular'>
                      Management of seasons and days of each season
                    </LabelReservation>
                  </DivTextCardReservation>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Active Seasons
                      </TitleReservation>
                      <DateReservation>
                        {seasons.length}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => detailPage('seasons')}>
                      See more
                    </ButtonAllReser>
                  </DivReservation>
                </Card>
                <Card>
                  <DivTextCardReservation>
                    <NumberReservation font='SFRegular'>
                      Single Events
                    </NumberReservation>
                    <LabelReservation font='SFRegular'>
                      Management of events, outside of the seasons' pattern.
                    </LabelReservation>
                  </DivTextCardReservation>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Single Events
                      </TitleReservation>
                      <DateReservation>
                        {events.length}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => detailPage('events')}>
                      See more
                    </ButtonAllReser>
                  </DivReservation>
                </Card>                                 
                <Card>
                  <DivTextCardReservation>
                    <NumberReservation font='SFRegular'>
                      Blocks
                    </NumberReservation>
                    <LabelReservation font='SFRegular'>
                      Slots/days block management
                    </LabelReservation>
                  </DivTextCardReservation>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Active BLocks
                      </TitleReservation>
                      <DateReservation>
                        {blocks.length}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => detailPage('blocks')}>
                      See more
                    </ButtonAllReser>
                  </DivReservation>
                </Card>
                <Card>
                  <DivTextCardReservation>
                    <NumberReservation font='SFRegular'>
                      Coupons
                    </NumberReservation>
                    <LabelReservation font='SFRegular'>
                      Management of coupon codes to be used at checkout
                    </LabelReservation>
                  </DivTextCardReservation>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Active coupons
                      </TitleReservation>
                      <DateReservation>
                        {coupons.length}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => detailPage('coupons')}>
                      See more
                    </ButtonAllReser>
                  </DivReservation>
                </Card>                
                <Card>
                  <DivTextCardReservation>
                    <NumberReservation font='SFRegular'>
                      Slots Languages
                    </NumberReservation>
                    <LabelReservation font='SFRegular'>
                      Slots languages management
                    </LabelReservation>
                  </DivTextCardReservation>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Slots Languages
                      </TitleReservation>
                      <DateReservation>
                        {slotsLanguages.length}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => detailPage('slotsLanguages')}>
                      See more
                  </ButtonAllReser>
                  </DivReservation>
                </Card> 
                <Card>
                  <DivTextCardReservation>
                    <NumberReservation font='SFRegular'>
                      Ticket Types
                    </NumberReservation>
                    <LabelReservation font='SFRegular'>
                      Ticket Types management
                    </LabelReservation>
                  </DivTextCardReservation>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Ticket Types
                      </TitleReservation>
                      <DateReservation>
                        {ticketTypes.length}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => detailPage('ticketTypes')}>
                      See more
                  </ButtonAllReser>
                  </DivReservation>
                </Card>                      
              </Cards>
            </DivCards>
            <Margin margin={2} />
            <DivCards>
              <ContainerInterval>
                <H6
                  dangerouslySetInnerHTML={{ __html: 'Duration and interval p/slot' }}
                />
                <Margin margin={2} />
                <DivInputsInterval>
                  <DivInterval>
                    <Text11
                      dangerouslySetInnerHTML={{ __html: 'DURATION' }}
                    />
                    <Margin />
                    <NumberInput
                      min='0'
                      max='60'
                      value={duration}
                      onChange={(event) => setDuration(event.target.value)}
                    />
                  </DivInterval>
                  <DivInterval>
                    <Text11
                      dangerouslySetInnerHTML={{ __html: 'INTERVAL' }}
                    />
                    <Margin />
                    <NumberInput
                      min='0'
                      max={60 - duration}
                      value={interval}
                      onChange={(event) => setInterval(event.target.value)}
                    />
                  </DivInterval>
                </DivInputsInterval>
              </ContainerInterval>
              <ContainerSaveInterval>
                <ButtonAllReser onClick={() => setDurationVisits()} style={{ width: '83px', boxSizing: 'border-box' }}>
                  Save
                </ButtonAllReser>
              </ContainerSaveInterval>
            </DivCards>            
          </Container>
        </Wrapper>
      }
      <ModalEmail
        isOpen={isOpen}
        toogleModal={toogleModal}
      />
      <ModalNotification
        isOpen={notification}
        status={statusEmail}
      />
    </Section>
  )
}



/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const DivCards = styled.div`
  display:flex;
  flex-flow:column;
  margin-bottom: 20px;
  :last-child {
    margin-bottom:0;
  }
`;
const Cards = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-top:16px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  box-sizing: border-box;
`;
const Card = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: 274px;
  min-height: 215px;
  box-sizing: border-box;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;
const DivReservation = styled.div`
  margin: 0px -24px -24px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const CardHtml = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: calc(50% - 20px);
  height: 100%;
  min-height:400px;
  flex-flow: column;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  .sun-editor{
    min-height:300px;
    .se-container{
      min-height:300px;
      display: flex;
      flex-flow: column;
    }
    .se-wrapper{
      flex:1;
      .se-wrapper-wysiwyg{
        min-height:200px;
      }
    }
  }
  .htmlEditor{
    border:unset;
    font-family: SFRegular;
    margin-top:-8px;
  }
  .htmlEditor-toolbar{
    border:unset;
    font-family: SFRegular;
    margin:0;
    margin-top:8px;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    border-bottom:1px solid #EBEBEB;
  }
  .htmlEditor-editor{
    font-family: SFRegular;
  }

  @media only screen and (max-width: 720px) {
    width: calc(100% - 20px);
  }
`;
const DivHtmlEditor = styled.div`
  margin: -24px -24px 0px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const DivTitleHtmlEditor = styled.div`
  display:flex;
  flex-flow:column;
`;
const DivTitleEmail = styled.div`
  padding:8px 24px;
  margin: 0 -24px;
  border-bottom:1px solid #EBEBEB;
  margin-bottom:8px;
  display:flex;
  flex-flow:column;
`;
const TitleEmail = styled(Text11)`
  margin-bottom:8px;
`;
const TextInput = styled.input.attrs(props => ({
  type: "text"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const TitleReservation = styled(Text11)`
  color: #A9A9A9;
  text-transform: uppercase;
`;
const DateReservation = styled(Text11)`
`;
const DivTextCardReservation = styled.div`
  display:flex;
  flex-flow:column;
`;
const NumberReservation = styled(H3)`
  font-weight: normal;
`;
const LabelReservation = styled(Text12)`
  margin-bottom:24px;
  font-weight: normal;
`;
const ButtonAllReser = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;
const Toggle = styled(Switch)`
  .react-switch-bg{
    background-color:#fff !important;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
  }
`;
const ContainerInterval = styled.div`
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  border-bottom: unset;
  padding: 24px;
  max-width:568px;
  box-sizing: border-box;
  display:flex; 
  flex-direction:column;
`;
const DivInputsInterval = styled.div`
  display:flex;
  flex-flow:row;
`;
const NumberInput = styled.input.attrs(props => ({
  type: "number"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const DivInterval = styled.div`
  width: calc(50% - 10px);
  margin-right:20px;
  display:flex;
  flex-flow:column;
  :last-child{
    margin-right: 0;
  }
`;
const ContainerSaveInterval = styled.div`
  max-width:568px;
  padding: 16px 24px;
  box-sizing:border-box;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  border-radius:6px;
  border-top:unset;
  border-top-left-radius:0;
  border-top-right-radius:0;
`;