/**
 * @file Defines cookies management components
 */


const setCookie = (token) => {
  if (process.browser) {
    sessionStorage.setItem("token", token);
  }
};

const checkCookie = (cname) => {
  if (process.browser) {
    let cookie = sessionStorage.getItem(cname);
    if (cookie === "" || cookie === undefined || cookie === null) {
      return false;
    } else {
      return true;
    }
  }
};

const removeCookie = () => {
  if (process.browser) {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("bilheteira");
  }
};

export {
  setCookie,
  checkCookie,
  removeCookie
};
