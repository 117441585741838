/* eslint-disable */

/**
 * @file Renders modal informing about success of user save input data operation
 */

import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { createGlobalStyle } from 'styled-components';

import {
  Text14,
} from '../../atoms';


/**
 * Manages rendering of ModalNotification
 * 
 * @component ModalNotification
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - management.js  
 * 
 * BUGS:
 * - ...
 * 
 */

export default function ModalNotification(props) {

  const {
    isOpen,
    status,
    errorMessage,
    successMessage,
  } = props;

  return (
    <DivModal isOpen={isOpen}>
      {console.log('ENTERED NOTIFICATION')}
      {console.log(status)}      
      <Content>
        <Container>
          { status === false &&
            <DivNotification>
              <Icon>
                ❌
              </Icon>
              <Text14
                dangerouslySetInnerHTML={{ __html: errorMessage ? errorMessage : 'Error' }}
              />
            </DivNotification>
          }
          {status === true &&
            <DivNotification>
              <Icon>
                ✅
              </Icon>
              <Text14
                dangerouslySetInnerHTML={{ __html: successMessage ? successMessage : 'Success' }}
              />
            </DivNotification>
          }
        </Container>
      </Content>
      {isOpen && <Global />}
    </DivModal>
  )
}

/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const Global = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`
const DivModal = styled.div`
  background: rgba(78, 93, 107, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  opacity:1;
  z-index:999;
  ${({ isOpen }) => !isOpen && css`
    opacity:0;
    z-index: -1;
  `}
`;
const Content = styled.div`
  position:relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 32px 40px;
  display: flex;
  flex-flow: column;
  width: 372px;
  max-height: 95%;
  overflow-y: auto;
  @media screen and (max-width:570px){
    width:350px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivNotification = styled.div`
  display:flex;
  flex-flow:row;
  min-height:200px;
  align-items:center;
  justify-content:center;
`;
const Icon = styled.span`
  margin-right:8px;
`;