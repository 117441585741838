/**
 * @file Defines H5 style
 */

import styled, { css } from 'styled-components';

let fontMax = 24;
let fontMin = 16;

const H5 = styled.h5`
  font-family: ${({ font }) => font ? font : 'SFSemibold'};
  font-size: calc(${fontMin}px + (${fontMax} - ${fontMin}) * ((100vw - 320px) / (1920 - 320)));	
  text-align: ${({ align }) => align ? align : 'left'};
  line-height: ${({ height }) => height ? height : '32'}px;
  color: ${({ color }) => color ? color : '#373737'};
  transition: all 0.5s ease;
  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  @media screen and (max-width: 768px) {
    line-height:24px;
  }
  @media screen and (min-width: 1920px) {
    font-size: ${fontMax}px;
  }
`;

export default H5;