/* eslint-disable */

/**
 * @file Shows result from QR code scan
 * 
 **/

import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Select from 'react-select';
import { createGlobalStyle } from 'styled-components';
import iconClose from '../../static/icon_close.svg';

import {
  Text14,
} from '../../atoms';


export default function ModalScanner(props) {

  const {
    isOpen,
    scanned,
    loading,
    errorMessage,
    toogleModal,
    success,
    setErrorMessage,
  } = props;

  const [error, setError] = useState(false);                       // ...                  // ...                 // ...

  const closeModal = () => {
    toogleModal();
    setErrorMessage('')
  }  

  const timeOut = () => {
    {setTimeout(() => {
      closeModal()
    }, 6000)}    
  }

  return (
    <DivModal isOpen={isOpen}>
      {console.log('LOADING:')}
      {console.log(scanned)}
      {console.log(loading)}
      {console.log(success)}
      {console.log(errorMessage)}

      {scanned == true &&
        <Content>
          <Container>
            {(loading === false && success === false) &&
              <>
                <DivNotification>
                  <Icon>
                    ❌ 
                  </Icon>
                  <Text14
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                  />               
                </DivNotification>

                {console.log('ERROR')}
                {timeOut()}               
              </>
            }
            {(loading === false && success === true) &&
              <>
                <DivNotification>
                  <Icon>
                    ✅
                  </Icon>
                  <Text14
                    dangerouslySetInnerHTML={{ __html: 'Entrada registada com sucesso.' }}
                  />
                </DivNotification>

                {console.log('SUCCESS')}
                {timeOut()} 
              </>             
            }
          </Container>
        </Content>
      }
    </DivModal>
  )
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const Global = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;
const DivModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  opacity:1;
  z-index:999;
  ${({ isOpen }) => !isOpen && css`
    opacity:0;
    z-index: -1;
  `}
  @media screen and (max-width:570px){
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }  
`;
const Content = styled.div`
  position:relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 32px 40px;
  display: flex;
  flex-flow: column;
  width: 372px;
  max-height: 95%;
  overflow-y: auto;
  margin-left: 20px;
  justify-content: center;
  @media screen and (max-width:570px){
    position: absolute;
    width:350px;
    margin-top: -100px;
    max-heigth: 300px;
    margin-left: 0;
    overflow-y: hidden;
    width: 95%;    
  }
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items:center;
  @media screen and (max-width:570px){
    display:block;
  }  
`;
const DivNotification = styled.div`
 display:flex;
 flex-flow:row;
 min-height:200px;
 align-items:center;
 justify-content:center;
  @media screen and (max-width:570px){
    min-height:200px;
  } 
`;
const Icon = styled.span`
 margin-right:8px;
`;