/* eslint-disable */

/**
 * @file App documentation
 */

import React, { useEffect, useState } from 'react';
import styled, { css } from "styled-components";
import { NavbarDoc } from '../molecules';

import editBtn from '../static/documentation/edit-button.png';
import editSmallBtn from '../static/documentation/edit-small-button.png';
import deleteBtn from '../static/documentation/delete-button.png';
import deleteSmallBtn from '../static/documentation/delete-small-button.png';
import saveBtn from '../static/documentation/save-button.png';
import cancelBtn from '../static/documentation/cancel-button.png';
import infoBtn from '../static/documentation/info-button.png';
import managementBtn from '../static/documentation/management-button.png';
import createBtn from '../static/documentation/create-new-button.png';
import seeAllBtn from '../static/documentation/see-all-button.png';
import seeMoreBtn from '../static/documentation/see-more-button.png';
import daysBtn from '../static/documentation/days-button.png';
import workingDaysBtn from '../static/documentation/working-days-button.png';
import testEmailBtn from '../static/documentation/test-email-button.png';
import instructionsEmailBtn from '../static/documentation/instructions-email-button.png';
import blockBtn from '../static/documentation/block-button.png';
import ixBtn from '../static/documentation/ix-button.png';

import navBar from '../static/documentation/nav-bar.png';
import ticketOffice from '../static/documentation/ticket-office.png';
import scheduleHome from '../static/documentation/schedule-home.png';
import services from '../static/documentation/services.png';
import editTicketOffice from '../static/documentation/edit_ticket-office.png';
import addService01 from '../static/documentation/add-service-01.png';
import addService02 from '../static/documentation/add-service-02.png';
import addService03 from '../static/documentation/add-service-03.png';
import management01 from '../static/documentation/management-01.png';
import management02 from '../static/documentation/management-02.png';
import emailEditor from '../static/documentation/email-editor.png';
import seasons01 from '../static/documentation/seasons-01.png';
import seasons02 from '../static/documentation/seasons-02.png';
import addSeason01 from '../static/documentation/add-season-01.png';
import addSeason02 from '../static/documentation/add-season-02.png';
import addDay from '../static/documentation/add-day.png';
import days01 from '../static/documentation/days-01.png';
import days02 from '../static/documentation/days-02.png';
import addWorkingDays from '../static/documentation/add-working-days.png';
import events from '../static/documentation/events.png';
import addEvent from '../static/documentation/add-event.png';
import blocks from '../static/documentation/blocks.png';
import addBlockRange from '../static/documentation/add-block-range.png';
import addBlockDaySlot from '../static/documentation/add-block-day-slot.png';
import coupons from '../static/documentation/coupons.png';
import addCoupons from '../static/documentation/add-coupon.png';
import addLanguage from '../static/documentation/add-language.png';
import languages from '../static/documentation/languages.png';
import addSlotLanguage from '../static/documentation/add-slot-language.png';
import slotsLanguages from '../static/documentation/slots-languages.png';
import ticketTypes from '../static/documentation/ticket-types.png';
import addTicketType from '../static/documentation/add-ticket-type.png';
import managementEmail from '../static/documentation/management-email.png';
import backofficeTemplates from '../static/documentation/backoffice-templates.png';
import mailchimpTemplates from '../static/documentation/mailchimp-templates.png';
import templateInfo from '../static/documentation/template-info.png';
import mailchimpEditor from '../static/documentation/mailchimp-editor.png';
import addTemplate from '../static/documentation/add-template.png';
import deleteTemplate from '../static/documentation/delete-template.png';
import reservations from '../static/documentation/reservations.png';
import reservations01 from '../static/documentation/reservations-01.png';
import addReservation01 from '../static/documentation/add-reservation-01.png';
import addReservation02 from '../static/documentation/add-reservation-02.png';
import reservations02 from '../static/documentation/reservations-02.png';
import schedule from '../static/documentation/schedule.png';
import invoices from '../static/documentation/invoices.png';
import scanner from '../static/documentation/scanner.png';
import supportText from '../static/documentation/support-text.png';
import eventLanguages from '../static/documentation/event-languages.png';
import generalSettings from '../static/documentation/general-settings.png';
import emailSettings from '../static/documentation/email-settings.png';
import emailSettings02 from '../static/documentation/email-settings-02.png';
import emailSettings03 from '../static/documentation/email-settings-03.png';
import invoicingSettings from '../static/documentation/invoicing-settings.png';
import users from '../static/documentation/users.png';
import addUser from '../static/documentation/add-user.png';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H2,
  H3,
  H4,
  H5,
  H6,
  Text11,
  Text12,
  Text14,
  Loading
} from '../atoms';

/**
 * @component Documentation
 *
 * - Manages settings of:
 *   - Support Text  
 *   - Event languages
 *   - Last booking time
 *   - Email
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * @todo Create Separate components for:
 *   @todo Event Languages
 *   @todo Support text
 */
export default function Documentation(props) {
  console.log(props)

  return (
    <Section>
      <Wrapper>
        <Container>       
          <DivHeader>
            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
              User Guide
            </H3>
          </DivHeader>     
          <NavbarDoc  path={props.path} style={splitLeft}/>
          <DivDoc  style={splitRight}>
            <List1 id="nav-bar" > 1. Navigation Bar </List1>
            <img src={navBar} alt='Navigation Bar' style={docImg}/>
            <Text>Elementos do menu de navegação por ordem da esquerda para a direita:</Text>
              <OrderedList>
                <ListItem><b>Home</b> - Página inicial.</ListItem>
                <ListItem><b>Reservations</b> - Link para a página de visualização de reservas.</ListItem> 
                <ListItem><b>Schedule</b> - Link para a página de visualização de horários.</ListItem>
                <ListItem><b>Invoices</b> - Link para a página de visualização de faturas.</ListItem>
                <ListItem><b>Scanner</b> - Link para a página de controlo de acessos por QR Code.</ListItem>
                <ListItem><b>Settings</b> - Link para a página de gestão de das opções do <i>backoffice</i> e página de reservas.</ListItem> 
                <ListItem><b>Users</b> - Link para a página de gestão de utilizadores.</ListItem>
                <ListItem><b>User Guide</b> - Guia de utilização do <i>backoffice</i>.</ListItem>                
                <ListItem><b>Username</b> - Nome do utilizador.</ListItem>
                <ListItem><b>Logout</b> - Botão de <i>logout</i>.</ListItem>
              </OrderedList>                                           
            <List1 id="home" > 2. Home </List1>
              <Text> Página inicial da bilheteira após <i>login</i>.</Text>              
              <List2 id="ticket-office">1.1. Ticket Office</List2> 
                <Text> Informação da bilheteira.</Text> 
                <img src={ticketOffice} alt='Home' style={docImg}/>
                <Text><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Acesso à edição de informação da bilheteira. Abre janela de alteração de dados: </Text>
                <img src={editTicketOffice} alt='Home' style={docImg}/>
                <OrderedList>
                  <ListItem><b>Title</b> - Nome da bilheteira</ListItem>
                  <ListItem><b>Description</b> - Descrição da bilheteira</ListItem> 
                  <ListItem><b>Url</b> - Link web da bilheteira</ListItem>
                  <ListItem><b>Email</b> - Email da bilheteira de envio das confirmações de reserva e faturas</ListItem>
                  <ListItem><b>Payments</b> - Opção de ativação da função de pagamentos</ListItem>
                  <ListItem><b>Stripe key</b> - Key da conta de Stripe associada aos pagamentos</ListItem>                 
                </OrderedList>
              <List2 id="home-schedule">2.2. Schedule</List2>
                <Text>Mostra resumo das reservas para hoje e para amanhã.</Text>
                <img src={scheduleHome} alt='Home' style={docImg}/> 
                <Text><img src={seeAllBtn} alt='[See All]' style={buttonImg}/> - Link para a página de horários de toda a bilheteira.</Text>    
              <List2 id="services">2.3. Services</List2>
                <Text>Lista de serviços (ou eventos) da bilheteira. </Text>
                <img src={services} alt='Home' style={docImg}/>
                <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de novo serviço. Abre janela de inserção de dados: </Text>
                <img src={addService01} alt='Add Service' style={docImg}/>
                <img src={addService02} alt='Add Service' style={docImg}/>
                <img src={addService03} alt='Add Service' style={docImg}/>
                <OrderedList>
                  <ListItem><b>Url Path</b> - Caminho url (Não editável. Para editar, contactar suporte).</ListItem>
                  <ListItem><b>Service PT</b> - Nome do serviço em português.</ListItem>
                  <ListItem><b>Service EN</b> - Nome do serviço em inglês.</ListItem> 
                  <ListItem><b>Service FR</b> - Nome do serviço em francês.</ListItem>
                  <ListItem><b>Description PT</b> - Descrição do serviço em português.</ListItem>
                  <ListItem><b>Description EN</b> - Descrição do serviço em inglês.</ListItem>
                  <ListItem><b>Description FR</b> - Descrição do serviço em francês.</ListItem>
                  <ListItem><b>Checkin Text PT</b> - Texto de checkin (caixa amarela) do serviço em português.</ListItem>
                  <ListItem><b>Checkin Text EN</b> - Texto de checkin do serviço em inglês.</ListItem>
                  <ListItem><b>Checkin Text FR</b> - Texto de checkin do serviço em francês.</ListItem>
                  <ListItem><b>Checkout Text PT</b> - Texto de checkout (caixa amarela) do serviço em português.</ListItem>
                  <ListItem><b>Checkout Text EN</b> - Texto de checkout do serviço em inglês.</ListItem>
                  <ListItem><b>Checkout Text FR</b> - Texto de checkout do serviço em francês.</ListItem>                                    
                  <ListItem><b>Url Path</b> - Terminação do caminho url do serviço.</ListItem>       
                  <ListItem><b>Service Image</b> - Imagem do serviço da listagem inicial, quando a opção de listagem dos serviços para o cliente está ativada.</ListItem>       
                  <ListItem><b>Service Active</b> - Opção de ativação do serviço. Quando ativo, o serviço irá aparecer na listagem de serviços da página inicial, se esta opção de página de listagem também estiver ativada.</ListItem>                                                                                                                                
                </OrderedList>                                                 
                <Text><img src={managementBtn} alt='[Create New]' style={buttonImg}/> - Acesso à página de gestão do serviço.</Text>
                <Text><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Edição da informação do serviço. Abre janela de alteração de dados.</Text>
                <br />                                                                            
                <Text><b>Nota:</b> Para eliminar serviço, contactar suporte.</Text>                                                                                             
                <List3 id="management"> 2.3.1. Management </List3>
                  <Text>Página de gestão do serviço. </Text>
                  <img src={management01} alt='Management' style={docImg}/>                    
                  <Text><img src={seeMoreBtn} alt='[See More]' style={buttonImg}/> - Acesso à página de gestão de cada uma das opções do serviço. </Text>                                    
                    <List4 id="seasons"> Seasons </List4>
                      <Text>Gestão das épocas e dos dias da semana de cada época do serviço.</Text>
                      <img src={seasons01} alt='Management' style={docImg}/>
                      <img src={seasons02} alt='Management' style={docImg}/> 
                      <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de nova época. Abre janela de inserção de dados: </Text>
                      <img src={addSeason01} alt='Management' style={docImg}/>
                      <img src={addSeason02} alt='Management' style={docImg}/>
                      <OrderedList>
                        <ListItem><b>Title</b> - Nome da época.</ListItem>
                        <ListItem><b>Start Date</b> - Data de início da época.</ListItem> 
                        <ListItem><b>End Date</b> - Data de fim da época</ListItem>
                        <ListItem><b>Ticketing Types</b> - Definição da opção de tipo de bilhetes. Bilhete único ou bilhetes de multiplos tipos (Adulto, Criança,...).</ListItem>
                        <ListItem><b>Ticket Price</b> - Preço do bilhete para a opção de bilhete único.</ListItem>
                        <ListItem><b>Discount Price</b> - Preço de desconto com base na quantidade mínima definida.</ListItem>
                        <ListItem><b>Discount Percentage</b> - Percentagem de desconto com base na quantidade mínima definida.</ListItem>       
                        <ListItem><b>Discount Quantity</b> - Quantidade mínima de bilhetes necessária para ativar o desconto.</ListItem>       
                        <ListItem><b>Language</b> - Lingua para os eventos do serviço por defeito. A lingua para cada evento específico poderá ser alterada nas opções de "Slots Languages".</ListItem>                                                                                                                                      
                      </OrderedList>
                      <Text> Em cada linha da lista de épocas: </Text>
                      <UnorderedList>
                        <ListItem><img src={daysBtn} alt='[Days]' style={buttonImg}/> - Acesso à página de gestão das opções de dias de semana e horários.</ListItem>
                        <ListItem><img src={editSmallBtn} alt='[Edit]' style={buttonImg}/> - Edição de época. Abre janela de alteração de dados.</ListItem> 
                        <ListItem><img src={deleteSmallBtn} alt='[Delete]' style={buttonImg}/> - Remove época da lista.</ListItem>                       
                      </UnorderedList>                                                             
                      <List5 id="days"> Days </List5>
                        <Text>Gestão dos dias da semana e dos horários de início e fim de cada dia. </Text>
                        <img src={days01} alt='[Days]' style={docImg}/>                                              
                        <Text><img src={workingDaysBtn} alt='[Add Working Days]' style={buttonImg}/> - Criação dias de semana. Abre janela de inserção de dados: </Text>                          
                        <img src={addWorkingDays} alt='Add Working Days' style={docImg}/>                        
                        <UnorderedList>
                          <ListItem><b>Start Time</b> - Hora de início de eventos do dia.</ListItem>
                          <ListItem><b>End Time</b> - Hora de limite de início de último evento do dia .</ListItem> 
                          <ListItem><b>Tickets</b> - Número de bilhetes disponíveis para o evento para cada horário.</ListItem>                       
                        </UnorderedList>                                                                        
                        <img src={days02} alt='Days' style={docImg}/>                          
                        <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de novo dia. Abre janela de inserção de dados: </Text>
                        <img src={addDay} alt='Add Day' style={docImg}/>  
                        <UnorderedList>
                          <ListItem><b>Day Week</b> - Dia da semana a criar.</ListItem>
                          <ListItem><b>Start Time</b> - Hora de início de eventos do dia.</ListItem>
                          <ListItem><b>End Time</b> - Hora de limite de início de último evento do dia .</ListItem> 
                          <ListItem><b>Tickets</b> - Número de bilhetes disponíveis para o evento para cada horário.</ListItem>                       
                          <ListItem><b>Active</b> - Ativação de dia.</ListItem>                          
                        </UnorderedList> 
                    <List4 id="events"> Events</List4> 
                      <Text>Criação de eventos fora do padrão de época. </Text>
                      <img src={events} alt='[Events]' style={docImg}/>
                      <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de evento isolado, fora do padrão da época. Abre janela de inserção de dados: </Text>
                      <img src={addEvent} alt='Add Event' style={docImg}/>                    
                      <UnorderedList>
                        <ListItem><b>Date</b> - Dia do evento.</ListItem> 
                        <ListItem><b>Time</b> - Horário do evento.</ListItem>                       
                      </UnorderedList>
                      <Text> Em cada linha da lista de eventos: </Text>
                      <UnorderedList>
                        <ListItem><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Edição de evento. Abre janela de alteração de dados.</ListItem> 
                        <ListItem><img src={deleteBtn} alt='[Delete]' style={buttonImg}/> - Remove evento da lista.</ListItem>                       
                      </UnorderedList>                                                
                    <List4 id="blocks"> Blocks</List4> 
                      <Text>Gestão dos bloqueios de horários e dias do serviço. </Text>
                      <img src={blocks} alt='[Blocks]' style={docImg}/>
                      <Text><img src={blockBtn} alt='[Block Range]' style={buttonImg}/> - Criação de bloqueio de conjunto de dias. Abre janela de inserção de dados: </Text>
                      <img src={addBlockRange} alt='Add Block Range' style={docImg}/>
                      <UnorderedList>
                        <ListItem><b>Start Date</b> - Data de início de bloqueio.</ListItem> 
                        <ListItem><b>End Date</b> - Data de fim de bloqueio.</ListItem>
                        <ListItem><b>Show On Calendar</b> - Definição de visibilidade dos dias bloqueados, no calendário da página de reservas.</ListItem>
                      </UnorderedList>
                      <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de bloqueio de dia ou horário específico. Quando horário não é selecionado, bolqueia o dia completo. Abre janela de inserção de dados: </Text>
                      <img src={addBlockDaySlot} alt='Add Block Day/Slot' style={docImg}/>                    
                      <UnorderedList>
                        <ListItem><b>Date</b> - Dia a bloquear.</ListItem> 
                        <ListItem><b>Slot</b> - Horário a bloquear.</ListItem>
                        <ListItem><b>Show On Calendar</b> - Definição de visibilidade do dia bloqueado, no calendário da página de reservas.</ListItem>
                      </UnorderedList>
                      <Text> Em cada linha da lista de bloqueios: </Text>
                      <UnorderedList>
                        <ListItem><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Edição de bloqueio. Abre janela de alteração de dados.</ListItem> 
                        <ListItem><img src={deleteBtn} alt='[Delete]' style={buttonImg}/> - Remove bloqueio da lista.</ListItem>                       
                      </UnorderedList>                     
                    <List4 id="coupons"> Coupons </List4>
                      <Text>Gestão dos códigos de cupões de desconto a inserir pelo cliente no checkout, quando esta opção está ativada. </Text>                                            
                      <img src={coupons} alt='Coupons' style={docImg}/>
                      <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de código de desconto. Abre janela de inserção de dados: </Text>
                      <img src={addCoupons} alt='Add Coupons' style={docImg}/>
                      <UnorderedList>
                        <ListItem><b>Name</b> - Nome do desconto</ListItem> 
                        <ListItem><b>Code</b> - Código do desconto</ListItem>
                        <ListItem><b>Value p/ticket</b> - Preço do bilhete do código.</ListItem>
                        <ListItem><b>Start Date</b> - Início da validade do código.</ListItem> 
                        <ListItem><b>End Date</b> - Fim da validade do código.</ListItem>
                        <ListItem><b>Utilizations</b> - Número máximo de utilizações (número de bilhetes vendidos) do código.</ListItem>                      
                      </UnorderedList>
                      <Text> Em cada linha da lista de cupões: </Text>                    
                      <UnorderedList>
                        <ListItem><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Edição de bloqueio. Abre janela de alteração de dados.</ListItem> 
                        <ListItem><img src={deleteBtn} alt='[Delete]' style={buttonImg}/> - Remove bloqueio da lista.</ListItem>                       
                      </UnorderedList>
                    <List4 id="slots-languages"> Slots Languages</List4>
                      <Text>Gestão das línguas dos horários do evento. </Text>
                      <img src={slotsLanguages} alt='[Slots Languages]' style={docImg}/>                       
                      <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Alteração de língua para um horário de evento específico. Abre janela de inserção de dados: </Text>
                      <img src={addSlotLanguage} alt='Add Slot Language' style={docImg}/> 
                      <UnorderedList>
                        <ListItem><b>Date</b> - Data do horário de alteração de língua.</ListItem> 
                        <ListItem><b>Slot</b> - Horário de alteração de língua.</ListItem>
                        <ListItem><b>Language</b> - Língua específica do horário.</ListItem>
                      </UnorderedList>
                      <Text> Em cada linha da lista de línguas: </Text>                    
                      <UnorderedList>
                        <ListItem><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Edição de dados. Abre janela de alteração de dados.</ListItem> 
                        <ListItem><img src={deleteBtn} alt='[Delete]' style={buttonImg}/> - Remove linha da lista.</ListItem>                       
                      </UnorderedList>                                            
                    <List4 id="ticket-types"> Ticket Types</List4> 
                      <Text>Gestão dos tipos de bilhetes do serviço/evento. </Text>
                      <img src={ticketTypes} alt='Ticket Types' style={docImg}/>                       
                      <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de novo tipo de bilhete. Abre janela de inserção de dados: </Text>
                      <img src={addTicketType} alt='Add Ticket Type' style={docImg}/> 
                      <UnorderedList>
                        <ListItem><b>Ticket Type PT</b> - Nome do tipo de bilhete em português.</ListItem> 
                        <ListItem><b>Ticket Type EN</b> - Nome do tipo de bilhete em inglês.</ListItem>
                        <ListItem><b>Ticket Type FR</b> - Nome do tipo de bilhete em francês.</ListItem>
                        <ListItem><b>Ticket Price</b> - Preço do tipo de bilhete.</ListItem> 
                        <ListItem><b>Season</b> - Época do tipo de bilhete.</ListItem>
                        <ListItem><b>Ticket Active</b> - Opção de ativação do tipo de bilhete.</ListItem>                        
                      </UnorderedList>
                      <Text> Em cada linha da lista de tipos de bilhete: </Text>                    
                      <UnorderedList>
                        <ListItem><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Edição do tipo de bilhete. Abre janela de alteração de dados.</ListItem> 
                        <ListItem><img src={deleteBtn} alt='[Delete]' style={buttonImg}/> - Remove tipo de bilhete da lista.</ListItem>                       
                      </UnorderedList>                                             
                    <List4 id="duration-interval"> Duration & Interval </List4> 
                      <Text>Definição da duração de cada evento e da duração do intervalo entre eventos. </Text>                     
                      <img src={management02} alt='[Duration and Interval]' style={docImg}/> 
                        <UnorderedList>
                          <ListItem><b>Duration</b> - Duração do evento em minutos.</ListItem> 
                          <ListItem><b>Interval</b> - Intervalo de tempo entre eventos em minutos.</ListItem>                       
                        </UnorderedList>                        
                        <Text><img src={saveBtn} alt='[Save]' style={buttonImg}/> - Gravação de valores inseridos. </Text>                        
                 <List3 id="emails"> 2.3.2. Emails </List3>
                    <Text>Página de gestão dos templates de email. </Text>
                    <img src={managementEmail} alt='Management Email' style={docImg}/>                    
                    <List4 id="backoffice-templates"> Backoffice Templates </List4>
                      <Text>Gestão dos templates do backoffice</Text>
                      <img src={backofficeTemplates} alt='Backoffice Templates' style={docImg}/>                    
                      <Text><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Acesso à página de alteração de templates. </Text>                                    
                      <img src={emailEditor} alt='[Email Editor]' style={docImg}/> 
                      <Text>Pagina de criação e edição de templates do Backoffice</Text>
                      <Text><img src={instructionsEmailBtn} alt='[Instructions for Email]' style={buttonImg}/>- Instruções com variáveis a utilizar no corpo do email.</Text>                      
                      <Text><img src={saveBtn} alt='[Save]' style={buttonImg}/> - Gravação de textos de emails inseridos para cada uma das línguas. </Text>                  
                    <List4 id="mailchimp-templates"> Mailchimp Templates </List4>
                      <Text>Gestão dos templates do Mailchimp.</Text>                                          
                      <img src={mailchimpTemplates} alt='Mailchimp Templates' style={docImg}/>
                      <Text><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Acesso à página de alteração de templates. </Text>                                                                              
                      <Text><img src={infoBtn} alt='[Info]' style={buttonImg}/> - Abertura de tabela com estatísticas do template. </Text>                                                        
                      <br />
                      <Text>Tabela com estatísticas do template selecionado:</Text> 
                      <img src={templateInfo} alt='Template Statistics' style={docImg}/>                      
                      <Text>Página de criação, edição, seleção e eliminação de templates do Mailchimp:</Text>
                      <img src={mailchimpEditor} alt='Mailchimp Editor' style={docImg}/> 
                      <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de novo template. Abre campo para inserção de nome do novo template: </Text>
                      <img src={addTemplate} alt='Add Template' style={docImg}/>
                      <img src={deleteBtn} alt='[Delete]' style={buttonImg}/> - Remove template da lista, após confirmação:                                              
                      <img src={deleteTemplate} alt='Delete Template' style={docImg}/>  
                      <Text><img src={instructionsEmailBtn} alt='[Instructions for Email]' style={buttonImg}/> - Instruções com variáveis a utilizar no corpo do email.</Text>                      
                      <Text><img src={saveBtn} alt='[Save]' style={buttonImg}/> - Gravação de textos de emails inseridos para cada uma das línguas. </Text> 
            <List1 id="reservations" >3. Reservations </List1>
              <Text>Página de visualização, criação e edição de reservas da bilheteira. </Text>
              <img src={reservations} alt='Reservations' style={docImg}/>
              <img src={reservations01} alt='Reservations' style={docImg}/>
              <Text>Campos de filtragem da lista de reservas:</Text>
              <UnorderedList>
                <ListItem><b>Service</b> - Serviço para o qual se pretende visualizar as reservas.</ListItem> 
                <ListItem><b>Date</b> - Data das reservas que se pretendem visualizar, ou início do período de visualização se o campo "End Date" também for inserido.</ListItem>
                <ListItem><b>End Date</b> - Data de fim do período das reservas que se pretendem visualizar.</ListItem> 
                <ListItem><b>Search by Name or Email</b> - Texto que faça parte do nome ou email das reservas a visualizar.</ListItem>                                        
              </UnorderedList>              
              <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de nova reserva. Abre janela de inserção de dados: </Text>
              <img src={addReservation01} alt='Add Reservation' style={docImg}/>
              <img src={addReservation02} alt='Add Reservation' style={docImg}/>
              <UnorderedList>
                <ListItem><b>Name</b> - Nome do dono da reserva. Será também o nome na fatura.</ListItem> 
                <ListItem><b>Email</b> - Email do dono da reserva.</ListItem>
                <ListItem><b>Country</b> - País do dono da reserva.</ListItem>
                <ListItem><b>Contact</b> - Contacto telefónico do dono da reserva.</ListItem> 
                <ListItem><b>VAT</b> - NIF de faturação da reserva.</ListItem>
                <ListItem><b>Obs</b> - Observações ou pedidos por parte do dono da reserva.</ListItem> 
                <ListItem><b>Date</b> - Data da reserva.</ListItem>
                <ListItem><b>Service</b> - Serviço da reserva.</ListItem>
                <ListItem><b>Quantity</b> - Para serviços de tipo de bilhete único. Quantidade de bilhetes reservados. A lista só aparece quando forem selecionados o serviço e a respetiva data do evento.</ListItem>
                <ListItem><b>Ticket Types List</b> - Para serviços com multíplos tipos de bilhete. Quantidade de bilhetes reservados por tipo de bilhete.</ListItem>                
                <ListItem><b>Slot</b> - Horário da reserva.</ListItem>
                <ListItem><b>Send Invoice</b> - Opção de envio de fatura.</ListItem>
              </UnorderedList>
              <Text> Em cada linha da lista de reservas: </Text>                    
              <UnorderedList>
                <ListItem><img src={infoBtn} alt='[Info]' style={buttonImg}/> - Apresenta detalhes dos bilhetes da reserva.</ListItem>                  
                <img src={reservations02} alt='Add Reservation' style={docImg}/>              
                <ListItem><img src={editSmallBtn} alt='[Edit]' style={buttonImg}/> - Edição de reserva. Abre janela de alteração de dados.</ListItem>
                <ListItem><img src={deleteSmallBtn} alt='[Delete]' style={buttonImg}/> - Remove linha da lista.</ListItem>                       
              </UnorderedList>                                                                                     
            <List1 id="schedule">4. Schedule </List1>
              <Text>Página de visualização das reservas por horário da bilheteira. Por defeito, apresenta todos os horários do dia de hoje para o serviço selecionado. A listagem pode ser alterada através dos campos de filtragem no topo da página. Os horários bloqueados estão indicados com a côr de fundo de acordo com a legenda. </Text>                          
              <img src={schedule} alt='Schedule' style={docImg}/>
              <Text>Campos de filtragem da lista de horários:</Text>
              <UnorderedList>
                <ListItem><b>Service</b> - Serviço para o qual se pretende visualizar os horários.</ListItem> 
                <ListItem><b>Date</b> - Data de início dos horários a visualizar.</ListItem>
                <ListItem><b>End Date</b> - Data de fim dos horários a visualizar.</ListItem> 
              </UnorderedList>
            <List1 id="invoices">5. Invoices </List1>
              <Text>Página de visualização das faturas emitidas da bilheteira. </Text>
              <img src={invoices} alt='Invoices' style={docImg}/>
              <Text>Campos de filtragem da lista de faturas:</Text>
              <UnorderedList>
                <ListItem><b>Service</b> - Serviço para o qual se pretende visualizar as faturas.</ListItem> 
                <ListItem><b>Date</b> - Data das reservas cujas faturas se pretendem visualizar, ou início do período de visualização se o campo "End Date" também for inserido.</ListItem>
                <ListItem><b>End Date</b> - Data de fim do período cujas faturas que se pretendem visualizar.</ListItem> 
                <ListItem><b>Search by Name or Email</b> - Texto que faça parte do nome ou email das reservas cujas faturas se pretende visualizar.</ListItem>                                        
              </UnorderedList>
              <Text> Em cada linha da lista de faturas: </Text>                    
              <UnorderedList>
                <ListItem><b>[Invoice]</b> - Apresenta fatura em formato pdf.</ListItem>                                        
              </UnorderedList>                                                         
            <List1 id="scanner">6. Scanner </List1>
              <Text>Página de scanning de QR codes emitidos nos bilhetes para controlo de acessos a cada evento. </Text>                          
              <img src={scanner} alt='Code Scanner' style={docImg}/>
              <Text>Aquando da execução da leitura do código, existem três tipos de resultados possíveis:</Text>                                      
              <UnorderedList>
                <ListItem><b>Entrada Válida (Verde)</b> - Código válido.</ListItem> 
                <ListItem><b>Erro de Leitura (Laranja)</b> - Tentar nova leitura.</ListItem>
                <ListItem><b>Entrada Inválida (Vermelho)</b> - Código inválido ou já utilizado.</ListItem> 
              </UnorderedList>            
            <List1 id="settings">7. Settings</List1>
              <Text>Página de gestão das opções da bilheteira.</Text>
              <Text>Cada grupo de opções tem os seguintes botões.</Text>
              <UnorderedList>
                <ListItem><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Desbloqueia os dados do grupo de opções para edição e mostra os botões [Save] e [Cancel] na linha final do grupo.</ListItem>
                <ListItem><img src={saveBtn} alt='[Save]' style={buttonImg}/> - Grava os dados alterados.</ListItem>                  
                <ListItem><img src={cancelBtn} alt='[Cancel]' style={buttonImg}/> - Cancela a alteração de dados.</ListItem>                       
              </UnorderedList>                            
              <List4 id="general-settings">General Settings</List4>
                <List5 id="support-text">Support Text</List5>
                  <img src={supportText} alt='Support Text' style={docImg}/>
                  <UnorderedList>
                    <ListItem><b>Active</b> - Opção de ativação do texto de suporte.</ListItem>
                  </UnorderedList>
                  <Text>Campos de inserção de dados:</Text>
                  <UnorderedList>
                    <ListItem><b>Support Text PT</b> - Texto de suporte ao cliente da bilheteira em português.</ListItem> 
                    <ListItem><b>Support Text EN</b> - Texto de suporte ao cliente da bilheteira em inglês.</ListItem>
                    <ListItem><b>Support Text FR</b> - Texto de suporte ao cliente da bilheteira em francês.</ListItem>
                  </UnorderedList> 
                <List5 id="event-languages">Event Languages</List5>
                <img src={eventLanguages} alt='Event Languages' style={docImg}/>
                  <UnorderedList>
                    <ListItem><b>Active</b> - Opção de ativação das línguas de eventos.</ListItem> 
                  </UnorderedList>
                <img src={generalSettings} alt='General Settings' style={docImg}/>
                  <Text>As línguas inseridas na tabela "Languages", aparecem listadas para seleção.</Text>                                 
                <List5 id="color-system">Color System</List5>
                  <UnorderedList>
                    <ListItem><b>Active</b> - Opção de ativação do sistema de cores na página de reservas.</ListItem>
                  </UnorderedList>                                  
                <List5 id="deadline-booking">Deadline Booking</List5>
                  <UnorderedList>
                    <ListItem>Inserção da hora limite para efetuar reserva, para os eventos do dia de hoje.</ListItem>
                  </UnorderedList>                
                <List5 id="checkout-fields">Checkout Fields</List5>
                  <UnorderedList>
                    <ListItem><b>Coupons</b> - Opção de ativação do campo de códigos de desconto.</ListItem>
                    <ListItem><b>Special Requests</b> - Opção de ativação do campo de observações/pedidos especiais.</ListItem>
                  </UnorderedList>
              <List4 id="email-settings">Email Settings</List4>
              <img src={emailSettings} alt='Email Settings' style={docImg}/>
              <UnorderedList>
                <ListItem><b>QR Code Tickets in Email</b> - Opção de enviar pdf de bilhetes com QR Code como anexo no email de confirmação de reserva.</ListItem>
              </UnorderedList>
              <img src={emailSettings02} alt='Email Settings' style={docImg}/>              
                <UnorderedList>
                  <ListItem><b>Send Notification Emails</b> - Opção de ativação dos emails de confirmação de reserva.</ListItem>
                </UnorderedList>
                <Text>Campos de inserção de dados da conta de email de onde serão enviados os emails de confirmação de reserva:</Text>
                <UnorderedList>
                  <ListItem><b>Nodemailer Default From</b> - Endereço de email de onde serão enviados os emails.</ListItem> 
                  <ListItem><b>Nodemailer Reply To</b> - Endereço de email para onde irão seguir as respostas ds clientes.</ListItem>
                  <ListItem><b>Host</b> - Servidor onde está alojada a conta de email.</ListItem>
                  <ListItem><b>Port</b> - Porta de acesso ao servidor do email.</ListItem>
                  <ListItem><b>Username</b> - Nome de utilizador da conta de email. Habitualmente o próprio endereço de email.</ListItem>
                  <ListItem><b>Password</b> - Password de acesso à conta de email.</ListItem>                                                        
                </UnorderedList>                
                <Text><img src={testEmailBtn} alt="[Send an email to your account's email address to confirm that everything is configured correctly.]" style={buttonImg}/> - Botão de envio de email de teste do funcionamento dos emails de confirmação de reserva.</Text>                                 
              <img src={emailSettings03} alt='Email Settings' style={docImg}/>
              <UnorderedList>
                <ListItem><b>Use Mailchimp</b> - Opção de utilização do servidor do Mailchimp para o envio de emails.</ListItem>
                <ListItem><b>Using Backoffice Templates vs. Using Mailchimp Templates</b> - Opção de utilização de templates do backoffice ou do Mailchimp.</ListItem>
                <ListItem><b>Send Backup Email</b> - Opção de email de backup para onde serão enviados todos os emails da bilheteira enviados a clientes.</ListItem>                
              </UnorderedList>              
              <List4 id="invoicing-settings">Invoicing Settings</List4>
              <img src={invoicingSettings} alt='Invoicing Settings' style={docImg}/>
                <UnorderedList>
                  <ListItem><b>Active</b> - Opção de ativação da criação e envio de faturas aquando da criação de cada reserva.</ListItem>
                </UnorderedList> 
                <Text>Campos de inserção de dados da conta do invoiceXpress:</Text>
                <UnorderedList>
                  <ListItem><b>InvoiceXpress Account Name</b> - Nome da conta do invoiceXpress.</ListItem>                
                  <ListItem><b>InvoiceXpress API Key</b> - Chave de acesso à conta do invoiceXpress.</ListItem>                                                        
                </UnorderedList>
                <Text><img src={ixBtn} alt='[Sign up to your InvoiceXpress account]' style={buttonImg}/> - Link à página de criação de conta do invoiceXpress. Após a criação da conta, terá acesso à chave e ao nome da conta, para inserção destes dados nos campos referidos e poder iniciar a emissão e envio automático de faturas. Para tal, deverá também configurar a sua conta do invoiceXpress com os seus dados fiscais e cumprir os passos indicados pelo invoiceXpress para que as faturas sejam corretamente emitidas.</Text>                                                       
            <List1 id="users">8. Users </List1> 
              <Text>Página de gestão dos utilizadores do backoffice da bilheteira. </Text>
              <img src={users} alt='Users' style={docImg}/>                           
              <Text><img src={createBtn} alt='[Create New]' style={buttonImg}/> - Criação de novo utiliador. Abre janela de inserção de dados: </Text>
              <img src={addUser} alt='Add User' style={docImg}/>
              <UnorderedList>
                <ListItem><b>Username</b> - Nome de utilizador de acesso à conta.</ListItem>
                <ListItem><b>Password</b> - Password de acesso à conta.</ListItem>
                <ListItem><b>Email</b> - Email associado à conta.</ListItem> 
                <ListItem><b>User Profile</b> - Perfil de utilizador que define o nível de acesso.</ListItem>                       
                <ListItem><b>Services</b> - Serviços aos quais o utilizador tem acesso.</ListItem> 
                <ListItem><b>Confirmed</b> - Confirmação de email.</ListItem> 
                <ListItem><b>Blocked</b> - Opção de bloqueio da conta.</ListItem>                                                          
              </UnorderedList>
              <Text> Em cada linha da lista de utilizadores: </Text>                    
              <UnorderedList>
                <ListItem><img src={editBtn} alt='[Edit]' style={buttonImg}/> - Edição de utilizador. Abre janela de alteração de dados.</ListItem>
                <ListItem><img src={deleteBtn} alt='[Delete]' style={buttonImg}/> - Remove utilizador da lista.</ListItem>                       
              </UnorderedList> 
              <Text style={{textAlign:'right', margin: '20px'}}>Updated - 08/11/2023</Text>              
          </DivDoc>                                                                                                                                                                                   
        </Container>
      </Wrapper>
    </Section>   
  ) 
}

/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

/**
 * Classes
 */

/* Control the left side */
const splitLeft = {
  height: '100%',
  width: '25%',
  position: 'fixed',
  zIndex: 1,
  top: 0,
  overflowX: 'hidden',
  paddingTop: '20px',
  left: 0,  
}

/* Control the right side */
const splitRight = {
  width: '65%',
  position: 'fixed',
  zIndex: 1,
  right: '5%',
  overflowX: 'visible',
  overflowY: 'scroll',  
}

const docImg = {
  border: '2px solid #373737',  
  maxWidth: '90%',  
  margin: '20px auto 20px 20px',
}

const buttonImg = {
  verticalAlign: 'middle',
}


/**
 * Styles
 */

const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
`;

const DivDoc = styled.div`
  position:relative;
  height: calc(100% - 110px);
  top: 110px;
  @media screen and (max-width:1350px){
    height: calc(100% - 150px);
    top: 150px;
    }
  }
  @media screen and (max-width:900px){
    height: calc(100% - 180px);
    top: 180px;
    background: #fff;
    }
  }
`
const UnorderedList = styled.ul`
  margin-left: 25px;
  color: #373737;  
`;
const OrderedList = styled.ol`
  margin-top: 0px;
  margin-left: 25px;
  color: #373737;  
`;
const ListItem = styled.li`
  color: #373737;
  margin: 5px 15px;
  line-height:24px;
`;
const Title = styled.div`
  font-size: 16px;
  margin:24px 10px 18px;
  font-weight: bold;
  color: #373737;
`;
const Text = styled.div`
  color: #373737;
  font-weight: normal;  
  margin:10px 15px;
  line-height:24px;
  `;
const List1 = styled(H4)`
margin-top: 10px;
font:SFRegular; 
font-weight: bold;
margin-top: 25px;
`;
const List2 = styled(H4)`
font-size: 22px;
margin-top: 5px;
font:SFRegular; 
font-weight: bold;
margin-top: 25px;
`;
const List3 = styled(H4)`
font-size: 20px;
font:SFRegular; 
font-weight: bold;
margin-top: 25px;
`;
const List4 = styled(H5)`
font-size: 20px;
font:SFRegular; 
font-weight: normal;
margin-top: 25px;
`;
const List5 = styled(H6)`
font-size: 18px;
font:SFRegular; 
font-weight: normal;
margin-left: 5px;
margin-top: 25px;
`;