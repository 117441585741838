/**
 * @file Defines H2 style
 */

import styled, { css } from 'styled-components';

let fontMax = 56;
let fontMin = 24;

const H2 = styled.h2`
  font-family: ${({ font }) => font ? font : 'SFSemiBold'};
  font-size: calc(${fontMin}px + (${fontMax} - ${fontMin}) * ((100vw - 320px) / (1920 - 320)));	
  text-align: ${({ align }) => align ? align : 'left'};
  line-height: ${({ height }) => height ? height : '64'}px;
  color: ${({ color }) => color ? color : '#373737'};
  transition: all 0.5s ease;

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  @media screen and (min-width: 1920px) {
    font-size: ${fontMax}px;
  }
`;

export default H2;