/**
 * @file Allows html editing
 */

import React, { Component } from 'react';

/**
 * @component HtmlEditor
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * -
 */
export default class HtmlEditor extends Component {
  render() {
    return (
      <></>
    );
  }
}
