/**
 * @file Defines Checkbox style
 */

import React from 'react';
import styled, { css } from 'styled-components';

/**
 * @component Checkbox
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * -
 */
const Checkbox = ({ className, checked, value, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} value={value} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="18 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox;


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */
 
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right:8px;
`;
const Icon = styled.svg`
  fill: none;
  stroke-width: 2px;
  stroke: rgba(78,93,107,0.5);
  height: 12px;
  width: 12px;
`;
const StyledCheckbox = styled.div`
  border-radius: 25%;
  border: 1px solid rgba(78,93,107,0.5);
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0.75px #5a5a5a;
  }
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent;
  transition: all 150ms;
  display:flex;
  align-items:center;
  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
  ${({ checked }) => checked && css`
    border-color:#373737;
    ${Icon} {
      stroke: #373737;
    }
  `}
`;
