/* eslint-disable */

/**
 * @file Renders backoffice login page
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import { setCookie, checkCookie } from '../auth';
import IconLogin from '../static/icon_login.svg';

import {
  H4,
  Text11,
  Text10
} from '../atoms';

import { useHistory } from "react-router-dom";


/**
 * Manages rendering of backoffice login page
 * 
 * @component Login
 *
 * @param ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */
export default function Login(props) {


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(true);
  const [errorLogin, setErrorLogin] = useState(false);
 

/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */ 

  const history = useHistory();

  useEffect(() => {
    if(checkCookie('token')){
      history.push('/home');
    }
  }, [])


/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */ 

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;


/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */  

/**
 * @function loginHandler
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const loginHandler = () => {
    axios
      .post(BACKEND_DOMAIN + "/auth/local", {
        identifier: username,
        password: password
      })
      .then(response => {
        // Handle success.
        setError(false);
        console.log(response.data)
        setCookie(response.data.jwt);

        console.log(response.data.user)

        sessionStorage.setItem("bilheteira", response.data.user.bilheteira.id);
        sessionStorage.setItem("username", response.data.user.username);
        sessionStorage.setItem("user-profile", response.data.user.user_profile.permissionsLevel);

        let userProfile = response.data.user.user_profile.permissionsLevel
        let user = response.data.user.username

        history.push('/home', {user: user, userProfile: userProfile});
      })
      .catch(error => {
        alert("Invalid username or password. Please retry.")

        setError(true);
        setErrorLogin(true);
      });
  };


  return (
    <Wrapper>
      <Container>
        <ContainerLogin>
          {console.log(username)}  
                
          <Title>Welcome</Title>
          <DivInput>
            <Label>
              Username*
            </Label>
            <Input
              type="text"
              onChange={e => {
                setUsername(e.target.value)
                props.onLogin(e)
              }}
              error={errorLogin}
              placeholder="Username"
            />
          </DivInput>
          <DivInput>
            <Label>
              Password*
            </Label>
            <Input
              type="password"
              onChange={e => setPassword(e.target.value)}
              error={errorLogin}
              placeholder="Password"
            />
          </DivInput>
          <DivInput>
            <LabelMandatory>
              *Mandatory fields
            </LabelMandatory>
          </DivInput>
          <Button 
            onClick={() => {
              loginHandler()
            }}
          >
            Login <ImgLogin src={IconLogin} />
          </Button>
        </ContainerLogin>
      </Container>
    </Wrapper>
  );
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const Wrapper = styled.div`
  position: relative;
  padding-top: calc(50px + (100 - 50) * ((100vw - 375px) / (1920 - 375)));
  padding-bottom: calc(50px + (100 - 50) * ((100vw - 375px) / (1920 - 375)));
  min-height:100vh;
  width:100%; 
  box-sizing:border-box;
  display: flex;
  align-items: center;
`;
const Container = styled.div`
  position: relative;
  width: 1530px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1920px) {
    width: calc(100% - 350px);
    margin-left: 175px;
    margin-right: 175px;
  }
  @media only screen and (max-width: 1440px) {
    width: calc(100% - 300px);
    margin-left: 150px;
    margin-right: 150px;
  }
  @media only screen and (max-width: 1280px) {
    width: calc(100% - 240px);
    margin-left: 120px;
    margin-right: 120px;
  }
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 180px);
    margin-left: 90px;
    margin-right: 90px;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 120px);
    margin-left: 60px;
    margin-right: 60px;
  }
  @media only screen and (max-width: 578px) {
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
  }
`;
const ContainerLogin = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  flex-flow:column;
`;
const Title = styled(H4)`
  color: #FFCE20;
  margin-bottom:56px;
`;
const DivInput = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  &:last-child{
      margin-bottom:30px;
  }
  @media screen and (max-width:600px){
    width: 100%;
  }
`;
const Label = styled(Text10)`
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #4E5D6B;
  opacity: 0.5;
  margin-bottom:8px;
`;
const Input = styled.input`
  border: none;
  outline: none;
  text-align: left;
  width: 372px;
  margin-bottom: 16px;
  padding: 16px 24px;
  color: #4E5D6B;
  background: #FFFFFF;
  border: 1px solid #E4EBF0;
  border-radius: 2px;
  ::placeholder{
    opacity:0.5;
  }
  ${({ error }) => error && css`
    border-color: red;
  `}
  @media screen and (max-width:600px){
    width: 100%;
    box-sizing: border-box;
  }
  
`;
const LabelMandatory = styled(Text11)`
  color: #4E5D6B;
  opacity: 0.5;
  margin-bottom:8px;
`;
const ImgLogin = styled.img`
  width:14px;
  height:14px;
`;
const Button = styled.div`
  display:flex;
  width:176px;
  justify-content:space-between;
  align-items: center;
  margin-top:56px;
  background: #FFCE20;
  border: 1px solid #FFCE20;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 24px;
  transition:all 0.3s ease;
  color: #F5F7F7;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  :hover{
    cursor: pointer;
    background: #f8c100;
  }
`;