/**
 * @file Defines Margin style
 */


import styled from 'styled-components';

const Margin = styled.div`
  margin-bottom: ${({ margin }) => margin ? margin * 8 : 8}px;
`;

export default Margin;