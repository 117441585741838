/**
 * @file Defines Text11 style
 */


import styled, { css } from 'styled-components';

const Text11 = styled.div`
  font-family: ${({ font }) => font ? font : 'SFSemiBold'};
  font-size: 11px;
  line-height:${({ height }) => height ? 24 : 16}px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : '#373737'};

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}
  span{
    color:#FF7575;
  }
`;

export default Text11;