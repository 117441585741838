/**
 * @file Defines component that renders emails management page
 */

/* eslint-disable */

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import styled, { css } from "styled-components";
import Switch from "react-switch";
import { useParams, useHistory } from "react-router-dom";

import iconEdit from '../static/icon_editar.svg';
import iconDelete from '../static/icon_lixo.svg';
import iconLeft from '../static/icon_left.svg';
import iconRight from '../static/icon_right.svg';
import iconInfo from '../static/icon_info.png';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H2,
  H3,
  H4,
  H5,
  H6,
  Text12,
  Text11,
  Loading
} from '../atoms';

import {
  ModalEmail,
  ModalNotification,
  Table,
} from '../molecules';


/**
 * Manages rendering of selected service management page
 * 
 * @component EmailsManagement
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 * 
 */ 
export default function ManagementEmail(props) {

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  let params = useParams();
  let history = useHistory();  

  const bilheteiraId = sessionStorage.getItem('bilheteira')  

  console.log('SERVICE ID')
  console.log(bilheteiraId)  

  let serviceId = params.id
  //let serviceId = props.match.params.id;

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;
  const FRONTEND_DOMAIN = props.FRONTEND_DOMAIN;
  const endpoint = props.endpoint

  let user = props.user;
  let userProfile = props.userProfile;    

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); 

  const templatesTitles = [
      {label: "Reservation confirmation PT:", nameKey: "emailReservationConfirmationPT", slugKey:"slugEmailReservationConfirmationPT"},
      {label: "Reservation confirmation EN:", nameKey: "emailReservationConfirmationEN", slugKey:"slugEmailReservationConfirmationEN"},
      {label: "Reservation confirmation FR:", nameKey: "emailReservationConfirmationFR", slugKey:"slugEmailReservationConfirmationFR"},
      {label: "Reservation notification PT (1 day before):", nameKey: "emailReservationNotificationPT", slugKey:"slugEmailReservationNotificationPT"},
      {label: "Reservation notification EN (1 day before):", nameKey: "emailReservationNotificationEN", slugKey:"slugEmailReservationNotificationEN"},
      {label: "Reservation notification FR (1 day before):", nameKey: "emailReservationNotificationFR", slugKey:"slugEmailReservationNotificationFR"},
      {label: "Vouchers Email PT:", nameKey: "emailVouchersPT", slugKey:"slugEmailVouchersPT"},
      {label: "Vouchers Email EN:", nameKey: "emailVouchersEN", slugKey:"slugEmailVouchersEN"},
      {label: "Vouchers Email FR:", nameKey: "emailVouchersFR", slugKey:"slugEmailVouchersFR"}    
    ]

/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [interfaceApiKey, setInterfaceApiKey] = useState('')
  const [configData, setConfigData] = useState('')
  const [templatesData, setTemplatesData] = useState('')
  const [templateHistory, setTemplateHistory] = useState({})
  const [databaseField, setDatabaseField] = useState('')
  const [name, setName] = useState('')
  const [labels, setLabels] = useState([])
  const [token, setToken] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [days, setDays] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [slotsLanguages, setSlotsLanguages] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [emails, setEmails] = useState([]);
  const [url, setUrl] = useState([]);
  const [serviceData, setServiceData] = useState({});    
  const [servicePath, setServicePath] = useState('');              // Selected service url path component

  const [isOpen, setIsOpen] = useState(false);
  const [openSubTable, setOpenSubTable] = useState(false);
  const [loading, setLoading] = useState(false); 

/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  useEffect(() => {
    getCredentials();
  }, [])

  useEffect(() => {
    getTemplates(token)
  }, [configData])


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

//NONE

/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */


/**
 * @function getCredentials
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token')

    setLoading(true)    
    setToken(temp)

    getData(temp)
  }


/**
 * @function getData
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getData = async (key) => {
    await Promise.all([
      //getUrl(key),
      getServiceData(key),
      getConfigData(key),      
    ])
    
    console.log('TRACK')

    setLoading(false)
  }


/**
 * @function getConfig - Gets bilheteira config data and sets Mailchimp interface API key.
 * 
 * TO IMPROVE:
 */
const getConfigData = async (key) => {
    console.log('ENTERED GETCONFIGDATA:')
    console.log(bilheteiraId)
    console.log(BACKEND_DOMAIN)

    axios
      .get(BACKEND_DOMAIN + '/configuracoes?bilheteira=' + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data[0])

        setConfigData(response.data[0])
        setInterfaceApiKey(response.data[0].mailchimp_interface_api_key)
        setLabels(response.data[0].bilheteira.nome)

        setLoading(false)
      })
      .catch(error => {
        console.error(error)

        setLoading(false)
      });
  }


/**
 * @function getUrl
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
 /* const getUrl = async (key) => {
    await axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {

        console.log(response.data)

        setUrl(response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  }*/ 

/**
 * @function getServiceData
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getServiceData = async (key) => {
    await axios
      .get(BACKEND_DOMAIN + "/servicos/" + serviceId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        console.log(response.data)
        
        setUrl(response.data.urlDomain)
        setServicePath (response.data.url)
        setServiceData(response.data)
      })
      .catch(error => {
        console.log(error)
      });
  }

/**
 * @function getTemplates - Gets Mailchimp templates.
 * 
 * TO IMPROVE:
 */
  const getTemplates = async (key) => {    
    console.log(configData)

    if (configData) {
      await axios
        .post(BACKEND_DOMAIN + "/emails/gettemplates", {
          endpoint: endpoint,
          configData: configData
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          }
        })
        .then(response => {
          console.log(response.data)

          setTemplatesData(response.data.response)     
          //setSlug(response.data.response.filter(data => data.name == name)[0].slug)  
        })
        .catch(error => {
          console.error(error)
        })
    }
  } 

/**
 * @function changePath
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - ?Deprecated?
 */
  const changePath = (path) => {
    console.log(props.history)
    
    props.history.push(`/${path}`);
  }


/**
 * @function openEditEmail
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const openEditEmail = (templateName) => {
    history.push({
      pathname: 'management-email/edit-email',
      id: serviceId,
    });
  } 


/**
 * @function openEditTempalte
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const openEditTemplate = (templateTitle, templateSlug, dbNameKey, dbSlugKey) => {
    history.push({
      pathname: 'management-email/edit-template',
      id: serviceId,
      state: { templateTitle, templateSlug, dbNameKey, dbSlugKey },      
    });
  } 

/**
 * @function showTemplateHistory
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const showTemplateHistory = async (slug, dbSlugField) => {    
    console.log(interfaceApiKey)
    console.log(slug)
    console.log(dbSlugField)

    const requestData = {
      name: slug,
    }

    if (interfaceApiKey) {
      await axios.post(BACKEND_DOMAIN + "/emails/timeseriestemplate", {
        endpoint: endpoint,
        interfaceApiKey: interfaceApiKey,
        requestData: requestData
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(response => {
        console.log(response.data)
       
        setOpenSubTable(!openSubTable)
        setDatabaseField(dbSlugField)
        setTemplateHistory(response.data)
      })
      .catch(error => {
        console.error(error)
      })
    }
  }    

  return (
    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper>
          <Container>
            <H2
              font='SFRegular'
              style={{fontSize: '1.2em', margin: '-35px 0 -20px 0', display: 'inline-block'}}
            >
              Service Url: 
            </H2>
            <div style={{paddingLeft: '20px', display: 'inline-block'}}>{url + "?srv=" + servicePath}</div>                    
            <Margin margin={4} />
            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Backoffice Email Templates               
            </H3> 
            <Margin margin={4} /> 
            <DivTemplate>           
              <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
                Manage backoffice email templates:
              </H5> 
              <ButtonAllReser 
                onClick={() => openEditEmail()} 
                style={{ width: '82px', marginLeft: '20px', boxSizing: 'border-box' }}
              >                
                <Icon src={iconEdit} /> Edit
              </ButtonAllReser>
            </DivTemplate>            
            <Margin margin={6} />
            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Mailchimp Email Templates               
            </H3>              
            <Margin margin={4} />

            {console.log(serviceData)}

            {templatesTitles.map((title, index) => 
            <div key={index}>

              {console.log(index)}
              {console.log(title)}
              
              <DivTemplate>            
                <TemplateTitle font='SFRegular'>
                  {title.label}
                </TemplateTitle>
                <TextBox>
                  {serviceData[title.nameKey]}
                </TextBox>
                <ButtonInfo 
                  onClick={() => {
                    showTemplateHistory(serviceData[title.slugKey], title.slugKey)
                  }}
                > 
                  <Icon src={iconInfo} /> 
                </ButtonInfo>             
                <ButtonAllReser 
                  onClick={() => openEditTemplate(title.label, serviceData[title.slugKey] , title.nameKey, title.slugKey)} 
                  style={{ width: '82px', marginLeft: '20px', boxSizing: 'border-box' }}
                >                
                  <Icon src={iconEdit} /> Edit
                </ButtonAllReser>
              </DivTemplate>
                {openSubTable==true && databaseField == title.slugKey &&
                  <DivTabela >

                    {console.log(index)}
                    {console.log(templateHistory)}

                    <Table >
                      <thead style={{border: 'none'}}>
                        <tr >                             
                          <th>Clicks</th>                                
                          <th>Complaints</th>
                          <th>Hard Bounces</th>
                          <th>Soft Bounces</th>                                                                     
                          <th>Opens</th> 
                          <th>Rejects</th>
                          <th>Sent</th>                                
                          <th>Unique Clicks</th>
                          <th>Unique Opens</th>                                              
                          <th>Unsubscribes</th>                                                                                                                                                 
                        </tr>
                      </thead>
                      <tbody>
                        {(templateHistory[0] || templateHistory.length == 0) &&
                          <tr>                    
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.clicks,0)}</td>                              
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.complaints,0)}</td>
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.hard_bounces,0)}</td>
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.soft_bounces,0)}</td>                      
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.opens,0)}</td>
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.rejects,0)}</td>                                             
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.sent,0)}</td>
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.unique_clicks,0)}</td>
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.unique_opens,0)}</td>
                            <td style={{textAlign: 'center'}}>{templateHistory.length == 0 ? 0 : templateHistory.reduce((sum,item) => sum+item.unsubs,0)}</td>                                                                
                          </tr>
                        }                              
                      </tbody>
                    </Table >              
                  </DivTabela >
                }
                <Margin margin={2} />
              </div>
            )}                           
          </Container>
        </Wrapper>
      }
    </Section>
  )
}



/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const DivCards = styled.div`
  display:flex;
  flex-flow:column;
  margin-bottom: 20px;
  :last-child {
    margin-bottom:0;
  }
`;
const Cards = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-top:16px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  box-sizing: border-box;
`;
const Card = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: 274px;
  min-height: 215px;
  box-sizing: border-box;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;
const DivReservation = styled.div`
  margin: 0px -24px -24px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const CardHtml = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: calc(50% - 20px);
  height: 100%;
  min-height:400px;
  flex-flow: column;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  .sun-editor{
    min-height:300px;
    .se-container{
      min-height:300px;
      display: flex;
      flex-flow: column;
    }
    .se-wrapper{
      flex:1;
      .se-wrapper-wysiwyg{
        min-height:200px;
      }
    }
  }
  .htmlEditor{
    border:unset;
    font-family: SFRegular;
    margin-top:-8px;
  }
  .htmlEditor-toolbar{
    border:unset;
    font-family: SFRegular;
    margin:0;
    margin-top:8px;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    border-bottom:1px solid #EBEBEB;
  }
  .htmlEditor-editor{
    font-family: SFRegular;
  }

  @media only screen and (max-width: 720px) {
    width: calc(100% - 20px);
  }
`;
const DivHtmlEditor = styled.div`
  margin: -24px -24px 0px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const DivTitleHtmlEditor = styled.div`
  display:flex;
  flex-flow:column;
`;
const DivTitleEmail = styled.div`
  padding:8px 24px;
  margin: 0 -24px;
  border-bottom:1px solid #EBEBEB;
  margin-bottom:8px;
  display:flex;
  flex-flow:column;
`;
const TitleEmail = styled(Text11)`
  margin-bottom:8px;
`;
const TextInput = styled.input.attrs(props => ({
  type: "text"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const TitleReservation = styled(Text11)`
  color: #A9A9A9;
  text-transform: uppercase;
`;
const DateReservation = styled(Text11)`
`;
const DivTextCardReservation = styled.div`
  display:flex;
  flex-flow:column;
`;
const NumberReservation = styled(H3)`
  font-weight: normal;
`;
const LabelReservation = styled(Text12)`
  margin-bottom:24px;
  font-weight: normal;
`;

const Toggle = styled(Switch)`
  .react-switch-bg{
    background-color:#fff !important;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
  }
`;
const ContainerInterval = styled.div`
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  border-bottom: unset;
  padding: 24px;
  max-width:568px;
  box-sizing: border-box;
  display:flex; 
  flex-direction:column;
`;
const DivInputsInterval = styled.div`
  display:flex;
  flex-flow:row;
`;
const NumberInput = styled.input.attrs(props => ({
  type: "number"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const DivInterval = styled.div`
  width: calc(50% - 10px);
  margin-right:20px;
  display:flex;
  flex-flow:column;
  :last-child{
    margin-right: 0;
  }
`;
const ContainerSaveInterval = styled.div`
  max-width:568px;
  padding: 16px 24px;
  box-sizing:border-box;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  border-radius:6px;
  border-top:unset;
  border-top-left-radius:0;
  border-top-right-radius:0;
`;
const DivTemplate = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items: center;
  margin-top:16px;
  margin-left: 10px;
  box-sizing: border-box;  
`;
const Icon = styled.img`
  width:14px;
  height:14px;
  margin-right:8px;
  z-index: 10;
`;

const DivTabela = styled.div`
  width:100%;
  overflow:scroll;
  margin-top: 10px;
  margin-left: 20px;
`;

const TemplateTitle = styled(H5)`
  font-weight: normal;
  width:430px;
`;
const TextBox =styled.div`  
  margin: 0px 5px;
  padding: 8px 13px 11px 13px;
  box-sizing:border-box;
  height:36px;
  width:40%;
  white-space: nowrap;
  overflow: hidden;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  border-radius:6px;
`;
const ButtonInfo = styled.div`
  margin: 0px 5px;
  background: #63666A;
  border-radius: 2px;
  padding: 11px 5px 11px 13px;
  display: flex;
  align-items: center;
  : center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px;  
  :hover{
    background: #393D47;
    cursor: pointer;
  }
`;
const ButtonAllReser = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;