/**
 * @file Defines component that allows writing emails body for strapi emails plugin 
 */

/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import Switch from "react-switch";
import arrowLeft from '../static/icon_arrow_left.svg';
import iconEdit from '../static/icon_editar.svg';
import iconDelete from '../static/icon_lixo.svg';
import iconLeft from '../static/icon_left.svg';
import iconRight from '../static/icon_right.svg';
import { useParams, useHistory } from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H2,
  H3,
  H5,
  H6,
  Text12,
  Text11,
  Loading
} from '../atoms';

import {
  ModalEmail,
  ModalNotification
} from '../molecules';

/**
 * Manages rendering of writing emails page
 * 
 * @component EmailsWriting
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 * 
 */ 
export default function EditEmail (props) {

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  let params = useParams();
  let history = useHistory();  

  const bilheteiraId = sessionStorage.getItem('bilheteira')  

  console.log('SERVICE ID')
  console.log(bilheteiraId)  

  let serviceId = params.id
  //let serviceId = props.match.params.id;

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;
  const FRONTEND_DOMAIN = props.FRONTEND_DOMAIN;

  let user = props.user;
  let userProfile = props.userProfile;    

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const settingsEmail = [
    ['undo', 'redo', 'fontSize', 'formatBlock', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview']
  ]  


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */	
  const [token, setToken] = useState('');

	const [url, setUrl] = useState([]);
  const [servicePath, setServicePath] = useState('');              // Selected service url path component

  const [statusEmail, setStatusEmail] = useState(false)
  const [emails, setEmails] = useState([])
  const [instructions, setInstructions] = useState(false)
  const [notification, setNotification] = useState(false)	 
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [languages, setLanguages] = useState([])

  const [title, setTitle] = useState('')
	const [htmlPrePt, setHtmlPrePt] = useState('')
  const [htmlPosPt, setHtmlPosPt] = useState('')
  const [htmlVouPt, setHtmlVouPt] = useState('')
  const [htmlPreEn, setHtmlPreEn] = useState('')
  const [htmlPosEn, setHtmlPosEn] = useState('')
  const [htmlVouEn, setHtmlVouEn] = useState('')     
  const [htmlPreFr, setHtmlPreFr] = useState('')
  const [htmlPosFr, setHtmlPosFr] = useState('')
  const [htmlVouFr, setHtmlVouFr] = useState('')  
  const [htmlPrePtTitle, setHtmlPrePtTitle] = useState('')
  const [htmlPosPtTitle, setHtmlPosPtTitle] = useState('')
  const [htmlVouPtTitle, setHtmlVouPtTitle] = useState('')  
  const [htmlPreEnTitle, setHtmlPreEnTitle] = useState('')
  const [htmlPosEnTitle, setHtmlPosEnTitle] = useState('')
  const [htmlVouEnTitle, setHtmlVouEnTitle] = useState('')  
  const [htmlPreFrTitle, setHtmlPreFrTitle] = useState('')
  const [htmlPosFrTitle, setHtmlPosFrTitle] = useState('')
  const [htmlVouFrTitle, setHtmlVouFrTitle] = useState('')  
  
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)


/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */
  useEffect(() => {
    getCredentials()
  }, [])

  useEffect(() => {
    console.log(emails)
  }, [emails])


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

//NONE

/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */


/**
 * @function getCredentials
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token')

    setToken(temp)
    getData(temp)
    getDataEmails(temp)
  }


/**
 * @function getData
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getData = async (key) => {
    setLoading(true)

    await Promise.all([
      getUrl(key),
      getServicePath(key), 
      getLanguages(key),     
    ])

    setLoading(false)    
  }


/**
 * @function getDataEmails
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const getDataEmails = (key) => {
    axios.get(BACKEND_DOMAIN + "/emails" + '?_limit=-1', {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
    .then(function (response) {
      if (response.data && response.data.length > 0) {
        console.log(response.data)
        console.log(response.data.filter(x=>Number(x.servico.id) === Number(serviceId)))

        const emailsFilter = response.data.filter(x=>Number(x.servico.id) === Number(serviceId))

        setEmails(response.data.filter(x=>Number(x.servico.id) === Number(serviceId)))

        for (let i = 0; i < emailsFilter.length; i++) {
          if (emailsFilter[i].type === 'lembrete' || emailsFilter[i].emailtype?.type === 'lembrete') {
            if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'pt') || emailsFilter[i].Linguagem === 'pt') {
                setHtmlPosPt(emailsFilter[i].Body);
                setHtmlPosPtTitle(emailsFilter[i].Subject);
            } else if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'en') || emailsFilter[i].Linguagem === 'en') {
                setHtmlPosEn(emailsFilter[i].Body);
                setHtmlPosEnTitle(emailsFilter[i].Subject);
            } else if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'fr') || emailsFilter[i].Linguagem === 'fr') {
                setHtmlPosFr(emailsFilter[i].Body);
                setHtmlPosFrTitle(emailsFilter[i].Subject);
            }
          } else if (emailsFilter[i].type === 'reserva' || emailsFilter[i].emailtype?.type === 'reserva') {
            if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'pt') || emailsFilter[i].Linguagem === 'pt') {
                setHtmlPrePt(emailsFilter[i].Body);
                setHtmlPrePtTitle(emailsFilter[i].Subject);
            } else if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'en') || emailsFilter[i].Linguagem === 'en') {
                setHtmlPreEn(emailsFilter[i].Body);
                setHtmlPreEnTitle(emailsFilter[i].Subject);
            } else if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'fr') || emailsFilter[i].Linguagem === 'fr') {
                setHtmlPreFr(emailsFilter[i].Body);
                setHtmlPreFrTitle(emailsFilter[i].Subject);
            }
          } else if (emailsFilter[i].type === 'vouchers'  || emailsFilter[i].emailtype?.type === 'vouchers') {
            if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'pt') || emailsFilter[i].Linguagem === 'pt') {
                setHtmlVouPt(emailsFilter[i].Body);
                setHtmlVouPtTitle(emailsFilter[i].Subject);
            } else if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'en') || emailsFilter[i].Linguagem === 'en') {
                setHtmlVouEn(emailsFilter[i].Body);
                setHtmlVouEnTitle(emailsFilter[i].Subject);
            } else if ((emailsFilter[i].lingua && emailsFilter[i].lingua.Codigo.toLowerCase() === 'fr') || emailsFilter[i].Linguagem === 'fr') {
                setHtmlVouFr(emailsFilter[i].Body);
                setHtmlVouFrTitle(emailsFilter[i].Subject);
            }
          }
        }
      }
    })
    .catch(function (error) {
      console.error(error)
    })
  }


/**
 * @function sendEmail
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const sendEmail = async (type, lang) => {
    console.log(languages)

    let tempId
    let langId    
    let body
    let title

    if(emails.filter(x=>Number(x.servico.id) === Number(serviceId) &&

      (x.type === type || x.emailtype?.type === type) &&
        ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang)).length > 0) {
          //console.log(emails.filter(x=>Number(x.servico.id) === Number(serviceId) && x.type === type && x.Linguagem === lang ))
        
        if (type === 'reserva') {
          if (lang === 'pt') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && 
              (x.type === type || x.emailtype?.type === type) && 
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlPrePt
            title = htmlPrePtTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          } else if (lang === 'en') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) &&
              (x.type === type || x.emailtype?.type === type) &&
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlPreEn
            title = htmlPreEnTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          } else if (lang === 'fr') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) &&
              (x.type === type || x.emailtype?.type === type) &&
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlPreFr
            title = htmlPreFrTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          }        
        } else if (type === 'lembrete') {
          if (lang === 'pt') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && 
              (x.type === type || x.emailtype?.type === type) && 
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlPosPt
            title = htmlPosPtTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          } else if (lang === 'en') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && 
              (x.type === type || x.emailtype?.type === type) && 
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlPosEn
            title = htmlPosEnTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          } else if (lang === 'fr') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && 
              (x.type === type || x.emailtype?.type === type) && 
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlPosFr
            title = htmlPosFrTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          }        
        } else if (type === 'vouchers') {
          if (lang === 'pt') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && 
              (x.type === type || x.emailtype?.type === type) && 
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlVouPt
            title = htmlVouPtTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          } else if (lang === 'en') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && 
              (x.type === type || x.emailtype?.type === type) && 
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlVouEn
            title = htmlVouEnTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          } else if (lang === 'fr') {
            tempId = emails.filter(x=>Number(x.servico.id) === Number(serviceId) && 
              (x.type === type || x.emailtype?.type === type) && 
              ((x.lingua && x.lingua.Codigo.toLowerCase() === lang) || x.Linguagem === lang) )[0].id;
            body = htmlVouFr
            title = htmlVouFrTitle
            langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
          }        
        }

        axios
          .put(BACKEND_DOMAIN + "/emails/" + tempId, {
            Body: body,
            Subject: title,
            lingua: langId
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then(function (response) {
            console.log(response.data)
            console.log(emails)

            setEmails(emails.map(item => {
              if (item.id == tempId) {
                const updatedItem = { 
                  ...item,
                  Body: response.data.Body,
                  Subject: response.data.Subject,
                  lingua: response.data.lingua
                }

                item == updatedItem
              }

              return item
            }))

            setNotification(true)
            setStatusEmail(true)
            setSuccessMessage('Email template changed.')
            setTimeout(() => {
              setNotification(false)
            }, 2000);
          })
          .catch(function (error) {
            setNotification(true)
            setStatusEmail(false)
            setSuccessError('Error changing email template.')          
            setTimeout(() => {
              setNotification(false)
            }, 2000)
          })

          return
    } else {
      if (type === 'reserva') {
        if (lang === 'pt') {
          body = htmlPrePt
          title = htmlPrePtTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        } else if (lang === 'en') {
          body = htmlPreEn
          title = htmlPreEnTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id          
        } else if (lang === 'fr') {
          body = htmlPreFr
          title = htmlPreFrTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        }        
      } else if (type === 'lembrete') {
        if (lang === 'pt') {
          body = htmlPosPt
          title = htmlPosPtTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        } else if (lang === 'en') {
          body = htmlPosEn
          title = htmlPosEnTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        } else if (lang === 'fr') {
          body = htmlPosFr
          title = htmlPosFrTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        }
      } else if (type === 'vouchers') {
        if (lang === 'pt') {
          body = htmlVouPt
          title = htmlVouPtTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        } else if (lang === 'en') {
          body = htmlVouEn
          title = htmlVouEnTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        } else if (lang === 'fr') {
          body = htmlVouFr
          title = htmlVouFrTitle
          langId = languages.find(lng => lng.Codigo.toLowerCase() === lang).id
        }
      }

      const typeId = await axios.get(BACKEND_DOMAIN + "/emailtypes", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(function (response) {
        console.log(response.data)

        const typeId = response.data.find(t => t.type === type)

        return(typeId)
      })
      .catch(function (error) {
        console.error(error)

        setNotification(true)
        setStatusEmail(false)
        setErrorMessage('Error creating email template.')          
        setTimeout(() => {
          setNotification(false)
        }, 2000)
      })

      console.log(typeId)

      axios
        .post(BACKEND_DOMAIN + "/emails", {
          servico:serviceId,        
          emailtype: typeId,
          Body: body,
          Subject: title,
          lingua: langId
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(function (response) {
          console.log(response.data)

          const newEmail = response.data 

          setEmails([...emails, newEmail])

          setNotification(true)
          setStatusEmail(true)
          setSuccessMessage('Email template created.')
          setTimeout(() => {
            setNotification(false)
          }, 2000)
        })
        .catch(function (error) {
          console.log(error)

          setNotification(true)
          setStatusEmail(false)
          setErrorMessage('Error creating email template.')          
          setTimeout(() => {
            setNotification(false)
          }, 2000)
        })

        return
    }
  }


/**
 * @function getUrl
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getUrl = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {

        console.log(response.data)

        setUrl(response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  } 

/**
 * @function getServicePath
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getServicePath = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/servicos/" + serviceId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        setServicePath (response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  }


/**
 * @function getLanguages - Gets available languages from database.
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state 
 *   (setLanguageOption + setSelectedLanguages)
 */
  const getLanguages = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/linguas", {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(function (response) {
        console.log(response.data);

        setLanguages(response.data)
      })
      .catch(function (error) {
        console.error(error)
      })
  }  
       	  

	return (

    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper> 
          <Container>
	          <BackButton onClick={() => history.push('/service/' + serviceId + '/management-email')}><IconSVG src={arrowLeft} />Back</BackButton>          
	        	<DivHeader>
	            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
	              {title}
	            </H3>
	          </DivHeader>	
						<DivCards>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
                  Email Editor
                </H5>
                <ButtonAllReser onClick={() => setInstructions(true)}>
                  Instructions for Email
                </ButtonAllReser>
              </div>
              <Margin margin={2} />
              <H6 font='SFRegular' style={{ fontWeight: 'normal' }}>
                🇵🇹 Portuguese
              </H6>
              <Cards>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Confirmation Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm reservation to the client
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('reserva', 'pt')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlPrePtTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlPrePtTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlPrePt}
                    onChange={(event) => setHtmlPrePt(event)}
                  />
                </CardHtml>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Notification Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm presence one day before the visit.
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('lembrete', 'pt')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlPosPtTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlPosPtTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlPosPt}
                    onChange={(event) => setHtmlPosPt(event)}
                  />
                </CardHtml>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Vouchers Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm vouchers purchase.
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('vouchers', 'pt')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlVouPtTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlVouPtTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlVouPt}
                    onChange={(event) => setHtmlVouPt(event)}
                  />
                </CardHtml>                
              </Cards>
              <Margin margin={2} />
              <H6 font='SFRegular' style={{ fontWeight: 'normal' }}>
                🇬🇧 English
              </H6>
              <Cards>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Confirmation Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm reservation to the client
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('reserva', 'en')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlPreEnTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlPreEnTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlPreEn}
                    onChange={(event) => setHtmlPreEn(event)}
                  />
                </CardHtml>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Notification Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm presence one day before the visit.
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('lembrete', 'en')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlPosEnTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlPosEnTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlPosEn}
                    onChange={(event) => setHtmlPosEn(event)}
                  />
                </CardHtml>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Vouchers Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm the vouchers purchase.
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('vouchers', 'en')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlVouEnTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlVouEnTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlVouEn}
                    onChange={(event) => setHtmlVouEn(event)}
                  />
                </CardHtml>                
              </Cards>
              <Margin margin={2} />                
              <H6 font='SFRegular' style={{ fontWeight: 'normal' }}>
                🇫🇷 French
              </H6>
              <Cards>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Confirmation Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm reservation to the client
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('reserva', 'fr')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlPreFrTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlPreFrTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlPreFr}
                    onChange={(event) => setHtmlPreFr(event)}
                  />
                </CardHtml>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Notification Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm presence one day before the visit.
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('lembrete', 'fr')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlPosFrTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlPosFrTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlPosFr}
                    onChange={(event) => setHtmlPosFr(event)}
                  />
                </CardHtml>
                <CardHtml>
                  <DivHtmlEditor>
                    <DivTitleHtmlEditor>
                      <H6>
                        Vouchers Email
                      </H6>
                      <Text12 font='SFRegular' color='#8B8B8B' style={{ fontWeight: 'normal' }}>
                        Email to confirm the vouchers purchase.
                      </Text12>
                    </DivTitleHtmlEditor>
                    <ButtonAllReser onClick={() => sendEmail('vouchers', 'fr')}>
                      Save
                    </ButtonAllReser>
                  </DivHtmlEditor>
                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Title Email' }}
                    />
                    <TextInput
                      value={htmlVouFrTitle}
                      placeholder='Type the title for the email where'
                      onChange={(event) => setHtmlVouFrTitle(event.target.value)}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    setContents={htmlVouFr}
                    onChange={(event) => setHtmlVouFr(event)}
                  />
                </CardHtml>                
              </Cards>            
            </DivCards>	
          </Container>
          <ModalEmail
            isOpen={instructions}
            setInstructions={setInstructions}
            serviceId={serviceId}
          />
          <ModalNotification
            isOpen={notification}
            status={statusEmail}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />               
        </Wrapper>
      }
    </Section>		
	)
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */
const BackButton = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:114px;
  padding:12px 24px;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  background: #EBEBEB;
  transition: all 0.5s ease;
  :hover{
    background: #A9A9A9;
    cursor:pointer;
  }
`;
const IconSVG = styled.img`
  width:5px;
  height:9px;
`;

const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin:24px 0;
  @media screen and (max-width:570px){
    flex-flow:column;
    align-items:flex-start;
  }
`;
const DivCards = styled.div`
  display:flex;
  flex-flow:column;
  margin-bottom: 20px;
  :last-child {
    margin-bottom:0;
  }
`;
const Cards = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-top:16px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  box-sizing: border-box;
`;
const Card = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: 274px;
  min-height: 215px;
  box-sizing: border-box;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;
const DivReservation = styled.div`
  margin: 0px -24px -24px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const CardHtml = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: calc(50% - 20px);
  height: 100%;
  min-height:400px;
  flex-flow: column;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  .sun-editor{
    min-height:300px;
    .se-container{
      min-height:300px;
      display: flex;
      flex-flow: column;
    }
    .se-wrapper{
      flex:1;
      .se-wrapper-wysiwyg{
        min-height:200px;
      }
    }
  }
  .htmlEditor{
    border:unset;
    font-family: SFRegular;
    margin-top:-8px;
  }
  .htmlEditor-toolbar{
    border:unset;
    font-family: SFRegular;
    margin:0;
    margin-top:8px;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    border-bottom:1px solid #EBEBEB;
  }
  .htmlEditor-editor{
    font-family: SFRegular;
  }

  @media only screen and (max-width: 720px) {
    width: calc(100% - 20px);
  }
`;
const DivHtmlEditor = styled.div`
  margin: -24px -24px 0px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const DivTitleHtmlEditor = styled.div`
  display:flex;
  flex-flow:column;
`;
const DivTitleEmail = styled.div`
  padding:8px 24px;
  margin: 0 -24px;
  border-bottom:1px solid #EBEBEB;
  margin-bottom:8px;
  display:flex;
  flex-flow:column;
`;
const TitleEmail = styled(Text11)`
  margin-bottom:8px;
`;
const TextInput = styled.input.attrs(props => ({
  type: "text"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const TitleReservation = styled(Text11)`
  color: #A9A9A9;
  text-transform: uppercase;
`;
const DateReservation = styled(Text11)`
`;
const DivTextCardReservation = styled.div`
  display:flex;
  flex-flow:column;
`;
const NumberReservation = styled(H3)`
  font-weight: normal;
`;
const LabelReservation = styled(Text12)`
  margin-bottom:24px;
  font-weight: normal;
`;
const ButtonAllReser = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;
const Toggle = styled(Switch)`
  .react-switch-bg{
    background-color:#fff !important;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
  }
`;
const ContainerInterval = styled.div`
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  border-bottom: unset;
  padding: 24px;
  max-width:568px;
  box-sizing: border-box;
  display:flex; 
  flex-direction:column;
`;
const DivInputsInterval = styled.div`
  display:flex;
  flex-flow:row;
`;
const NumberInput = styled.input.attrs(props => ({
  type: "number"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const DivInterval = styled.div`
  width: calc(50% - 10px);
  margin-right:20px;
  display:flex;
  flex-flow:column;
  :last-child{
    margin-right: 0;
  }
`;
const ContainerSaveInterval = styled.div`
  max-width:568px;
  padding: 16px 24px;
  box-sizing:border-box;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  border-radius:6px;
  border-top:unset;
  border-top-left-radius:0;
  border-top-right-radius:0;
`;