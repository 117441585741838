/* eslint-disable */

/**
 * @file Renders code scanning page
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import Switch from "react-switch";
//import QrReader from 'react-qr-scanner';
//import {QrReader} from 'react-qr-reader';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Canvas from '../atoms/form/canvas'

import Select from 'react-select';
import Lottie from 'react-lottie';
import animationData from '../lotties/qr-code-scan2';

import {
  Container,
  Wrapper,
  Section,
  H3,
  Text11,  
  Text14,
  Loading,
  Margin,
} from '../atoms';

import {
  ModalScanner,
} from '../molecules';


/**
 * Manages rendering of code scanning page
 * 
 * @component Reservations
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */
export default function ScanCode(props) {

/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [loading, setLoading] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState('');
  const [result, setResult] = useState('No result');
  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [userProfile, setUserProfile] = useState('');  
  const [errorMessage, setErrorMessage] = useState('');
  const [screenMessage, setScreenMessage] = useState(true);
  const [delay, setDelay] = useState(10000);
  const [data, setData] = useState('No result');


/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */  

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;  

  const lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };  

  var filter = document.getElementById("filter")

  const scanStyle = {
    display:'block',
    position: 'absolute',
    right:0,
    left: 0,
    top:0,
    height: '200px',
    width:'200px',
    objectFit: 'cover',
    justifyContent: 'center',
    alignText:'center',   
  }  


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  const handleScan = (data) => {
    console.log('DATA: ' + data)
    if (data != null) {    
      setScanned(true)
      setIsOpen(true)  
      setLoading(true)  
      setResult(data)   
    } 
  }

  const handleError= (errorMessage) => {
      console.log('ERROR: ' + errorMessage)

    if(errorMessage != 'Não legível: falhou a identificação do código')
      setErrorMessage(errorMessage)
      setScanned(true)  
      //setIsOpen(true)         

      console.log(errorMessage)
  }

  useEffect(() => {
    getCredentials();
  }, []);

  useEffect(() => {
    getTicketId(result);
  }, [result]);


/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */  

/**
 * @function getCredentials
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token');
    let tempUserProfile = sessionStorage.getItem('user-profile');  

    setToken(temp);
    setUserProfile(tempUserProfile);  
  };

  const getTicketId = async (result) => {
    if (scanned == true) {
      setLoading(true);
      const newString = null

      console.log('TOKEN:')
      console.log(token)

      await axios
        .get(BACKEND_DOMAIN + "/bilhetes"+'?_limit=-1', 
          { headers: 
            {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async response => {
          console.log(response.data)
          console.log(result)

          var existentTicket = false

          await response.data.map(ticket => {
            if (ticket.QRcode == result) {
              checkEntry(ticket.id)
              existentTicket = true
            }
          })

          console.log('STRING:' + newString)
          console.log(existentTicket)

          if (existentTicket == false) {
            setLoading(false)     
            setSuccess(false)
            setErrorMessage('QR Code/Bilhete não existente') 

            filter.style.background="rgb(255,0,0,0.5)";    
            setTimeout(() => (filter.style.background="rgb(255,0,0,0)"), 6000)  
          }
        })
        .catch(error => {
          setLoading(false)
          setSuccess(false)
          setErrorMessage('Erro na busca de bilhete 1.')

          filter.style.background="rgb(255,0,0,0.5)";   
          setTimeout(() => (filter.style.background="rgb(255,0,0,0)"), 6000)            
        });
    }
  };
    

/**
 * @function toogleModal
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const toogleModal = () => {
    console.log('TOGGLED')
    setScanned(false)
    setLoading(false)
    setIsOpen(false);
    setResult('No result')
  };  


/**
 * @function checkEntry
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const checkEntry = async (ticketId) => {
    setLoading(true);

    await axios
      .get(BACKEND_DOMAIN + "/bilhetes/" + ticketId + '?_limit=-1',{
      headers: 
          {
            Authorization: `Bearer ${token}`,
          },
        })
      .then (async response => {
        console.log(response)

        if (response.data.entrada !== true) {
          await axios
            .put(BACKEND_DOMAIN + "/bilhetes/" + ticketId + '?_limit=-1', {
              entrada: true 
            }, {
              headers: 
                {
                  Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
              setLoading(false)
              setSuccess(true)

              filter.style.background="rgb(0,255,0,0.5)";                
              setTimeout(() => (filter.style.background="rgb(0,255,0,0)"), 6000)                      
            })
            .catch(error => {
              setLoading(false)
              setSuccess(false)
              setErrorMessage('Erro no registo de entrada. Tentar novamente.') 
              
              filter.style.background="rgb(255,0,0,0.5)";    
              setTimeout(() => (filter.style.background="rgb(255,0,0,0)"), 6000)      
            })  
        } else {
            setLoading(false)
            setSuccess(false)
            setErrorMessage('Bilhete já deu entrada previamente.')        
            
            filter.style.background="rgb(255,165,0,0.5)";    
            setTimeout(() => (filter.style.background="rgb(255,165,0,0)"), 6000) 
        } 
      })
      .catch(error => {          
        setLoading(false)
        setSuccess(false)
        setErrorMessage('Erro na busca de bilhete 2.')  

        filter.style.background="rgb(255,0,0,0.5)";    
        setTimeout(() => (filter.style.background="rgb(255,0,0,0)"), 6000)                
      });
  };


/**
 * RENDER
 *
 * TO IMPROVE:
 * ...
 */
  return(
    <Section style={{maxHeight: '300px'}}>
      {loading == true && 
        <DivLottie isLoading={loading}>
          <Lottie
            options={lottieOptions}
            height={"200px"}
            width={"200px"}
          />
        </DivLottie>
      }
      {loading == false &&
        <Wrapper style={{maxHeight: '300px'}}>
          <Container style={{maxHeight: '300px'}}>        
            <DivHeader>
              <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
                Code Scanner
              </H3>
            </DivHeader>         
            <DivReader>    
              <DivCanvas>
                <Canvas/>
              </DivCanvas> 
              <DivCam>
                <BarcodeScannerComponent
                  width={300}
                  height={270}
                  delay={1000}
                  facingMode={'environment'}  
                  onUpdate={(err, result) => {
                    if (result) {
                      handleScan(result.text)
                    } else {
                      handleError('Não legível: falhou a identificação do código');
                    }
                  }}
                />             
                {/*<QrReader
                  delay={3000}
                  style={scanStyle}                 
                  onError={handleError}
                  onScan={handleScan}
                  constraints={ {video: {facingMode: 'environment'} }}           
                />*/}

                {/*<QrReader
                  scanDelay={3000}               

                  videoStyle={scanStyle}                     
                  onResult={(result, error) => {
                    if (!!result) {
                      handleScan(result?.text)                      
                    }


                  }}                         
                />*/}
              </DivCam>               
            </DivReader>

            {console.log('RESULT:')}
            {console.log(result)}

          </Container>  
        </Wrapper>                  
      }
      {isOpen && 
        <Filter id="filter"/>
      } 
      <DivModal>     
        {isOpen && screenMessage &&
            <ModalScanner
              isOpen={isOpen}
              scanned={scanned}
              loading={loading}
              toogleModal={toogleModal}
              success={success}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
        }
      </DivModal>      
    </Section>
  )
}



/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
  @media screen and (max-width: 1200px){
    display:none;
    visibility: hidden;
  }
  @media screen and (max-width: 570px){
    display:none;    
    visibility: hidden;
  }  
`;
const DivReader = styled.div`
  poisition: absolute;
  top: 0;
  display: flex;
  justify-content: center;   
  @media screen and (max-width: 1200px){
    margin: 0px auto;
  }
  @media screen and (max-width: 570px){
    margin: 0px auto;
  }     
`;
const DivCam = styled.div`
  position: relative;
  display: block;
  justify-content:center;
  margin: 0;

  z-index: 666; 
  @media screen and (max-width: 1200px){
    width:200px;    
    margin-right: 100px;    
  }
  @media screen and (max-width: 570px){
    heigth:200px;
    width:250px;
    margin-right: 100px;  
    margin-left:50px; 
  }
`;

const DivCanvas = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content:center;  
  z-index: 777;   
  @media screen and (max-width: 1200px){
    margin: 0 auto;
  }
  @media screen and (max-width: 570px){
    height: 200;
    width: 200;
    margin: 0 auto;
  }   
`;
const DivLottie = styled.div`
  margin-top: 75px;
  @media screen and (max-width: 1200px){
    position: relative;

    z-index: 1111;   
    opacity: 1;
  }
`;

const Filter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh; 
  width: 100vw; 
  display: block;
  z-index: 888;  
`;
const DivModal = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 0px;
  @media screen and (max-width: 1200px){
    margin-top: 50px;
    display:flex;    
    visibility: visible;
  }
  @media screen and (max-width: 570px){
    position: absolute;
    display:flex;    
    visibility: visible;
    max-height: 300px;
  }  
`;
const Toggle = styled(Switch)`
  .react-switch-bg{
    background-color:#fff !important;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
  }
`;
const TextActive = styled(Text11)`
  margin-left: calc(4px + (10 - 4) * ((100vw - 375px) / (1920 - 375)));
  text-transform: uppercase;
  /*&#messages{
  @media screen and (max-width: 1200px){
      margin-top: 225px;
    }
    @media screen and (max-width: 570px){    
      margin-top: 225px;
    }  */
  } 
`;