/* eslint-disable */

/**
 * @file Defines component that allows writing emails body for strapi emails plugin 
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import moment from 'moment';

import arrowLeft from '../static/icon_arrow_left.svg';
import iconEdit from '../static/icon_editar.svg';
import iconDelete from '../static/icon_lixo.svg';
import iconLeft from '../static/icon_left.svg';
import iconRight from '../static/icon_right.svg';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H2,
  H3,
  H4,
  H5,
  H6,
  Text12,
  Text11,
  Loading
} from '../atoms';

import {
  Table
} from '../molecules';

/**
 * Manages rendering of writing emails page
 * 
 * @component SalesReportMonth
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 * 
 */ 
export default function SalesReportMonth (props) {

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */
  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;
  const FRONTEND_DOMAIN = props.FRONTEND_DOMAIN;  

  let history = useHistory();  

  console.log(props)
  console.log(history)

  const userId = history.location.state.userId
  const userName = history.location.state.user
  const reportUser = history.location.state.userData
  const bilheteiraId = sessionStorage.getItem('bilheteira')  

  console.log('SERVICE ID')
  console.log(bilheteiraId)  

  //let serviceId = params.id
  //let serviceId = props.match.params.id;

  const year = history.location.state.year 
  const month = history.location.state.month  
  const initialDate = moment(`${year}-${(month+1).toString().length == 1 ? '0' : ''}${(month+1).toString()}-01`).format('YYYY-MM-DD') + 'T00:00:00.000Z';
  const finalDate = moment(`${year}-${(month+1).toString().length == 1 ? '0' : ''}${(month+1).toString()}-01`).endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
  const daysInMonth = moment(`${year}-${(month+1).toString().length == 1 ? '0' : ''}${(month+1).toString()}-01`).daysInMonth()

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */	
  const [token, setToken] = useState('');

	const [url, setUrl] = useState([]);
  const [servicePath, setServicePath] = useState('');              // Selected service url path component

  const [notification, setNotification] = useState(false)	 
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)

  const [title, setTitle] = useState('')

  const [reportReservations, setReportReservations] = useState([])
  const [daysData, setDaysData] = useState([])
  const [reportType, setReportType] = useState('user')  


/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */
  useEffect(() => {
    getCredentials()
  }, [])

  useEffect(() => {
    if(token)getReportData(token)
  }, [reportType])


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  const handleSelectChange = (event) => {
    setReportType(event.target.value)
    // Perform any other actions based on the selected value
  };

/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */


/**
 * @function getCredentials
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token')

    setToken(temp)
    getData(temp)
  }


/**
 * @function getData
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getData = async (key) => {
    setLoading(true)

    await Promise.all([
      getUrl(key),
      getReportData(key),
      //getServicePath(key),    
    ])

    setLoading(false)    
  }


/**
 * @function getUrl
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getUrl = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {

        console.log(response.data)

        setUrl(response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  } 

/**
 * @function getServicePath
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  /*const getServicePath = (key) => {
    axios
      .get(BACKEND_DOMAIN + "/servicos/" + serviceId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        setServicePath (response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  }*/


/**
 * @function getUserReportData
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getUserMonthReservations = async (key, id) => {
    try {
      const response = await axios.post(BACKEND_DOMAIN + "/reservas/getusermonthreservations/", {
        userId: id,
        initialDate: initialDate,
        finalDate: finalDate, 
      },{
        headers: {Authorization: `Bearer ${key}`}
      })
   
    console.log(response.data)

    return response.data
  } catch(error) {
    console.error(error)

    throw error
  }
}


/**
 * @function getTeam
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getTeam = async (key, id) => {
    try {
      const response = await axios.get(BACKEND_DOMAIN + "/teams/"+'?manager='+reportUser.username,{
          headers: {Authorization: `Bearer ${key}`}
        })
      
      console.log(response.data[0].users)

      return response.data[0].users

    } catch(error) {
      console.error(error)

      throw error
    }
  }  

/**
 * @function salesDaysMonth
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const salesDaysMonth = (reportReservations) => {
    console.log('SET DAYSDATA')
    console.log(reportReservations)
    console.log(daysInMonth)

    if(reportReservations) {
      let reportData = []

      for (let i = 1; i<=daysInMonth; i++) {
        let reservations = 0
        let tickets = 0
        let value = 0
        let dayData = {}

        reportReservations.filter(res => moment(res.data).date() == i).map(res => {
          reservations = reservations + 1
          tickets = tickets + res.quantidade
          value = value + res.precoTotal
        })

        dayData = {
          day: i,
          reservations: reservations,
          tickets: tickets,
          value: value
        }

        reportData.push(dayData)
      }
      
      console.log(reportData)

      return reportData
    }
  }

/**
 * @function getReportData
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getReportData = async (key) => {
    let userReservations = []
    let teamReservations = []

    if (reportUser.teamManager !== true || reportType !== 'team') {
      console.log('USER')

      userReservations = await getUserMonthReservations(key, reportUser.id)

      const reportData = salesDaysMonth(userReservations)

      setDaysData(reportData)
    } else {
      console.log('TEAM')

      const teamMembers = await getTeam(key, reportUser.id)

      console.log(teamMembers)

      await Promise.all(
        teamMembers.map(async usr => {
          userReservations = await getUserMonthReservations(key, usr.id)

          console.log(userReservations)

          teamReservations = teamReservations.concat(userReservations)     
        })
      )

      console.log(teamReservations)

      const reportData = salesDaysMonth(teamReservations)

      setDaysData(reportData)
    }
  }

            	  
	return (
    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper> 
          <Container>
	          <BackButton onClick={() => history.push('/users')}><IconSVG src={arrowLeft} />Back</BackButton>          
	        	<DivHeader>
	            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
	              Sales Report | {(month+1).toString().length == 1 ? '0' : ''}{(month+1).toString()}/{year} - {reportUser.username}
	            </H3>

              {console.log(reportUser)}
              
              {reportUser.user_profile.permissionsLevel == 'Manager' &&
                <H4 style={{fontFamily: 'SFRegular', paddingTop:'10px'}}>
                  Report Type: 
                  <select value={reportType} onChange={handleSelectChange} style={{marginLeft: '10px'}}>
                    <option value='user'>User</option>
                    <option value='team'>Team</option>
                  </select>
                </H4> 
              }              
	          </DivHeader>	
            <Margin margin={5} />
            <DivTabela   style ={{display:'block', whiteSpace: 'nowrap', tableLayout: 'auto'}}>
              <ReportTable >
                <thead>
                  <tr >
                    <th style={{textAlign: 'center'}}>DAY</th>                                 
                    <th style={{textAlign: 'center'}}>RESERVATIONS</th> 
                    <th style={{textAlign: 'center'}}>TICKETS</th>
                    <th style={{textAlign: 'center', paddingRight: '50px'}}>VALUE</th>                                                               
                  </tr>
                </thead>

                {console.log(daysData)}

                <tbody style={{border: 'none'}}>
                  {daysData.map(day => 
                    <tr>
                      <td style={{textAlign: 'center', padding:'10px'}}>{day.day} </td>
                      <td style={{textAlign: 'center', padding:'10px'}}>{day.reservations} </td>
                      <td style={{textAlign: 'center', padding:'10px'}}>{day.tickets} </td>
                      <td style={{textAlign: 'right',  padding:'10px 90px 10px 10px', width: '100px'}}>{day.value.toFixed(2)} € </td>
                    </tr>
                  )}
                  <tr>
                    <td style={{textAlign: 'center', fontWeight: "bold"}}>TOTALS</td>
                    <td style={{textAlign: 'center', fontWeight: "bold"}}>{daysData.reduce((total, day) => total+day.reservations, 0)}</td>
                    <td style={{textAlign: 'center', fontWeight: "bold"}}>{daysData.reduce((total, day) => total+day.tickets, 0)}</td>
                    <td style={{textAlign: 'right', padding:'10px 90px 10px 10px', width: '100px', fontWeight: "bold"}}>{daysData.reduce((total, day) => total+day.value, 0).toFixed(2)} €</td>
                  </tr>
                </tbody>
                </ReportTable >
            </DivTabela>          					
          </Container>              
        </Wrapper>
      }
    </Section>		
	)
}



/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */
const BackButton = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:114px;
  padding:12px 24px;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  background: #EBEBEB;
  transition: all 0.5s ease;
  :hover{
    background: #A9A9A9;
    cursor:pointer;
  }
`;
const IconSVG = styled.img`
  width:5px;
  height:9px;
`;

const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin:24px 0;
  @media screen and (max-width:570px){
    flex-flow:column;
    align-items:flex-start;
  }
`;
const DivTabela = styled.div`
  width:60%;
  margin auto;
  display:inline-block;  
  white-space: nowrap;
  overflow-x:scroll;
  overflow-y: auto;
  //height:calc(300px + (650 - 400) * ((100vw - 760px) / (1920 - 760)));
  height: 600px;
  @media screen and (max-width: 759px){
    width: 100%;
    height:350px;
  }
`;
const ReportTable = styled(Table)`
  tbody tr {
    td{
      padding:16px;
      }
    }
  thead {
    position: sticky;
    top: 0;
    z-index: 1; // any positive value, layer order is global
    th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
    }
  }
`;