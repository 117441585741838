/**
 * @file Defines H6 style
 */

import styled, { css } from 'styled-components';

const H6 = styled.h6`
  font-family: ${({ font }) => font ? font : 'SFSemiBold'};
  font-size: 16px;	
  text-align: ${({ align }) => align ? align : 'left'};
  line-height: ${({ height }) => height ? height : '24'}px;
  color: ${({ color }) => color ? color : '#373737'};
  transition: all 0.5s ease;
  
  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  @media screen and (max-width: 768px) {
    line-height:24px;
  }
`;

export default H6;