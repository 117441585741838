/**
 * @file Defines Text16 style
 */

import styled, { css } from "styled-components";

const Text16 = styled.div`
  font-family: ${({ font }) => font ? font : 'SFMedium'};
  font-size: 16px;
  line-height:${({ height }) => height ? 24 : 16}px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : '#373737'};
  b{
    font-weight:bold;
  }
  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
`;

export default Text16;