/* eslint-disable */

/**
 * @file Renders Users tab in backoffice
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import styled, { css } from "styled-components";
import iconEdit from '../static/icon_editar.svg';
import iconDelete from '../static/icon_lixo.svg';
import iconLeft from '../static/icon_left.svg';
import iconRight from '../static/icon_right.svg';
import iconInfo from '../static/icon_info.png';
import Select from 'react-select';
import Switch from "react-switch";
import { useParams, useHistory } from "react-router-dom";

import { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H3,
  H4,
  H5,
  H6,
  Text11,
  Text12,
  Loading,
} from '../atoms';

import {
  Modal,
  Table
} from '../molecules';

/**
 * Manages rendering of users management tab in backoffice
 * 
 * @component Users
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */
export default function Users(props) {
  console.log('PROPS2:'+JSON.stringify(props))

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */  

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;

  let optionsRows = [
    { value: 5, label: '5 items p/page' },
    { value: 10, label: '10 items p/page' },
    { value: 25, label: '25 items p/page' },
    { value: 50, label: '50 items p/page' },
  ];  

  const idBilheteira = sessionStorage.getItem("bilheteira")
  let currentUsers = []

  console.log('idBilheteira ' + idBilheteira)

  const currentYear = new Date().getFullYear()

  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

  let params = useParams()
  let history = useHistory()  

/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */  

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingService, setLoadingService] = useState(false);
  const [token, setToken] = useState('');
  const [services, setServices] = useState([]);
  const [ticketOffice, setTicketOffice] = useState({});
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [userId, setUserId] = useState(null)
  const [userProfile, setUserProfile] = useState('');
  const [userObject, setUserObject] = useState({});  
  const [openSubTable, setOpenSubTable] = useState(false);       // Opens row with visitor info
  const [reservationIndex, setReservationIndex] = useState('');  // Sets reservation index to display subtable
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [teams, setTeams] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50); 

  let tokenRequest = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */  
  useEffect(() => {
    getCredentials()
  }, [ ])

  useEffect(() => {  
    if (token) {
      getTeams(token)    
    }
  }, [token]) 

  useEffect(() => {  
    console.log(user)

    if (teams) {
      getUsers(token)   
    }
  }, [user, teams])
 

/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  const handleSelectChange = (event) => {
    setSelectedYear(event.target.value);
    // Perform any other actions based on the selected value
  };


/**
 * @callback handleClick - ...
 *
 * CALLED BY:
 * ...
 *
 * TO IMPROVE:
 * ...
 */
  const handleClick = event => {
    setCurrentPage(Number(event.target.id));
  };  


/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */  

/**
 * @function passData
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const passData = (data, type) => {
    setDataModal(
      {
        content: data,
        typeContent: type,
        token: token,        
      }
    )

    setTimeout(toogleModal(), 500);
  };


/**
 * @function toogleModal
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };


/**
 * @function ?
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * @todo Create function
 */
  console.log('USERS:')
  console.log (users)

  let pageNumbers = [];
  let indexOfLast = currentPage * itemPerPage;
  let indexOfFirst = indexOfLast - itemPerPage;
  
  if (users.length > 0) {
    currentUsers = users.slice(indexOfFirst, indexOfLast);
    for (let i = 1; i <= Math.ceil(users.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  }

  /**
 * @function getCredentials
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token'); 
    let tempUser = sessionStorage.getItem('username');
    let tempUserProfile = sessionStorage.getItem('user-profile'); 

    setToken(temp);    
    setUser(tempUser);
    setUserProfile(tempUserProfile);
  };

/**
 * @function getUsers
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getUsers = (key) => {
    console.log(user)

    if(user) {
      setLoading(true)

      axios
        .get(BACKEND_DOMAIN + "/users" + '?_limit=-1', {
          params: {
            bilheteira: idBilheteira,
          }
        },{
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data)

          const userData = response.data.find(u => u.username == user)
          const userServices = userData.servicos

          console.log(userData)
          console.log(userData.bilheteira.id)          

          setUserId(userData.id)
          setUserObject(userData)

          let tempArr

          if (userData.user_profile.permissionsLevel == 'Manager') {
            tempArr = response.data
              .filter(usr => usr.team ? usr.team.id == userData.team.id : false)

            console.log(tempArr)
          } else {
            tempArr = response.data
          }

          console.log(tempArr)

          tempArr.sort(function (a, b) {
            if (a.created_at > b.created_at) {
              return -1
            }
            if (a.created_at < b.created_at) {
              return 1;
            }
            return 0;
          });

          console.log(tempArr)
          
          setServices(userServices)
          setUsers(tempArr)

        setLoading(false)
      })
      .catch(error => {
        console.error(error)

        setLoading(false)
      })
    }
  }

/**
 * @function getTeams
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getTeams = (key) => {
    setLoading(true)

    axios
      .get(BACKEND_DOMAIN + "/teams" + '?_limit=-1', {
        params: {
          bilheteira: idBilheteira,
        }
      },{
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {  
        console.log(response.data)

        setTeams(response.data)
      })
      .catch(error => {
        console.error(error)

        setLoading(false)
      })
  } 


/**
 * @function prevPage
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * ...
 */ 
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

/**
 * @function nextPage
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * ...
 */
  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  }; 

/**
 * @function openMonthReport
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const openMonthReport = (userData, year, month) => {
    history.push({
      pathname: 'users/'+userData.id+'/report/'+year+'/'+month,
      state: { userId, user, userData, year, month },
    });
  }      

  return(
    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper>
          <Container>         
            <DivHeader>
              <H3
                font='SFRegular'
                style={{ fontWeight: 'normal' }}
              >
                Users
              </H3>            
            <DivFilterAdd>            
              <ButtonAdd onClick={() => passData('', 'addUser')}>
                Create new
              </ButtonAdd>
            </DivFilterAdd>
            </DivHeader>            
            <Margin margin={4} />
            <DivTabela >
              <Table >
                <thead >                   
                  <tr >  
                    <th  style={{width:'50px', position: 'sticky'}}></th>                  
                    <th  style={{width:'20px', textAlign:'center'}}>#id</th>
                    <th  style={{width:'160px', textAlign:'center'}}>Name</th>
                    <th  style={{width:'200px', textAlign:'center'}}>Email</th>  
                    <th  style={{width:'150px', textAlign:'center'}}>User Profile</th>
                    <th  style={{width:'150px', textAlign:'center'}}>Team</th>                    
                    <th  style={{width:'150px', textAlign:'center'}}>Services</th>                                                       
                    <th  style={{width:'30px', textAlign:'center'}}>Confirmed</th>
                    <th  style={{width:'30px', textAlign:'center'}}>Active</th>
                    <th  style={{width:'60px'}}></th>
                  </tr>             
                </thead>

                {console.log(currentUsers)}

                {currentUsers.length > 0 && currentUsers.map((body, index) => (
                  <>
                    <tbody>
                      <tr key={String(index)} style={{height:'60px'}}>                           
                        <td style={{width:'40px'}}>
                          <div style={{ width: '40px' }}>
                            <ButtonInfo 
                              className='buttons'
                              onClick={() => {
                                setOpenSubTable(!openSubTable)
                                setReservationIndex(index)
                              }}
                            >
                              <Icon src={iconInfo} />                                                                      
                            </ButtonInfo>
                          </div>  
                        </td>

                        {console.log(body.username)}
                        {console.log(teams)}
                        {console.log(teams.filter(team => team.manager == body.username))} 
                                                                    
                        <td  style={{width:'20px', textAlign:'center'}}>{body.id}</td>
                        <td  style={{maxWidth:'150px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{body.username}</td>
                        <td  style={{maxWidth:'180px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{body.email}</td>  
                        <td  style={{width:'180px', textAlign:'center'}}>{body.user_profile.permissionsLevel}</td>
                        <td  style={{maxWidth:'180px'}}>{teams.filter(team => team.manager == body.username).length != 0 
                          ? teams.filter(team => team.manager == body.username)[0].users.map((usr, index) => (<div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{usr.username}</div>)) 
                          : ''}</td>
                        <td  style={{maxWidth:'180px'}}>{body.servicos.map((service, index) => (<div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{service.nome}</div>))}</td>                                                       
                        <td  style={{width:'30px', textAlign:'center'}}>{body.confirmed && <span> ✅ </span>}</td>
                        <td  style={{width:'30px', textAlign:'center'}}>{body.active && <span> ✅ </span>}</td>
                        <td  style={{width:'60px'}} className='buttons' >
                          <ButtonEdit 
                            onClick={() => passData(body, 'editUser')}
                          >
                          <Icon src={iconEdit} />
                          </ButtonEdit>                                                                             
                          <ButtonDelete onClick={() => passData(body, 'deleteUser')}>
                            <Icon src={iconDelete} />
                          </ButtonDelete>
                        </td>                      
                      </tr>
                      {openSubTable == true && index==reservationIndex &&
                        <tr>
                          <td colSpan={13} style={{padding: '0px'}}>
                            <DivSubTabela   style ={{display:'block', whiteSpace: 'nowrap', tableLayout: 'auto'}}>
                              <thead style={{border: 'none'}}>
                                <tr >                                
                                  <th style={{width:'100px', textAlign:'center'}}>
                                    <select value={selectedYear} onChange={handleSelectChange}>
                                      <option value={currentYear}>{currentYear}</option>
                                      <option value={currentYear-1}>{currentYear-1}</option>
                                    </select>
                                  </th> 

                                  {console.log(months)}

                                  {months.map((month, index) => 
                                    <th style={{textAlign:'center', opacity: '1'}}>
                                      <ButtonMonth
                                        onClick={() => openMonthReport(body, selectedYear, index)} 
                                      >
                                        {month}
                                      </ButtonMonth>
                                    </th>
                                  )}                                                           
                                </tr>
                              </thead>
                              <tbody>
                                {console.log('SUBTABLE ELEMENT')}
                                <tr>  
                                  <td align={'center'} style={{fontWeight: "bold"}}>Reservations</td>                              
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-01-01',selectedYear+'-02-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-02-01',selectedYear+'-03-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-03-01',selectedYear+'-04-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-04-01',selectedYear+'-05-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-05-01',selectedYear+'-06-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-06-01',selectedYear+'-07-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-07-01',selectedYear+'-08-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-08-01',selectedYear+'-09-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-09-01',selectedYear+'-10-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-10-01',selectedYear+'-11-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-11-01',selectedYear+'-12-01', []))).length}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-12-01',selectedYear+1+'-01-01', []))).length}</td>                                                                                                                                             
                                </tr>
                                <tr>
                                  <td align={'center'} style={{fontWeight: "bold"}}>Tickets</td>                              
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-01-01',selectedYear+'-02-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-02-01',selectedYear+'-03-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-03-01',selectedYear+'-04-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-04-01',selectedYear+'-05-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-05-01',selectedYear+'-06-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-06-01',selectedYear+'-07-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-07-01',selectedYear+'-08-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-08-01',selectedYear+'-09-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-09-01',selectedYear+'-10-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-10-01',selectedYear+'-11-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-11-01',selectedYear+'-12-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-12-01',selectedYear+1+'-01-01', []))).reduce((a, b) => a + b.quantidade, 0)}</td>                                                                                                                                              
                                </tr> 
                                <tr>
                                  <td align={'center'} style={{fontWeight: "bold"}}>Total Value</td>                              
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-01-01',selectedYear+'-02-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-02-01',selectedYear+'-03-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-03-01',selectedYear+'-04-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-04-01',selectedYear+'-05-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-05-01',selectedYear+'-06-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-06-01',selectedYear+'-07-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-07-01',selectedYear+'-08-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-08-01',selectedYear+'-09-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-09-01',selectedYear+'-10-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-10-01',selectedYear+'-11-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-11-01',selectedYear+'-12-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td> 
                                  <td align={'center'}>{body.reservas.filter(res => (moment(res.data).isBetween(selectedYear+'-12-01',selectedYear+1+'-01-01', []))).reduce((a, b) => a + b.precoTotal, 0).toFixed(2)}€</td>                                                                                                                                              
                                </tr>                                 
                              </tbody>
                            </DivSubTabela>
                          </td>
                        </tr> 
                      } 
                    </tbody>
                  </>                                               
                ))}                                      
              </Table>                                          
            </DivTabela>
            {currentUsers.length === 0 &&
              <H6 style={{ padding: '40px 0', border: '1px solid #E4EBF0' }} font='SFRegular' align="center" dangerouslySetInnerHTML={{ __html: 'No items to display' }} />
            }
            <DivFooter>
              <DropdownItems
                rows={true}
                placeholder='Rows'
                name='rows'
                classNamePrefix='selectBox'
                options={optionsRows}
                onChange={optionsRows => setItemPerPage(optionsRows.value)}
                value={optionsRows.filter(data => (data.value === itemPerPage)) ? optionsRows.filter(data => (data.value === itemPerPage)) : ''}
              />
              <DivPagination>
                <Arrow onClick={prevPage} left>
                  <img src={iconLeft} />
                </Arrow>
                {pageNumbers.map((number, index) => (
                  <>
                    {(number.value==pageNumbers.length && 
                      currentPage < pageNumbers.length - 2) && 
                      <span style={{marginRight:'10px'}}>...</span>
                    }                   
                    {(number.value==1 || number.value==pageNumbers.length || ((number.value >= currentPage - 1) && (number.value <= currentPage + 1))) &&
                      <NumberText
                        key={String(index)}
                        id={number.value}
                        onClick={handleClick}
                        className={currentPage === number.value ? "selected" : ""}
                      >
                        {number.label}
                      </NumberText> 
                    } 
                    {number.value==1 && 
                      currentPage > 3 &&
                      <span style={{marginRight:'10px'}}>...</span>
                    }                     
                  </>    
                ))}
                <Arrow onClick={nextPage} right>
                  <img src={iconRight} />
                </Arrow>                
              </DivPagination>
            </DivFooter>
          </Container>
          {console.log(dataModal)}
          {isOpen &&
            <Modal
              {...dataModal}
              isOpen={isOpen}
              toogleModal={toogleModal}
              token={token}
              idBilheteira={idBilheteira} 
              userServices={services}             
              users={users}
              setUsers={setUsers} 
              user={user}
              setUser={setUser}
              setUserProfile={setUserProfile}
              userProfile={userProfile} 
              userObject={userObject}               
              setUserObject={setUserObject}
              teams={teams}     
              setTeams={setTeams}                    
              BACKEND_DOMAIN={BACKEND_DOMAIN}
            />
          }
        </Wrapper>
      }
    </Section>
  );
}

/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
`;
const DivFilterAdd = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  @media screen and (max-width:1080px){
    flex-flow:column;
    align-items:flex-start;
  }
`;
const DivGLobalDate = styled.div`
  display:flex;
  flex-direction:row;
  @media screen and (max-width:1080px){
    flex-direction:column;
  }
`;
const DivDate = styled.div`
  display:flex;
  flex-flow:column;
  margin-right:20px;
  :last-child{
    margin-right:00px;
  }
  @media screen and (max-width:1080px){
    width:100%;
    margin-right:0;
    margin-bottom:20px;
  }
`;
const InputDate = styled(DayPickerInput)`
  &.input-date{
    height:48px;
    padding:16px;
    box-sizing: border-box;
    font-family: SFRegular;
  }
  input{
    height:48px !important;
    font-family: SFRegular;

  }
`;

const TextInput = styled.input.attrs(props => ({
  type: "text"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:450px;
  max-width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;

const FixedTable = styled.table`
  table-layout: auto;
  overflow: scroll;
  width:100%;
  border: 1px solid #E4EBF0;
  border-radius: 2px;
  margin:0 auto;
  border-spacing:0;
  white-space: nowrap;
  font-family:SFRegular;
  tbody>tr:nth-child(odd){
    background-color:#fff;
  }
  tbody>tr:nth-child(even){
    background-color:#F9F9F9;
  }
  thead tr{
    background-color:#F9F9F9;
    th{
      position: sticky;
      padding: 16px 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #4E5D6B;
      opacity: 0.5;
      text-align:center;
    }
  }
  thead {
    border-top: 2px solid #DCDCDC;
    border-bottom: 2px solid #DCDCDC; 
  }  
  tbody tr {
    td{
      padding:24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #262F38;
      &.buttons{
        display:flex;
      }
    }
  }
`;

const DivTabela = styled.div`
  width:100%;
  display:inline-block;  
  white-space: nowrap;
  overflow:scroll;
  height:calc(300px + (650 - 400) * ((100vw - 760px) / (1920 - 760)));
  @media screen and (max-width: 759px){
    height:350px;
  }
`;
const DivSubTabela = styled.div`
  width:100%;
  white-space: nowrap;  
  overflow: auto;
  height:calc(300px + * ((100vw - 760px) / (1920 - 760)));
  tbody tr {
    td{
      padding:16px;
      };
    th{
      padding:16px;
      }
    }
  @media screen and (max-width: 759px){
    height:350px;
  }
`;
const Icon = styled.img`
  width:14px;
  height:14px;
  margin-right:8px;
  z-index: 10;
`;
const ButtonAdd = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 16px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  height:48px;
  box-sizing: border-box;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
  @media screen and (max-width:570px){
    padding: 16px 30px;
    margin-top:8px;
  }
`;
const ButtonEdit = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 0px 8px 10px;
  display: flex;
  align-items: center;
  : center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;
const ButtonInfo = styled.div`
  margin-left: 5px;
  background: #63666A;
  border-radius: 2px;
  padding: 8px 2px 8px 10px;
  display: flex;
  align-items: center;
  : center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px;  
  :hover{
    background: #393D47;
    cursor: pointer;
  }
`;
const ButtonDelete = styled.div`
  margin-left: 5px;
  background: #EBEBEB;
  border-radius: 2px;
  padding: 8px 2px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px;  
  :hover{
    background: #A9A9A9;
    cursor: pointer;
  }
`;
const ButtonMonth = styled.div`
  background: #63666A;  
  margin-left: 5px;
  border-radius: 2px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFF;
  transition: all 0.4s ease;
  max-height: 14px;  
  :hover{
    background: #393D47;
    cursor: pointer;
  }
`;
const DivPagination = styled.div`
  margin-top:-10px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50px;
  flex-wrap:wrap;
`;
const NumberText = styled.div`
  height:48px;
  width:48px;
  margin-right: 10px;
  margin-top: 10px;
  background: #EBEBEB;
  border:1px solid #EBEBEB;
  color: #A9A9A9;
  box-sizing: border-box;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.4s ease;
  &.selected {
    color: #373737;
    background: #FFCE20;
  }
  :last-of-type {
    margin-right: 0;
  }
  :hover{
    cursor: pointer;
    color: #373737;
    border:1px solid #A9A9A9;
  }
`;
const Arrow = styled.div`
  height:48px;
  width:48px;
  border:1px solid #EBEBEB;
  background: #EBEBEB;
  box-sizing:border-box;
  border-radius: 2px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 10px;
  color:#A9A9A9;
  transition: all 0.4s ease;
  ${({ left }) => left && css`
    margin-right: 10px;
  `}
  ${({ right }) => right && css`
    margin-right: 10px;
  `}
  :hover{
    cursor:pointer;
    border:1px solid #A9A9A9;
    color:#373737;
  }
`;
const Dropdown = styled(Select)`
  width: 250px;
  font-family: SFMedium;
  margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  margin-right: ${({ rows }) => rows ? '16px' : '0'};
  div{
    border-color: #E4EBF0;
    min-height:36px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  }
`;
const DropdownItems = styled(Select)`
  width: 250px;
  min-width:200px;
  font-family: SFMedium;
  margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  margin-right: ${({ rows }) => rows ? '16px' : '0'};
  div{
    border-color: #E4EBF0;
    min-height:16px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    top:-160px;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        min-height:36px;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  }
`;
const DivFooter = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:14px;
`;