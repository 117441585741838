/**
 * @file Defines Text12 style
 */


import styled, { css } from "styled-components";

const Text12 = styled.div`
  font-family: ${({ font }) => font ? font : 'SFSemiBold'};
  font-size: 12px;
  line-height:${({ height }) => height ? 24 : 16}px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : '#373737'};

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
`;

export default Text12;