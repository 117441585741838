/* eslint-disable */

/**
 * @file Renders backoffice navigation menu bar
 */

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import styled, { css } from "styled-components";

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H2,
  H3,
  H4,
  H5,
  H6,
  Text12,
  Text11,
  Loading
} from '../../atoms';

/**
 * @component NavbarDoc
 *
 * - Manages settings of:
 *   - Support Text  
 *   - Event languages
 *   - Last booking time
 *   - Email
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * @todo Create Separate components for:
 *   @todo Event Languages
 *   @todo Support text
 */
export default function NavbarDoc(props) {
  console.log(props)

  const [navbar, setNavbar] = useState(true);
  const [hash, setHash] = useState('');
  const [path, setPath] = useState('');
  const [url, setUrl] = useState(window.location.href);  

  const navBar = '/documentation/#nav-bar'
  const home = '/documentation/#home'
  const ticketOffice = '/documentation/#ticket-office'
  const homeSchedule = '/documentation/#home-schedule'
  const services = '/documentation/#services'
  const management = '/documentation/#management'
  const seasons = '/documentation/#seasons'
  const days = '/documentation/#days'  
  const events = '/documentation/#events' 
  const blocks = '/documentation/#blocks'
  const coupons = '/documentation/#coupons'
  const slotsLanguages = '/documentation/#slots-languages'
  const ticketTypes = '/documentation/#ticket-types'
  const durationInterval = '/documentation/#duration-interval'
  const emails = '/documentation/#emails'
  const backofficeTemplates = '/documentation/#backoffice-templates'
  const mailchimpTemplates = '/documentation/#mailchimp-templates'  
  const reservations = '/documentation/#reservations'  
  const schedule = '/documentation/#schedule'
  const invoices = '/documentation/#invoices'
  const scanner = '/documentation/#scanner'
  const settings = '/documentation/#settings'
  const generalSettings = '/documentation/#general-settings'
  const supportText = '/documentation/#support-text'
  const eventLanguages = '/documentation/#event-languages'
  const colorSystem = '/documentation/#color-system'
  const checkoutFields = '/documentation/#checkout-fields'      
  const emailSettings = '/documentation/#email-settings'
  const invoicingSettings = '/documentation/#invoicing-settings'      
  const users = '/documentation/#users'

  useEffect(() => {
    console.log(url)

    checkNav();
  }, [url]);


/**
 * @function checkNav
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const checkNav = () => {
    let temp = url.split('/');

    console.log(temp[3])
    console.log(temp[4])

    setPath(temp[3]);
    setHash(temp[4]);
  }


/**
 * @function changePath
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changePath = (hash) => {
    console.log(path)
    console.log(hash)

    setHash(hash)
  }

  return ( 
    <>
      <div>       
        <Link to={navBar}>
          <List1 active={hash == '#nav-bar'} onClick={() => changePath('#nav-bar')}>1. Navigation Bar</List1>             
        </Link>
      </div>      
      <div>       
        <Link to={home}>
          <List1 active={hash == '#home'} onClick={() => changePath('#home')}>2. Home</List1>             
        </Link>
      </div>
      <div>
        <Link to={ticketOffice}>
          <List2 active={hash == '#ticket-office'} onClick={() => changePath('#ticket-office')}>2.1. Ticket Office</List2>
        </Link>
      </div>
      <div>
        <Link to={homeSchedule}>
          <List2 active={hash == '#home-schedule'} onClick={() => changePath('#home-schedule')}>2.2. Schedule</List2>
        </Link>
      </div>
      <div>
        <Link to={services}>             
          <List2 active={hash == '#services'} onClick={() => changePath('#services')}>2.3. Services</List2>
        </Link> 
      </div>
      <div>
        <Link to={management}> 
          <List3 active={hash == '#management'} onClick={() => changePath('#management')}>2.3.1. Management </List3>
        </Link>
      </div> 
      <div>
        <Link to={seasons}>
          <List4 active={hash == '#seasons'} onClick={() => changePath('#seasons')}> Seasons</List4>
        </Link>
      </div>
      <div>
        <Link to={days}>
          <List5 active={hash == '#days'} onClick={() => changePath('#days')}>Days</List5>
        </Link>
      </div> 
      <div>
        <Link to={events}> 
          <List4 active={hash == '#events'} onClick={() => changePath('#events')}>Events</List4>
        </Link>
      </div>                         
      <div>
        <Link to={blocks}> 
          <List4 active={hash == '#blocks'} onClick={() => changePath('#blocks')}>Blocks</List4>
        </Link>
      </div>     
      <div>
        <Link to={coupons}> 
          <List4 active={hash == '#coupons'} onClick={() => changePath('#coupons')}>Coupons</List4>
        </Link>
      </div>
      <div>
        <Link to={slotsLanguages}>
          <List4 active={hash == '#slots-languages'} onClick={() => changePath('#slots-languages')}>Slots Languages</List4>
        </Link>
      </div>
      <div>
        <Link to={ticketTypes}>
          <List4 active={hash == '#ticket-types'} onClick={() => changePath('#ticket-types')}>Ticket Types</List4> 
        </Link>
      </div>          
      <div>
        <Link to={durationInterval}>
          <List4 active={hash == '#duration-interval'} onClick={() => changePath('#duration-interval')}>Duration & Interval</List4> 
        </Link>
      </div>                  
      <div>
        <Link to={emails}>
          <List3 active={hash == '#emails'} onClick={() => changePath('#emails')}>2.3.2. Emails</List3> 
        </Link>
      </div>
      <div>
        <Link to={backofficeTemplates}>
          <List4 active={hash == '#backoffice-templates'} onClick={() => changePath('#backoffice-templates')}>Backoffice Templates</List4> 
        </Link>
      </div>
      <div>
        <Link to={mailchimpTemplates}>
          <List4 active={hash == '#mailchimp-templates'} onClick={() => changePath('#mailchimp-templates')}>Mailchimp Templates</List4> 
        </Link>
      </div>             
      <div>
        <Link to={reservations} >
          <List1 active={hash == '#reservations'} onClick={() => changePath('#reservations')}>3. Reservations</List1>
        </Link> 
      </div>
      <div>
        <Link to={schedule}>
          <List1 active={hash == '#schedule'} onClick={() => changePath('#schedule')}>4. Schedule</List1>
        </Link>
      </div>                  
      <div>
        <Link to={invoices}>
          <List1 active={hash == '#invoices'} onClick={() => changePath('#invoices')}>5. Invoices</List1>
        </Link>
      </div>                                                                                                                                                                                                                                                                                                                     
      <div>
        <Link to={scanner}>
          <List1 active={hash == '#scanner'} onClick={() => changePath('#scanner')}>6. Scanner</List1>
        </Link>
      </div>
      <div>
        <Link to={settings}>
          <List1 active={hash == '#settings'} onClick={() => changePath('#settings')}>7. Settings</List1>
        </Link>
      </div>
      <div>
        <Link to={generalSettings}>
          <List4 active={hash == '#general-settings'} onClick={() => changePath('#general-settings')}>General Settings</List4>
        </Link>
      </div>
      <div>
        <Link to={supportText}>
          <List5 active={hash == '#support-text'} onClick={() => changePath('#support-text')}>Support Text</List5>
        </Link>
      </div>
      <div>
        <Link to={eventLanguages}>
          <List5 active={hash == '#event-languages'} onClick={() => changePath('#event-languages')}>Event Languages</List5>
        </Link>
      </div>        
      <div>
        <Link to={colorSystem}>
          <List5 active={hash == '#color-system'} onClick={() => changePath('#color-system')}>Color System</List5>
        </Link>
      </div>
      <div>
        <Link to={checkoutFields}>
          <List5 active={hash == '#checkout-fields'} onClick={() => changePath('#checkout-fields')}>Checkout Fields</List5>
        </Link>
      </div>                        
      <div>
        <Link to={emailSettings}>
          <List4 active={hash == '#email-settings'} onClick={() => changePath('#email-settings')}>Email Settings</List4>
        </Link>
      </div>
      <div>
        <Link to={invoicingSettings}>
          <List4 active={hash == '#invoicing-settings'} onClick={() => changePath('#invoicing-settings')}>Invoicing Settings</List4>
        </Link>
      </div>                     
      <div>
        <Link to={users}>
          <List1 active={hash == '#users'} onClick={() => changePath('#users')}>8. Users</List1>
        </Link>
      </div>  
    </>                                                                                                                                                                                                                
  ) 
}

/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

/**
 * Classes
 */



/**
 * Styles
 */


const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
`;

const Title = styled(Text12)`
  margin-bottom:24px;
  font-weight: normal;
`;

const List1 = styled(H6)`
  color: ${({ active }) => active ? '#373737' : '#A9A9A9'};
  margin-left: 10px;
  margin-top: 5px;
  font:SFRegular; 
  font-weight: normal;  
  :hover {
    color: #8B8B8B;
  }
`;

const List2 = styled(H6)`
  color: ${({ active }) => active ? '#373737' : '#A9A9A9'};
  margin-left: 20px;
  margin-top: 5px;
  font:SFRegular; 
  font-weight: normal;
  :hover {
    color: #8B8B8B;
  } 
`;

const List3 = styled(H6)`
  color: ${({ active }) => active ? '#373737' : '#A9A9A9'};
  margin-left: 30px;
  font:SFRegular; 
  font-weight: normal;
  :hover {
    color: #8B8B8B;
  }
 `;

const List4 = styled(H6)`
  color: ${({ active }) => active ? '#373737' : '#A9A9A9'};
  margin-left: 40px;
  font:SFRegular; 
  font-weight: normal; 
  :hover {
    color: #8B8B8B;
  }
`;

const List5 = styled(H6)`
  color: ${({ active }) => active ? '#373737' : '#A9A9A9'};
  margin-left: 50px;
  font:SFRegular; 
  font-weight: normal;
  :hover {
    color: #8B8B8B;
  }
`
