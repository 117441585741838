/**
 * @file Defines Wrapper style
 */

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-top: calc(20px + (40 - 20) * ((100vw - 375px) / (1920 - 375)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 375px) / (1920 - 375)));
`;

export default Wrapper;