
/* eslint-disable */

/**
 * @file Calls navigation menu bar component or redirects
 */


import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import {checkCookie} from './auth';

import { Navbar } from './molecules';

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  console.log('REST:'+JSON.stringify(rest))

  let userProfile = sessionStorage.getItem('user-profile');
  let user = sessionStorage.getItem('username')

  return (
    <Route
      {...rest}

      render={props => {
        console.log('PROPS1.2:'+JSON.stringify(props))
        console.log('LOCATION STATE: '+props.history.location.state)   
        console.log('PR USER PROFILE: ' + userProfile)
        console.log('PR USER: ' + user)

        if (checkCookie('token') && props.history.location.state != undefined) {
          return (
            <>
              <Navbar 
                userProfile={props.history.location.state.userProfile} 
                user={props.history.location.state.user} { ...props} 
              />
              <Component {...props} />
            </>
          )
        } else if (checkCookie('token')) {
          return (
            <>
              <Navbar  
                user={user} 
                userProfile={userProfile} 
                { ...props} 
              />
              <Component {...props} />
            </>
          )
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
