/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import styled, { css } from "styled-components";
import iconLeft from '../static/icon_left.svg';
import iconRight from '../static/icon_right.svg';
import iconInfo from '../static/icon_info.png';
import Select from 'react-select';

import { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H3,
  H6,
  Text12,
  Loading,
} from '../atoms';

import {
  ModalVisitor,
  Table,
} from '../molecules';

export default function Schedule(props) {
  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;

  let temp = sessionStorage.getItem('token')

  const idBilheteira = sessionStorage.getItem("bilheteira");  

  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [token, setToken] = useState('');
  const [dataSchedule, setDataSchedule] = useState([]);
  const [isShown, setIsShown] = useState(-1);            // Shows popup on mouse over button info
  const initialColumns = [
    {
      title: 'Dia'
    },
    {
      title: 'Data'
    },
    {
      title: 'Hora'
    },  
    {
      title: 'Total'
    }];
  const [columns, setColumns] = useState([
    {
      title: ''
    },     
    {
      title: 'Dia'
    },
    {
      title: 'Data'
    },
    {
      title: 'Hora'
    },
    {
      title: 'Total'
    }, 
  ]);
  const [maxRows, setMaxRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [day, setDay] = useState('');
  const [dayEnd, setDayEnd] = useState('');
  const [dataService, setDataService] = useState([]);
  const [service, setService] = useState('');
  const [isOpen, setIsOpen] = useState(false);  
  const [dataModalVisitor, setDataModalVisitor] = useState({});  // Sets data of visitor to show on modal
  const [openSubTable, setOpenSubTable] = useState(false);       // Opens row with visitor info
  const [openTypesTable, setOpenTypesTable] = useState(false);   // Opens row with visitor info  
  const [scheduleIndex, setScheduleIndex] = useState('');        // Sets schedule index to display subtable
  const [reservationIndex, setReservationIndex] = useState('');  // Sets schedulereservation index to display subtable  
  const [filtredData, setFiltredData] = useState([]);
  const [userData, setUserData] = useState({});                  // User database data
  const [user, setUser] = useState('');
  const [userProfile, setUserProfile] = useState('')
  const [tiposBilhete, setTiposBilhete] = useState('')


  let optionsRows = [
    { value: 5, label: '5 items p/page' },
    { value: 10, label: '10 items p/page' },
    { value: 25, label: '25 items p/page' },
    { value: 50, label: '50 items p/page' },
  ];

  let tokenRequest = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };


/**
 * @callback showInfo - ...
 *
 * CALLED BY:
 * ...
 *
 * TO IMPROVE:
 * ...
 */
  const showInfo = (i) => {
    setIsShown(i);
  }

  
/**
 * @callback hideInfo - ...
 *
 * CALLED BY:
 * ...
 *
 * TO IMPROVE:
 * ...
 */
  const hideInfo = () => {
    setIsShown(-1);
  } 



  const getDate = () => {
    if (props.location && props.location.data) {
      setDay(props.location.data);
    }
  };

  const getSchedule = () => {
    setLoadingTable(true);

    let data = {
      servico: service !== '' ? service : undefined,
      dataInicio: day !== '' ? day : undefined,
      dataFim: dayEnd !== '' ? dayEnd : undefined,
    };
    
    if (token !== '') {
      axios
        .post(BACKEND_DOMAIN + "/bilheteiras/" + idBilheteira + "/horario", data, tokenRequest)
        .then(response => {
          console.log(response.data.result)

          setDataSchedule(response.data.result)
          setFiltredData(response.data.result)
          setMaxRows(response.data.maxRows)
          setLoadingTable(false)
          setLoading(false)
        })
        .catch(error => {
          setLoadingTable(false)
          setLoading(false)
        });
    }
  };

  const checkColumns = () => {
    let columnsToAdd1 = { title: 'Visitante' };
    let columnsToAdd2 = { title: 'Pax' };
    let tempColumns = initialColumns;
    if (maxRows === 0) {
      setColumns(initialColumns)
    } else {
      for (let i = 0; i < maxRows; i++) {
        tempColumns.push(columnsToAdd1, columnsToAdd2);
        setColumns(tempColumns);
      }
    }
  };

  const handleClick = event => {
    setCurrentPage(Number(event.target.id));
  };

  let currentSchedules = [];
  let pageNumbers = [];
  let indexOfLast = currentPage * itemPerPage;
  let indexOfFirst = indexOfLast - itemPerPage;
  if (dataSchedule.length > 0) {
    currentSchedules = filtredData.slice(indexOfFirst, indexOfLast);
    for (let i = 1; i <= Math.ceil(filtredData.length / itemPerPage); i++) {
      pageNumbers.push({
        value: i,
        label: i
      });
    }
  }

  const getService = (key) => {
    setLoading(true);
    axios
      .get(BACKEND_DOMAIN + "/servicos" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)
        
        let temp = [];

        for (let i = 0; i < response.data.length; i++) {
          temp.push({
            value: response.data[i].id,
            label: response.data[i].nome
          })
        }

        console.log(temp)
        console.log(userData)

        let filteredTemp = temp.filter(service => {
          let include = false

          if (userData.servicos.find(s => s.id == service.value) != undefined)
            include = service.value == userData.servicos.find(s => s.id == service.value).id

          return include
        })

        console.log(filteredTemp)

        setService(response.data[0].id);
        setDataService(filteredTemp);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };


/**
 * @function getTiposBilhete
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getTiposBilhete = (key) => {
    console.log('GET TIPOSBILHETES')

    axios
      .get(BACKEND_DOMAIN + "/tipo-bilhetes" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)

        setTiposBilhete(response.data) 
      })
      .catch(error => {
        console.log(error)

        setLoading(false)
      })           
  } 

/**
 * @function getUserData
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * -
 */ 
  const getUserData = (key) => {
    setLoading(true);

    axios
        .get(BACKEND_DOMAIN + "/users" + '?_limit=-1', {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response.data.find(u => u.username==user))
          const userObject = response.data.find(u => u.username==user)

          setUserData(userObject)
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });  
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getCredentials = () => {
    let tempUser = sessionStorage.getItem('username');
    let tempUserProfile = sessionStorage.getItem('user-profile');

    setUser(tempUser);
    setUserProfile(tempUserProfile);
    setToken(temp);
    getUserData(temp);   
  };

  useEffect (() => {
      getService(temp);
    }, [userData])

  useEffect(() => {
    getUserData(temp);
  }, [user]); 

  useEffect(() => {
    getCredentials();
    getDate();
    getTiposBilhete(temp)
  }, []);

  useEffect(() => {
    getSchedule();
  }, [day, dayEnd, service]);

  /*useEffect(() => {
    checkColumns();
  }, [filtredData, maxRows]);*/

  let fakeRows = []

  for (let i = 0; i < maxRows; i++) {
    fakeRows.push(
      <td></td>
    );
    fakeRows.push(
      <td></td>
    );
  }
  const fakeRowWith = (number) => {
    let tempNumber = maxRows - number;
    let tempRows = [];
    for (let i = 0; i < tempNumber; i++) {
      tempRows.push(
        <td></td>
      );
      tempRows.push(
        <td></td>
      );
    }
    return tempRows;
  }

  const passData = (reservation) => {
    console.log(reservation)

    setDataModalVisitor(
      {
        content: reservation,
      }
    );

    setTimeout(toogleModal(), 500);
  };  

  const toogleModal = () => {
    setIsOpen(!isOpen);
  };  

  return (
    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper>
          <Container>         
            <DivHeader>
              <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
                Schedule
              </H3>
            </DivHeader>
            <DivFilterAdd>
              <DivGLobalDate>
                <DivDate>
                  <Text12
                    dangerouslySetInnerHTML={{ __html: 'Service' }}
                  />
                  <Margin margin={1} />
                  <Dropdown
                    placeholder='Service'
                    name='slot'
                    classNamePrefix='selectBox'
                    rows
                    options={dataService}
                    onChange={dataService => setService(dataService.value)}
                    value={dataService.filter(data => (data.value === service)) ? dataService.filter(data => (data.value === service)) : ''}
                  />
                </DivDate>
                <DivDate>
                  <Text12
                    dangerouslySetInnerHTML={{ __html: 'Date' }}
                  />
                  <Margin margin={1} />
                  <InputDate
                    inputProps={{ className: 'input-date' }}
                    onDayChange={(day) => setDay(moment(day).format('YYYY-MM-DD'))}
                    format={'DD-MM-YYYY'}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder='Select date to filter'
                    value={day}
                  />
                </DivDate>
                <DivDate>
                  <Text12
                    dangerouslySetInnerHTML={{ __html: 'Date End' }}
                  />
                  <Margin margin={1} />
                  <InputDate
                    inputProps={{ className: 'input-date' }}
                    onDayChange={(dayEnd) => setDayEnd(moment(dayEnd).format('YYYY-MM-DD'))}
                    format={'DD-MM-YYYY'}
                    modifiers={{
                      from: new Date(day)
                    }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder='Select date to filter'
                    value={dayEnd}
                  />
                </DivDate>
              </DivGLobalDate>
            </DivFilterAdd>
            <div style={{display: 'flex',flexDirection:'row',alignItems:'center',margin:10}}>
                  <span style={{backgroundColor:'rgba(245, 39, 39, 0.22)',height:15,width:15,borderRadius:15,marginRight:5}}></span>
                  <Text12
                    dangerouslySetInnerHTML={{ __html: 'Bloqueado' }}
                  />
                </div>
            <Margin margin={2} />
            {!loadingTable &&
              <DivTabela>         
                <Table>
                  <thead>
                    <tr style ={{height: '60px'}}>
                      {columns.map((header, index) => (
                        <th key={String(index)}>{header.title}</th>
                      ))}
                    </tr>
                  </thead>

                  {console.log(currentSchedules)}

                  {currentSchedules.length > 0 &&
                    <>
                      {currentSchedules.map((body, index) => (
                        <>
                          <tbody>
                            <tr style={{backgroundColor:body.blocked ?'rgba(245, 39, 39, 0.22)' : 'transparent'}} key={String(index)}>
                              <td style={{maxWidth:'40px'}}>
                                <ButtonInfo onClick={() => {
                                  setOpenSubTable(!openSubTable)
                                  setScheduleIndex(index)
                                }}>
                                  <Icon src={iconInfo} />                                           
                                </ButtonInfo>                                            
                              </td>                              
                              <td>{body.dia}</td>
                              <td>{body.data}</td>
                              <td>{body.hora}</td>
                              <td>{body.total}</td>
                            </tr>
                          </tbody>

                          {console.log(openSubTable)}
                          {console.log(String(index))}
                          {console.log(scheduleIndex)}

                          {openSubTable==true && index==scheduleIndex &&
                            <>
                              <thead style={{border: 'none'}}>
                                <tr>
                                  <th></th>
                                  {body.reservas.length != 0 && body.reservas[0].multiTicket == true &&
                                    <th></th>
                                  }
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Contact</th>                                              
                                  <th>Country</th> 
                                  <th>Quantity</th>                                                                                
                                  <th>Obs</th>                                              
                                  <th>Code</th>                                              
                                  <th>NIF</th>                                                
                                </tr>
                              </thead>

                              {console.log(body.reservas.length)}
                              {console.log(maxRows)}

                                
                              {(body.reservas.length > 0 && body.reservas.length == maxRows) &&  
                                <>                          
                                  {body.reservas.map((reservation, index) => ( 
                                    <>
                                      <tbody>                 
                                        <tr>
                                          {console.log('BEFORE')}                                        
                                          {console.log(reservation)}
                                          {console.log(openTypesTable)}
                                          {console.log(reservation.multiTicket)}
                                          {console.log(index)}
                                          {console.log(scheduleIndex)}                                          
                                          <td></td>
                                          {reservation.multiTicket == true &&
                                            <td style={{maxWidth:'40px'}}>
                                              <ButtonInfo onClick={() => {
                                                setOpenTypesTable(!openTypesTable)
                                                setReservationIndex(index)                                                
                                              }}>
                                                <Icon src={iconInfo} />                                           
                                              </ButtonInfo>                                            
                                            </td> 
                                          }                                     
                                          <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{reservation.nome}</td>
                                          <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{reservation.email}</td>
                                          <td>{reservation.contacto}</td>
                                          <td>{reservation.pais}</td>
                                          <td>{reservation.quantidade}</td>                                             
                                          <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{reservation.obs}</td>
                                          <td>{reservation.codigo}</td>
                                          <td>{reservation.nif}</td>
                                        </tr>
                                      </tbody>

                                      {console.log('AFTER')}                                        
                                      {console.log(openTypesTable)}
                                      {console.log(reservation.multiTicket)}
                                      {console.log(index)}
                                      {console.log(scheduleIndex)}
                                                                                                                  
                                      {openTypesTable==true && reservation.multiTicket == true && index==reservationIndex &&
                                        <>
                                          <thead style={{border: 'none'}}>
                                          <tr >
                                            <th></th>
                                            <th></th>                                            
                                            <th>Ticket Type</th>
                                            <th>Quantity</th>                                                                                                                                                           
                                          </tr>                        
                                          </thead>
                                          <tbody>
                                            {reservation.tiposreservas.map(type => (                                
                                              <tr >
                                                {console.log(tiposBilhete.find(item => item.id == type.tipo_bilhete))}
                                                <td></td>
                                                <td></td>                                                
                                                <td>{tiposBilhete.find(item => item.id == type.tipo_bilhete).tipoBilheteEN}</td>
                                                <td>{type.quantidadeTipo}</td>                                                                                                                                                            
                                              </tr>
                                            ))}                                   
                                          </tbody>   
                                        </>                                                                
                                      }
                                    </>
                                  ))} 
                                </>
                              }

                              {(body.reservas.length > 0 && body.reservas.length < maxRows) &&
                                <>
                                  {body.reservas.map((reservation, index) => (  
                                    <> 
                                      <tbody>                 
                                        <tr>
                                          {console.log(reservation)}
                                          <td></td>
                                          {reservation.multiTicket == true &&
                                            <td style={{maxWidth:'40px'}}>
                                              <ButtonInfo onClick={() => {
                                                setOpenTypesTable(!openTypesTable)
                                                setReservationIndex(index)                                                                                                
                                              }}>
                                                <Icon src={iconInfo} />                                           
                                              </ButtonInfo>                                            
                                            </td>
                                          }    
                                          <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{reservation.nome}</td>
                                          <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{reservation.email}</td>
                                          <td>{reservation.contacto}</td>
                                          <td>{reservation.pais}</td>
                                          <td>{reservation.quantidade}</td>                                          
                                          <td style={{textAlign: 'left', maxWidth: '220px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{reservation.obs}</td>
                                          <td>{reservation.codigo}</td>
                                          <td>{reservation.nif}</td>        
                                        </tr>
                                      </tbody>

                                      {console.log('AFTER')}                                        
                                      {console.log(openTypesTable)}
                                      {console.log(reservation.multiTicket)}
                                      {console.log(index)}
                                      {console.log(scheduleIndex)}
                                      {console.log(reservation)}                                      

                                      {openTypesTable==true && reservation.multiTicket == true && index==reservationIndex &&
                                        <>
                                          <thead style={{border: 'none'}}>
                                            <tr >
                                              <th></th>
                                              <th></th>                                              
                                              <th>Ticket Type</th>
                                              <th>Quantity</th>                                                                                                                                                           
                                            </tr>                        
                                          </thead>
                                          <tbody>
                                            {reservation.tiposreservas.map(type => (                                
                                              <tr >
                                                {console.log(type)}
                                                {console.log(tiposBilhete)}
                                                {console.log(tiposBilhete.find(item => item.id == type.tipo_bilhete))}
                                                <td></td>
                                                <td></td>                                                
                                                <td>{tiposBilhete.find(item => item.id == type.tipo_bilhete).tipoBilheteEN}</td>
                                                <td>{type.quantidadeTipo}</td>                                                                                                                                                            
                                              </tr>
                                            ))}                                   
                                          </tbody>   
                                        </>                                                                
                                      }
                                    </>                                      
                                  ))}

                                  {fakeRowWith(body.reservas.length)}  

                                </>                                                             
                              }

                              {console.log(fakeRows)}

                              {(body.reservas.length === 0 && maxRows > 0) &&
                                <div key={String(index)}>
                                  {fakeRows}
                                </div>
                              }    
                            </>                                                                
                          }
                        </>                                                                      
                      ))}
                    </>
                  }
                </Table>
              </DivTabela>
            }
            {currentSchedules.length === 0 &&
              <H6 style={{ padding: '40px 0', border: '1px solid #E4EBF0' }} font='SFRegular' align="center" dangerouslySetInnerHTML={{ __html: 'No items to display' }} />
            }
            <DivFooter>
              <DropdownItems
                rows={true}
                placeholder='Rows'
                name='rows'
                classNamePrefix='selectBox'
                options={optionsRows}
                onChange={optionsRows => setItemPerPage(optionsRows.value)}
                value={optionsRows.filter(data => (data.value === itemPerPage)) ? optionsRows.filter(data => (data.value === itemPerPage)) : ''}
              />
              <DivPagination>
                <Arrow onClick={prevPage} left>
                  <img src={iconLeft} />
                </Arrow>

                {console.log(pageNumbers)}

                {pageNumbers.map((number, index) => (
                  <>
                    {(number.value==pageNumbers.length && 
                      currentPage < pageNumbers.length - 2) && 
                      <span style={{marginRight:'10px'}}>...</span>
                    }                   
                    {(number.value==1 || number.value==pageNumbers.length || ((number.value >= currentPage - 1) && (number.value <= currentPage + 1))) &&
                      <NumberText
                        key={String(index)}
                        id={number.value}
                        onClick={handleClick}
                        className={currentPage === number.value ? "selected" : ""}
                      >
                        {number.label}
                      </NumberText> 
                    } 
                    {number.value==1 && 
                      currentPage > 3 &&
                      <span style={{marginRight:'10px'}}>...</span>
                    }                     
                  </>    
                ))}
                <Arrow onClick={nextPage} right>
                  <img src={iconRight} />
                </Arrow>                
              </DivPagination>
            </DivFooter>
          </Container>
          <ModalVisitor
            isOpen={isOpen}
            {...dataModalVisitor}
            toogleModal={toogleModal}
            user={user}
            setUser={setUser}
            setUserProfile={setUserProfile}
            userProfile={userProfile}             
          />          
        </Wrapper>
      }
    </Section>
  );
}


const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
`;
const DivFilterAdd = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
`;
const DivGLobalDate = styled.div`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  margin-bottom: -20px;
`;
const DivDate = styled.div`
  display:flex;
  flex-flow:column;
  margin-right:20px;
  margin-bottom:20px;
  :last-child{
    margin-right:00px;
  }
`;
const InputDate = styled(DayPickerInput)`
  &.input-date{
    height:48px;
    padding:16px;
    box-sizing: border-box;
    font-family: SFRegular;
  }
  input{
    height:48px !important;
    font-family: SFRegular;

  }
`;
const DivTabela = styled.div`
  width:100%;
  overflow:scroll;
  height:calc(300px + (650 - 400) * ((100vw - 760px) / (1920 - 760)));
  @media screen and (max-width: 759px){
    height:350px;
  }
`;

const Icon = styled.img`
  width:14px;
  height:14px;
  margin-right:8px;
`;
const ButtonAdd = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 16px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  height:48px;
  box-sizing: border-box;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
  @media screen and (max-width:570px){
    padding: 16px 30px;
    margin-top:8px;
  }
`;
const ButtonEdit = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;
const ButtonDelete = styled.div`
  margin-left: 20px;
  background: #EBEBEB;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #A9A9A9;
    cursor: pointer;
  }
`;
const NameLink = styled.div`
  text-decoration: underline;
  color: blue;
  :hover{
    color: black;
    cursor: pointer;
  }
`;
const ButtonInfo = styled.div`
  margin-left: 5px;
  background: #63666A;
  border-radius: 2px;
  padding: 8px 2px 8px 10px;
  display: flex;
  align-items: center;
  : center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  max-height: 14px; 
  max-width: 20px; 
  :hover{
    background: #393D47;
    cursor: pointer;
  }
`;
const DivPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50px;
  flex-wrap:wrap;
  margin-top:-10px;

`;
const NumberText = styled.div`
  height:48px;
  width:48px;
  margin-right: 10px;
  margin-top: 10px;
  background: #EBEBEB;
  border:1px solid #EBEBEB;
  color: #A9A9A9;
  box-sizing: border-box;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.4s ease;
  &.selected {
    color: #373737;
    background: #FFCE20;
  }
  :last-of-type {
    margin-right: 0;
  }
  :hover{
    cursor: pointer;
    color: #373737;
    border:1px solid #A9A9A9;
  }
`;
const Arrow = styled.div`
  height:48px;
  width:48px;
  border:1px solid #EBEBEB;
  background: #EBEBEB;
  box-sizing:border-box;
  border-radius: 2px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 10px;
  color:#A9A9A9;
  transition: all 0.4s ease;
  ${({ left }) => left && css`
    margin-right: 10px;
  `}
  ${({ right }) => right && css`
    margin-right: 10px;
  `}
  :hover{
    cursor:pointer;
    border:1px solid #A9A9A9;
    color:#373737;
  }
`;
const Dropdown = styled(Select)`
  width: 250px;
  font-family: SFMedium;
  margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  margin-right: ${({ rows }) => rows ? '16px' : '0'};
  div{
    border-color: #E4EBF0;
    min-height:36px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  }
`;
const DropdownItems = styled(Select)`
  width: 250px;
  min-width:200px;
  font-family: SFMedium;
  margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  margin-right: ${({ rows }) => rows ? '16px' : '0'};
  div{
    border-color: #E4EBF0;
    min-height:16px;
  }
  .selectBox__control {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4E5D6B;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    height:48px;
    .selectBox__value-container {
      border-radius: 0;
      align-items: center;
      padding:16px 24px;
      box-sizing: border-box;
      height: 48px;
      .selectBox__input {
        display: flex !important;
        align-items: center !important;
      }
      .selectBox__single-value {
        display: flex;
        align-items: center;
      }
      .selectBox__placeholder {
        display: flex;
        align-items: center;
      }
    }
    .selectBox__indicators {
      height:48px;
      width:48px;
      background: #F5F7F7;
      justify-content:center;
      .selectBox__indicator-separator {
        display: none;
      }
      .selectBox__dropdown-indicator {
        display:flex;
        align-items: center;
        svg {
          transition: all 400ms ease;
          transform: rotate(0);
        }
      }
      &:hover {
        background-color: #E4EBF0;    
      }
    }
    :hover{
      border-color:#EBEBEB;
    }
  }
  .selectBox__menu {
    border-radius: 0;
    margin-top: 4px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    z-index: 2;
    top:-160px;
    .selectBox__menu-list {
      background: #fff !important;
      margin:0;
      .selectBox__option {
        font-size: 14px;
        display: flex;
        align-items: center;
        min-height:36px;
        &.selectBox__option--is-selected {
          background-color: #d8d8d8;
          color: #000;
        }
        &.selectBox__option--is-focused {
          background-color: #eaeaea;
          color: #000;
        }
        &:hover {
          cursor: pointer;
          background: #E4EBF0;
        }
      }
    }
  }
  .selectBox__control--is-focused {
    border-width: 1px;
    border-style: solid;
    border-color: #F5F7F7;
    box-shadow: none;
    &:hover {
      border-color: #F5F7F7;
    }
  }
  .selectBox__control--menu-is-open {
    .selectBox__indicators {
      .selectBox__dropdown-indicator {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  @media screen and (max-width:570px){
    margin-bottom: ${({ rows }) => rows ? '0' : '16px'};
  }
`;
const DivFooter = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:14px;
`;
