/* eslint-disable */

/**
 * @file Shows information from a specific visitor
 * 
 * Deprecated 22-07-2022
 **/

import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Select from 'react-select';
import { createGlobalStyle } from 'styled-components';
import iconClose from '../../static/icon_close.svg';

import {
  Text14,
} from '../../atoms';

/**
 * Manages rendering visitor info modal
 * 
 * @component Schedule
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - Deprecated 22-07-2022
 * 
 */

export default function ModalVisitor(props) {

  const {
    isOpen,
    content,
    toogleModal,
  } = props;

  const closeModal = () => {
    toogleModal();
  }  

  return (
    (content != undefined) &&
      <DivModal isOpen={isOpen}>
        <Content>
          <ButtonClose onClick={() => closeModal()}>
            <img src={iconClose}/>
          </ButtonClose>        
          <Container>
            {console.log(isOpen)}
            {console.log(content)}

            <span>Name: {content.nome}</span>
            <span>Email: {content.email}</span>
            <span>Contact: {content.contacto}</span>
            <span>Country: {content.pais}</span>
            {content.obs === '-' ? '' : <span>Obs: {content.obs}</span>}
            {(content.codigo && content.codigo !== '') ? <span>Code: {content.codigo}</span> : null }
            {(content.nif && content.nif!=='-') ? <span>NIF: {content.nif}</span> : '' }
            <span>Quantity: {content.quantidade}</span>
          </Container>
        </Content>
        {isOpen && <Global />}
      </DivModal>
  )
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const Global = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`
const DivModal = styled.div`
  background: rgba(78, 93, 107, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  opacity:1;
  z-index:999;
  ${({ isOpen }) => !isOpen && css`
    opacity:0;
    z-index: -1;
  `}
`;
const Content = styled.div`
  position:relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 32px 40px;
  display: flex;
  flex-flow: column;
  width: 372px;
  max-height: 95%;
  overflow-y: auto;
  @media screen and (max-width:570px){
    width:350px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
`;
const ButtonClose = styled.div`
  position:absolute;
  top:10px;
  right:10px;
  :hover{
    cursor:pointer;
  }
`;
