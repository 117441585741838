/**
 * @file Defines component that allows writing emails body for strapi emails plugin 
 */

/* eslint-disable */
import React, { useState, useEffect } from "react"
import Select from 'react-select'
import axios from "axios"
import styled, { css } from "styled-components"
import Switch from "react-switch"
import { useParams, useHistory, useLocation } from "react-router-dom"
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

import arrowLeft from '../static/icon_arrow_left.svg'
import iconEdit from '../static/icon_editar.svg'
import iconDelete from '../static/icon_lixo.svg'
import iconLeft from '../static/icon_left.svg'
import iconRight from '../static/icon_right.svg'

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H2,
  H3,
  H5,
  H6,
  Text12,
  Text11,
  Loading
} from '../atoms'

import {
  ModalNotification,
  ModalDelete,
  ModalEmail,
} from '../molecules'

/**
 * Manages rendering of writing emails page
 * 
 * @component EmailsWriting
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 * 
 */ 
export default function EditTemplate (props) {

/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  let params = useParams();
  let history = useHistory();  
  let location = useLocation();

  console.log(location)

  const bilheteiraId = sessionStorage.getItem('bilheteira')  

  console.log('SERVICE ID')
  console.log(bilheteiraId)  

  let serviceId = params.id
  //let serviceId = props.match.params.id;

  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;
  const FRONTEND_DOMAIN = props.FRONTEND_DOMAIN;
  const endpoint = props.endpoint

  let user = props.user;
  let userProfile = props.userProfile;    

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const settingsEmail = [
    ['undo', 'redo', 'fontSize', 'formatBlock', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview']
  ]  


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */	
  const [token, setToken] = useState('')
  const [interfaceApiKey, setInterfaceApiKey] = useState('')
  const [configData, setConfigData] = useState('')

	const [url, setUrl] = useState([])
  const [servicePath, setServicePath] = useState('')              // Selected service url path component

  const [title, setTitle] = useState('')
  const [databaseNameKey, setDatabaseNameKey] = useState('')
  const [databaseSlugKey, setDatabaseSlugKey] = useState('')
  const [name, setName] = useState('')
  const [slug, setSlug] = useState('')
  const [subject, setSubject] = useState(null)
  const [body, setBody] = useState('')
  const [labels, setLabels] = useState([])
  const [bodyRender, setBodyRender] = useState('')
  const [templateContent, setTemplateContent] = useState([])
  const [editFields, setEditFields] = useState(false)
  const [templates, setTemplates] = useState([])
  const [templatesData, setTemplatesData] = useState([])
  const [newTemplateName, setNewTemplateName] = useState('')
  const [addNewTemplate, setAddNewTemplate] = useState (false)  
  const [notification, setNotification] = useState(false)
  const [statusEmail, setStatusEmail] = useState(false)
  const [mailchimpTemplates, setMailchimpTemplates] = useState([])  
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [instructions, setInstructions] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)  


/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  useEffect(() => {  
    getCredentials()
  }, [])

  useEffect(() => {
    getTemplates(token)  
  }, [configData, slug])

  useEffect(() => {
    console.log(location)
    console.log(slug)

    const { templateTitle, templateSlug, dbNameKey, dbSlugKey } = location.state

    //const storedSlug = localStorage.setItem('slug', templateSlug)
    //const initialSlug = storedSlug || (location.state && templateSlug) || ''    
    const initialSlug = (location.state && templateSlug) || ''

    //console.log(storedSlug)
    console.log(initialSlug)

    //setSlug(storedSlug)
    setSlug(initialSlug)
    setTitle(location.state && templateTitle || '')
    setDatabaseSlugKey(location.state && dbSlugKey || '')
    setDatabaseNameKey(location.state && dbNameKey || '')

    localStorage.setItem('slug', initialSlug)    
  }, [location.state])


  useEffect (() => {
    console.log('TRACK')
    console.log(slug)    

    if (slug) {
      console.log('RENDERS TEMPLATE')

      renderTemplate(slug)
    }
  }, [slug, interfaceApiKey])


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

/**
 * @callback handleTemplateChange - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const handleTemplateChange = (event) => {
    console.log(event.value)
    console.log(templatesData)
    console.log(templatesData.filter(tpl => tpl.slug == event.value)[0].subject)    
    console.log(templatesData.filter(tpl => tpl.slug == event.value)[0].code)
     
    setSlug(event.value)

    setShowSaveButton(true)

    if (event.value) {
      //setSubject(templatesData.filter(tpl => tpl.name == event.value)[0].subject)    
      //setBody(templatesData.filter(tpl => tpl.name == event.value)[0].code)
    }
  }


/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates in the form of @state in function header comments?
 */


/**
 * @function getCredentials
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getCredentials = () => {
    console.log('ENTERED GET CREDENTIALS:')

    let temp = sessionStorage.getItem('token')

    setToken(temp)
    setLoading(true)

    getData(temp)
  }


/**
 * @function getData
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const getData = async (key) => {
    await Promise.all([
      getConfigData(key),
      getUrl(key),
      getServicePath(key),
    ])  
  }


/**
 * @function toogleModal
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };


/**
 * @function getUrl
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getUrl = async (key) => {
    await axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {

        console.log(response.data)

        setUrl(response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  } 

/**
 * @function getServicePath
 *
 * CALLED BY:
 * - 
 * 
 * TO IMPROVE:
 * -
 * 
 */ 
  const getServicePath = async (key) => {
    await axios
      .get(BACKEND_DOMAIN + "/servicos/" + serviceId, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        setServicePath (response.data.url)
      })
      .catch(error => {
        console.log(error)
      });
  } 	 

/**
 * @function getConfig - Gets bilheteira config data and sets Mailchimp interface API key.
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state 
 *   (setLanguageOption + setSelectedLanguages)
 */
const getConfigData = async (key) => {
    console.log('ENTERED GETCONFIGDATA:')
    console.log(bilheteiraId)
    console.log(BACKEND_DOMAIN)

    axios
      .get(BACKEND_DOMAIN + '/configuracoes?bilheteira=' + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data[0])

        setConfigData(response.data[0])
        setInterfaceApiKey(response.data[0].mailchimp_interface_api_key)
        setLabels([response.data[0].bilheteira.nome])
      })
      .catch(error => {
        console.error(error)
      });
  }
 

/**
 * @function getTemplates - Gets Mailchimp templates.
 * 
 * TO IMPROVE:
 */
  const getTemplates = async (key) => {    
    console.log(configData)

    if (configData) {
      await axios
        .post(BACKEND_DOMAIN + "/emails/gettemplates", {
          endpoint: endpoint,
          configData: configData
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          }
        })
        .then(response => {
          console.log(response.data)

          let templatesNames = response.data.map(tpl => {
            return ({
              value: tpl.slug,
              label: tpl.name,
            })
          })

          console.log(slug)
          console.log(templatesNames)

          setTemplates(templatesNames) 
          setTemplatesData(response.data)

          if (response.data.filter(data => data.slug == slug)[0]) { 
            setSubject(response.data.filter(data => data.slug == slug)[0].subject)
            setBody(response.data.filter(data => data.slug == slug)[0].code)
            setName(response.data.filter(data => data.slug == slug)[0].name)            
          }

          setLoading(false)        
        })
        .catch(error => {
          console.log('ERROR GET TEMPLATES')
          console.error(error)

          setLoading(false)        
        })

        renderTemplate(slug)      
      }
  } 

/**
 * @function saveTemplate
 * 
 * CALLED BY:
 * - Save button is clicked.
 */
  const saveTemplate = async () => {
    if(addNewTemplate == true) {
      addTemplate(token)
    } else {
      updateTemplate(token)
    }                       
  }

/**
 * @function addTemplate
 * 
 * CALLED BY:
 * - Save button is clicked.
 */
  const addTemplate = async (key) => {
    console.log("ADD TEMPLATE")

    if (interfaceApiKey) {
      const requestData = {
        name: newTemplateName,
        subject: subject,
        code: body,
        from_email: configData.nodemailer_default_from,
        from_name: configData.bilheteira.nome,
        labels: labels,
        publish: true,
      }

      const databaseData = {}

      await axios.post(BACKEND_DOMAIN + "/emails/addtemplate", {
          endpoint: endpoint,
          interfaceApiKey: interfaceApiKey,
          requestData: requestData
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
      .then(response => {
        console.log(response.data)

        console.log(databaseNameKey)
        console.log(databaseSlugKey)        

        databaseData[databaseNameKey] = response.data.name
        databaseData[databaseSlugKey] = response.data.slug

        console.log(databaseData) 

        localStorage.setItem('name', response.data.name) 
        localStorage.setItem('slug', response.data.slug) 

        setName(response.data.name)
        setSlug(response.data.slug)
      })
      .catch(error => {
        console.error(error)
      })

      await axios.put(BACKEND_DOMAIN + '/servicos/' + serviceId, databaseData, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response)     
      })
      .catch(error => {
        console.error(error)
      })

      setAddNewTemplate(false)
      setShowSaveButton(false)
    }
  }   


/**
 * @function updateTemplate
 * 
 * CALLED BY:
 * - Save button is clicked.
 */
  const updateTemplate = async (key) => {
    console.log("UPDATE TEMPLATE")

    if (interfaceApiKey) {    
      const requestData = {     
        name: slug,
        subject: subject,
        code: body,
        labels: labels
      }

      console.log(requestData)

      const databaseData = {}

      console.log(databaseSlugKey)
      console.log(databaseNameKey)

      databaseData[databaseSlugKey] = slug
      databaseData[databaseNameKey] = name

      console.log(databaseData)

      try {
      //Updates template data      
        await axios.post(BACKEND_DOMAIN + "/emails/updatetemplate", {
          endpoint: endpoint,
          interfaceApiKey: interfaceApiKey,
          requestData: requestData
        }, {
          headers: {
            Authorization: `Bearer ${key}`,
          }
        })
          .then(response => {
            console.log(response.data)
            console.log(slug)        
            
            localStorage.setItem('slug', slug)  

            renderTemplate(slug)    
          })
          .catch(error => {
            console.error(error)
          })
      } catch(error) {
        console.error(error)
      }

    // Updates template to be used
      await axios.put(BACKEND_DOMAIN + '/servicos/' + serviceId, databaseData, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response)                
        })
        .catch(error => {
          console.error(error)
        })    

      setShowSaveButton(false)
    }    
  }   


/**
 * @function deleteTemplate - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const deleteTemplate = async (key, deleteName, dbSlugKey, dbNameKey) => {
    setIsOpen(false)    

    const requestData = {
      name: deleteName,
    }

    const databaseData = {}

    databaseData[dbSlugKey] = ''
    databaseData[dbNameKey] = ''

    console.log(databaseData)     

    if (interfaceApiKey) {
      await axios.post(BACKEND_DOMAIN + "/emails/deletetemplate", {
        endpoint: endpoint,
        interfaceApiKey: interfaceApiKey,
        requestData: requestData
      }, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        console.log(response.data) 
        console.log(databaseData)  

        getTemplates(token)
        setSubject('')
        setBody('')
        setName('')
        setSlug('')    

        console.log(databaseData)        

        axios.put(BACKEND_DOMAIN+'/servicos/'+serviceId, databaseData, {
          headers: {
            Authorization: `Bearer ${key}`,
          },
        })
        .then(response => {
          console.log(response)     
        })
        .catch(error => {
          console.error(error)
        })
      }) 
      .catch(error => {
        console.error(error)
      })

      setShowSaveButton(false)
    }
  }


/**
 * @function renderTemplate - Render template html.
 * 
 * TO IMPROVE:
 * @todo Check ideal value/labels fields.
 * @todo Check possibility to refactor output into one unique state 
 *   (setLanguageOption + setSelectedLanguages)
 */
  const renderTemplate = async () => {    
    console.log(interfaceApiKey)

    const requestData = {
      template_name: slug,
      template_content: templateContent,
    }

    if (interfaceApiKey) {
      await axios.post(BACKEND_DOMAIN + "/emails/rendertemplate", {
        endpoint: endpoint,
        interfaceApiKey: interfaceApiKey,
        requestData: requestData
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(response => {
        console.log(response.data.html)
        
        setBodyRender(response.data.html)
      })
      .catch(error => {
        console.error(error)
      })
    }
  }   

/**
 * @function toggleModal
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */ 
  const toggleModal = (usedModal) => {
    setModal(usedModal)

    setIsOpen(!isOpen)
  }        

	return (

    <Section>
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper> 
          <Container>
	          <BackButton onClick={() => history.push('/service/' + serviceId + '/management-email')}><IconSVG src={arrowLeft} />Back</BackButton>          
	        	
            {console.log(title)}

            <DivHeader>
	            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
	              {title}
	            </H3>
	          </DivHeader>	
            <div 
              style={{
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'space-between' 
              }}
            >
              <H5 font='SFRegular' style={{ fontWeight: 'normal',   whiteSpace: 'nowrap', marginRight:'40px' }}>
                Selected Template:
              </H5>

              {console.log(templates)}
              {console.log(templatesData)}
              {console.log(name)}

              <Dropdown
                placeholder='Mailchimp Template'
                name='Template'
                classNamePrefix='selectBox'
                defaultValue={templates.filter(data => data.value == slug) ?
                  templates.filter(data => data.value == slug) :
                  ''}
                options={templates}
                value={templates.filter(data => data.value == slug) ?
                  templates.filter(data => data.value == slug) :
                  ''}
                onChange={handleTemplateChange}
              />
              <ButtonAdd onClick={() => {
                setAddNewTemplate(true)
                setShowSaveButton(true)
              }}>
                Create New
              </ButtonAdd>              
              <ButtonDelete onClick={() => toggleModal('delete')}>
                <Icon src={iconDelete} />Delete
              </ButtonDelete>
            </div>
            <Margin margin={3} />
            {addNewTemplate == true && 
              <div 
                style={{
                  display: 'flex', 
                  flexDirection: 'row', 
                  alignItems: 'center', 
                  justifyContent: 'space-between' 
                }}
              >
                <H5 font='SFRegular' style={{ fontWeight: 'normal',   whiteSpace: 'nowrap', marginRight:'20px' }}>
                  New Template Name:
                </H5>            
                <TextInput
                  placeholder='Type the new template name here'
                  value={newTemplateName}
                  maxLength={250}
                  onChange={(event) => setNewTemplateName(event.target.value)}
                  style={{maxWidth:'590px', marginRight:'auto'}}
                />
              </div> 
            }
            <Margin margin={4} />            
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
                Template Editor:
              </H5>
            </div>
            <Margin margin={3} />
            <DivHtmlEditor> 
              {showSaveButton == true &&           
                <ButtonAllReser 
                  onClick={() => saveTemplate()}
                >
                  Save
                </ButtonAllReser>
              }
              <ButtonAllReser onClick={() => setInstructions(true)} style={{marginLeft:'auto'}}>
                Instructions for Email
              </ButtonAllReser>              
            </DivHtmlEditor>           
            {templatesData[0] != undefined &&
              <Cards>
                <CardHtml>

                  {console.log(name)}
                  {console.log(slug)}                  
                  {console.log(subject)}
                  {console.log(body)} 
                  {console.log(templatesData)}    

                  <DivTitleEmail>
                    <TitleEmail
                      dangerouslySetInnerHTML={{ __html: 'Email Subject' }}
                    />
                    <TextInput
                      placeholder='Type the subject email here'
                      value={templatesData.filter(data => data.slug == slug)[0] && subject === null ? templatesData.filter(data => data.slug == slug)[0].subject : subject}
                      onChange={(event) => {
                        setSubject(event.target.value)
                        setShowSaveButton(true)
                      }}
                    />
                  </DivTitleEmail>
                  <SunEditor
                    setOptions={{
                      buttonList: settingsEmail,
                    }}
                    defaultValue={templatesData.filter(data => data.slug == slug)[0] ? templatesData.filter(data => data.slug == slug)[0].code : ''}
                    setContents={body}
                    onChange={(event) => {
                      setBody(event)
                      setShowSaveButton(true)
                    }}
                  />
                </CardHtml>
                <CardHtmlRender class="se-wrapper-inner se-wrapper-wysiwyg">
                  <div class="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable" dangerouslySetInnerHTML={{ __html: bodyRender }} />
                </CardHtmlRender>
              </Cards>              
            }              
            <Margin margin={2} />                               	
          </Container>

          {console.log(isOpen)}

          {isOpen && modal == 'delete' &&
            <ModalDelete
              isOpen={isOpen}
              name={name}
              token={token}
              databaseSlugKey={databaseSlugKey}
              databaseNameKey={databaseNameKey}
              deleteTemplate={deleteTemplate}
              toggleModal={toggleModal}
            />
          }
          <ModalEmail
            isOpen={instructions}
            setInstructions={setInstructions}
          />          
        </Wrapper>
      }
    </Section>		
	)
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */
const BackButton = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:114px;
  padding:12px 24px;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  background: #EBEBEB;
  transition: all 0.5s ease;
  :hover{
    background: #A9A9A9;
    cursor:pointer;
  }
`;
const IconSVG = styled.img`
  width:5px;
  height:9px;
`;

const DivHeader = styled.div`
  display:flex;
  justify-content:space-between;
  margin:24px 0;
  @media screen and (max-width:570px){
    flex-flow:column;
    align-items:flex-start;
  }
`;
const DivCards = styled.div`
  display:flex;
  flex-flow:column;
  margin-bottom: 20px;
  :last-child {
    margin-bottom:0;
  }
`;
const Cards = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-top:16px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
  box-sizing: border-box;
`;
const Card = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: 274px;
  min-height: 215px;
  box-sizing: border-box;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;
const DivReservation = styled.div`
  margin: 0px -24px -24px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const CardHtml = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: calc(50% - 20px);
  height: 100%;
  min-height:600px;
  flex-flow: column;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  .sun-editor{
    min-height:500px;
    .se-container{
      min-height:500px;
      display: flex;
      flex-flow: column;
    }
    .se-wrapper{
      flex:1;
      .se-wrapper-wysiwyg{
        min-height:500px;
      }
    }
  }
  .htmlEditor{
    border:unset;
    font-family: SFRegular;
    margin-top:-8px;
  }
  .htmlEditor-toolbar{
    border:unset;
    font-family: SFRegular;
    margin:0;
    margin-top:8px;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    border-bottom:1px solid #EBEBEB;
  }
  .htmlEditor-editor{
    font-family: SFRegular;
  }

  @media only screen and (max-width: 720px) {
    width: calc(100% - 20px);
  }
`;
const CardHtmlRender = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: calc(50% - 20px);
  height: 100%;
  min-height:790px;
  flex-flow: column;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  .sun-editor{
    min-height:500px;
    .se-container{
      min-height:500px;
      display: flex;
      flex-flow: column;
    }
    .se-wrapper{
      flex:1;
      .se-wrapper-wysiwyg{
        min-height:500px;
      }
    }
  }
  .htmlEditor{
    border:unset;
    font-family: SFRegular;
    margin-top:-8px;
  }
  .htmlEditor-toolbar{
    border:unset;
    font-family: SFRegular;
    margin:0;
    margin-top:8px;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    border-bottom:1px solid #EBEBEB;
  }
  .htmlEditor-editor{
    font-family: SFRegular;
  }

  @media only screen and (max-width: 720px) {
    width: calc(100% - 20px);
  }
`;
const DivHtmlEditor = styled.div`
  margin: -24px -24px 0px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const DivTitleHtmlEditor = styled.div`
  display:flex;
  flex-flow:column;
`;
const DivTitleEmail = styled.div`
  padding:8px 24px;
  margin: 0 -24px;
  border-bottom:1px solid #EBEBEB;
  margin-bottom:8px;
  display:flex;
  flex-flow:column;
`;
const TitleEmail = styled(Text11)`
  margin-bottom:8px;
`;
const TextInput = styled.input.attrs(props => ({
  type: "text"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;
const TitleReservation = styled(Text11)`
  color: #A9A9A9;
  text-transform: uppercase;
`;
const DateReservation = styled(Text11)`
`;
const DivTextCardReservation = styled.div`
  display:flex;
  flex-flow:column;
`;
const NumberReservation = styled(H3)`
  font-weight: normal;
`;
const LabelReservation = styled(Text12)`
  margin-bottom:24px;
  font-weight: normal;
`;

const ButtonAllReser = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;

const Toggle = styled(Switch)`
  .react-switch-bg{
    background-color:#fff !important;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
  }
`;

const ContainerInterval = styled.div`
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  border-bottom: unset;
  padding: 24px;
  max-width:568px;
  box-sizing: border-box;
  display:flex; 
  flex-direction:column;
`;

const DivInputsInterval = styled.div`
  display:flex;
  flex-flow:row;
`;

const NumberInput = styled.input.attrs(props => ({
  type: "number"
}))`
  font-family: SFMedium;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width:100%;
  height:48px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
`;

const DivInterval = styled.div`
  width: calc(50% - 10px);
  margin-right:20px;
  display:flex;
  flex-flow:column;
  :last-child{
    margin-right: 0;
  }
`;

const ContainerSaveInterval = styled.div`
  max-width:568px;
  padding: 16px 24px;
  box-sizing:border-box;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  border-radius:6px;
  border-top:unset;
  border-top-left-radius:0;
  border-top-right-radius:0;
`;

const Dropdown = styled(Select)`
 width: ${({ width }) => width ? width : '100%'};
 font-family: SFMedium;
 div{
   border-color: #E4EBF0;
   min-height:36px;
 }
 .selectBox__control {
   font-style: normal;
   font-size: 14px;
   line-height: 16px;
   color: #4E5D6B;
   box-sizing: border-box;
   border: 1px solid #EBEBEB;
   border-radius: 2px;
   height:48px;
   .selectBox__value-container {
     border-radius: 0;
     align-items: center;
     padding:16px 24px;
     box-sizing: border-box;
     height: 48px;
     .selectBox__input {
       display: flex !important;
       align-items: center !important;
     }
     .selectBox__single-value {
       display: flex;
       align-items: center;
     }
     .selectBox__placeholder {
       display: flex;
       align-items: center;
       transition: all 0.5s ease;
       color: #A9A9A9;
     }
   }
   .selectBox__indicators {
     height:48px;
     width:48px;
     background: #F5F7F7;
     justify-content:center;
     .selectBox__indicator-separator {
       display: none;
     }
     .selectBox__dropdown-indicator {
       display:flex;
       align-items: center;
       svg {
         transition: all 400ms ease;
         transform: rotate(0);
       }
     }
     &:hover {
       background-color: #E4EBF0;    
     }
   }
   :hover{
     border-color:#EBEBEB;
   }
 }
 .selectBox__menu {
   border-radius: 0;
   margin-top: 4px;
   box-sizing: border-box;
   box-shadow: none;
   border: 1px solid #d8d8d8;
   z-index: 2;
   .selectBox__menu-list {
     background: #fff !important;
     margin:0;
     .selectBox__option {
       font-size: 14px;
       display: flex;
       align-items: center;
       &.selectBox__option--is-selected {
         background-color: #d8d8d8;
         color: #000;
       }
       &.selectBox__option--is-focused {
         background-color: #eaeaea;
         color: #000;
       }
       &:hover {
         cursor: pointer;
         background: #E4EBF0;
       }
     }
   }
 }
 .selectBox__control--is-focused {
   border-width: 1px;
   border-style: solid;
   border-color: #F5F7F7;
   box-shadow: none;
   &:hover {
     border-color: #F5F7F7;
   }
 }
 .selectBox__control--menu-is-open {
   .selectBox__indicators {
     .selectBox__dropdown-indicator {
       svg {
         transform: rotate(-180deg);
       }
     }
   }
 }
`;
const ButtonAdd = styled.div`
  margin-left: 20px;
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 55px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  box-sizing: border-box;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
  ${({ disabled }) => disabled == true && css`
    pointer-events: none;
    background: #A9A9A9;
    :hover{
      background: #A9A9A9;
      cursor: block;
    }
  `}
  @media screen and (max-width:570px){
    padding: 16px 40px;
    margin-top:8px;
  }
`;
const ButtonDelete = styled.div`
  margin-left: 20px;
  background: #EBEBEB;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #A9A9A9;
    cursor: pointer;
  }
`;
const Icon = styled.img`
  width:14px;
  height:14px;
  margin-right:8px;
`;
