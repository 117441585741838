/**
 * @file Defines Loading component
 */

import React from 'react';
import styled, { css } from 'styled-components';

/**
 * @component Loading
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * -
 */
export default function Loading(props) {

  const {
    isLoading,
    slotsDay,
    small
  } = props;

  return (
    <LoadingSection isLoading={isLoading} slotsDay={slotsDay} small={small}>
      <Align>
        <div className="loader"/>
      </Align>
    </LoadingSection>
  )
}

/**
 * @styles
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * -
 */

const Align = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
  height:100%;
`;
const LoadingSection = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  opacity:0;
  transition: all 0.2s ease-in-out;
  z-index: -1;

  ${({ isLoading }) => isLoading && css`
    opacity:1;
    z-index: 105;
  `}

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: ${({ small }) => small ? 2 : 10}px;
    position: relative;
    text-indent: -9999em;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-right: 2px solid rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    border-left: 2px solid #FFCE20;
    transform: translateZ(0);
    animation: load8 1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;