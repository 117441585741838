/* eslint-disable */

/**
 * @file Defines Table style
 */

import React from 'react';
import styled, { css } from "styled-components";


/**
 * @component Table
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * -
 */
export default function Table(props) {
  return (
    <Tabela>
      {props.children}
    </Tabela>
  )
}

/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */
 
const Tabela = styled.table`
  overflow: scroll;
  width:100%;  
  border: 1px solid #E4EBF0;
  border-radius: 2px;
  margin:0 auto;
  border-spacing:0;
  white-space: nowrap;
  font-family:SFRegular;
  tbody>tr:nth-child(odd){
    background-color:#fff;
  }  
  tbody>tr:nth-child(even){
    background-color:#F9F9F9;
  }
  /*tbody:nth-child(odd)>tr{
    background-color:#fff;
  }  
  tbody:nth-child(even)>tr{
    background-color:#F9F9F9;
  }*/
  thead tr{
    background-color:#F9F9F9;
    th{
      padding: 16px 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #4E5D6B;
      opacity: 0.5;
      text-align:center;
    }
  }
  thead {
    border-top: 2px solid #DCDCDC;
    border-bottom: 2px solid #DCDCDC; 
  }  
  tbody tr {
    td{
      padding:24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #262F38;
      text-align:center;
      &.buttons{
        display:flex;
      }
    }
  }
`;