/* eslint-disable */

/**
 * @file Renders Home tab in backoffice
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Switch from "react-switch";

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H3,
  H5,
  H6,
  Text14,
  Text12,
  Text11,
  Loading
} from '../atoms';

import {
  Modal,
} from '../molecules';

/**
 * Manages rendering of reservations tab in backoffice
 * 
 * @component Home
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - ...  
 * 
 * BUGS:
 * - ...
 * 
 * TO IMPROVE:
 * @todo Complete @param comments.
 * @todo Review and standardize variable names:
 *   @todo Make sure all internal variables are in english
 *   @todo Decide if database variables should be in English or Portuguese and standardize
 *   @todo Add descriptive comments to all potentially ambiguous variables
 */
export default function Home(props) {


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [error, setError] = useState(false);
  const [user, setUser] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const [loading, setLoading] = useState(false);
  const [loaginService, setLoadingService] = useState(false);
  const [token, setToken] = useState('');
  const [services, setServices] = useState([]);
  const [ticketOffice, setTicketOffice] = useState({});
  const [numReserToday, setNumReserToday] = useState(0);
  const [numReserTom, setNumReserTom] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});  


/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  let history = useHistory();

  useEffect(() => {
    getCredentials();
  }, []);


/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */  
  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;

  let today = new Date();
  let tomorrow = new Date()
  tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);

  const idBilheteira = sessionStorage.getItem("bilheteira");




/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates n the form of @state in function header comments?
 */

 /**
 * @function getCredentials
 *
 * - Retrieves and sets token for backend access
 * - Calls function to get toggle buttons state from Strapi
 * - Calls function to get email configuration from Strapi 
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token');
    let tempUser = sessionStorage.getItem('username');
    let tempUserProfile = sessionStorage.getItem('user-profile');

    setUser(tempUser);
    setUserProfile(tempUserProfile);
    setToken(temp);
    getData(temp, tempUser);
    getReservations(temp);
  }

/**
 * @function getData
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const getData = async (key, user) => {
    console.log('IDBilheteira: ' + idBilheteira) 
    console.log(key)

    setLoading(true);

    await axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + idBilheteira + "/comEpoca" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)
        console.log(user)
        console.log(userProfile)

        setLoading(false);
        setDataTicketService(key, response, user);
      })
      .catch(error => {
        setLoading(false);
        setError(true);
      });
  }


/**
 * @function setDataTicketService
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const setDataTicketService = async (key, response, user) => {
    console.log('RESPONSE:')
    console.log (response.data)
    console.log(user)

    await axios
      .get(BACKEND_DOMAIN + "/users/", {
        params: {
          bilheteira: idBilheteira,
          username: user 
        }
      },{
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)

        setLoading(false);

        const userServices = response.data[0].servicos

        console.log('SERVICES:')
        console.log(userServices)

        setServices(userServices)
      })
      .catch(error => {
        console.error(error)

        setLoading(false);
        setError(true);
      });

    let tempServices = [];

    if (response.data.users) {
      for (let i = 0; i < response.data.users.length; i++) {
        if (response.data.users[i].username === user) {
          for (let j = 0; j < response.data.servicos.length; j++) {
            tempServices.push(response.data.servicos[j]);
          }
        }
      }
    }

    setTicketOffice({
      id: response.data.id,
      name: response.data.nome,
      description: response.data.descricao,
      url: response.data.url,
      email: response.data.email,
      color: response.data.cor,
      Pagamentos: response.data.Pagamentos,
      stripe_api_key: response.data.stripe_api_key
    })

    console.log(tempServices)
  }

  /*const getProfileName = async (key, profileID) => {

    console.log(profileID);

    setLoading(true);
    
    await axios
      .get(BACKEND_DOMAIN + "/user-profiles/"+profileID, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response)
        setLoading(false);

        setUserProfile(response.data.permissionsLevel);
      })
      .catch(error => {
        setLoading(false);
        setError(true);
      });    
  }*/


/**
 * @function getReservations
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const getReservations = (key) => {
    setLoading(true)

    let data = {
      servico: undefined,
      dataInicio: moment(today).format('YYYY-MM-DD'),
      dataFim: moment(tomorrow).format('YYYY-MM-DD')
    }
    
    axios
      .post(BACKEND_DOMAIN + "/bilheteiras/" + idBilheteira + "/horario" + '?_limit=-1', data, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)

        getNumReser(response.data)
        setLoading(false)
      })
      .catch(error => {
        setError(true)
        setLoading(false)
      })
  }


/**
 * @function getNumReser
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const getNumReser = (schedule) => {
    console.log(schedule)

    let tempToday = 0
    let tempTomorrow = 0

    if (schedule.result?.length > 0) {
      for (let i = 0; i < schedule.result.length; i++) {
        if (schedule.result[i].data === moment(today).format('YYYY-MM-DD')) {
          if (schedule.result[i].reservas?.length > 0) {
            for (let u = 0; u < schedule.result[i].reservas.length; u++) {
              tempToday = tempToday + 1;
            }
          }
        } else if (schedule.result[i].data === moment(tomorrow).format('YYYY-MM-DD')) {
          for (let u = 0; u < schedule.result[i].reservas.length; u++) {
            tempTomorrow = tempTomorrow + 1;
          }
        }
      }


      // for (let i = 0; i < reservations.length; i++) {
      //   if (moment(reservations[i].data).format('DD MMM YYYY') === moment(today).format('DD MMM YYYY')) {
      //     tempToday = tempToday + 1;
      //   }
      //   if (moment(reservations[i].data).format('DD MMM YYYY') === moment(tomorrow).format('DD MMM YYYY')) {
      //     tempTomorrow = tempTomorrow + 1;
      //   }
      // }
      setNumReserToday(tempToday);
      setNumReserTom(tempTomorrow)
    }
  }


/**
 * @function reserIndiv
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const reserIndiv = (date) => {
    let tempDate = moment(date).format('YYYY-MM-DD');
    history.push({
      pathname: '/schedule',
      data: tempDate
    });
  }


/**
 * @function seeService
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const seeService = (service) => {
    history.push({
      pathname: 'service/' + service.id + '/management',
      id: service.id
    });
  }

/**
 * @function changeAvailability
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const changeAvailability = (data) => {
    setLoadingService(true);
    axios
      .put(BACKEND_DOMAIN + "/servicos/" + data.id,
        {
          ativo: !data.ativo
        }, tokenRequest
      )
      .then(response => {
        setServices(services.map(x => {
          if (x.id !== data.id) return x
          return { ...x, ativo: !data.ativo }
        }))

        setLoadingService(false)
      })
      .catch(error => {
        setError(true)
        setLoadingService(false)
      });
  }

/**
 * @function passData
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const passData = (data, type) => {
    setDataModal(
      {
        content: data,
        typeContent: type,
        token: token,
      }
    )
    setTimeout(toogleModal(), 500)
  };

/**
 * @function detailPage
 * 
 * @param ...
 * 
 * @returns ...
 * 
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const detailPage = () => {
    history.push({
      pathname: '/home/invoices',
    });
  }

/**
 * @function toogleModal
 *
 * CALLED BY:
 * - ...
 *
 * TO IMPROVE:
 * ...
 */
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getCredentials();
  }, []);

  useEffect(() => {
    let tokenRequest = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }, []);

  /*useEffect(() => {
    getProfileName()
  }, [user])*/

  return (
    <Section>
      {console.log(user)}
      {console.log(userProfile)}
      
      {loading == true && <Loading isLoading={loading} />}
      {loading == false &&
        <Wrapper>
          <Container>
            {/* {ticketOffice.length > 0 && */}          
            { userProfile == ('Administrator' || 'Manager') &&   
              <>
                <DivCards>
                  <H5
                    font='SFRegular'
                    style={{ fontWeight: 'normal' }}
                  >
                    Ticket Office
                  </H5>
                  <Cards>
                    <Card>
                      <H6>
                        {ticketOffice.name}
                      </H6>
                      <Margin margin={1} />
                      <Text14 font='SFRegular'>
                        {ticketOffice.description}
                      </Text14>
                      <Margin margin={2} />
                      <Text14 font='SFRegular'>
                        {ticketOffice.url}
                      </Text14>
                      <Margin margin={1} />
                      <Text14 font='SFRegular'>
                        {ticketOffice.email}
                      </Text14>
                      <Margin margin={2} />
                      <ButtonAllReser onClick={() => passData(ticketOffice, 'editTicketOffice')} style={{ width: '82px', boxSizing: 'border-box' }}>
                        Edit
                      </ButtonAllReser>
                    </Card> 
                  </Cards>
                </DivCards>
              </>
            }
            <DivCards>
              <H5
                font='SFRegular'
                style={{ fontWeight: 'normal' }}
              >
                Schedule
              </H5>
              <Cards>
                <Card reservations={true}>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Today
                      </TitleReservation>
                      <DateReservation color='#8B8B8B'>
                        {moment(today).format('DD MMM YYYY')}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => reserIndiv(today)}>
                      See all
                    </ButtonAllReser>
                  </DivReservation>
                  <NumberReservation>
                    {numReserToday}
                  </NumberReservation>
                </Card>
                <Card reservations={true}>
                  <DivReservation>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TitleReservation>
                        Tomorrow
                      </TitleReservation>
                      <DateReservation color='#8B8B8B'>
                        {moment(tomorrow).format('DD MMM YYYY')}
                      </DateReservation>
                    </div>
                    <ButtonAllReser onClick={() => reserIndiv(tomorrow)}>
                      See all
                    </ButtonAllReser>
                  </DivReservation>
                  <NumberReservation>
                    {numReserTom}
                  </NumberReservation>
                </Card>
              </Cards>
            </DivCards>

            {console.log(userProfile)}

            { (userProfile == 'Administrator' ||
              userProfile == 'Manager') &&   
              <>     
                <H5
                  font='SFRegular'
                  style={{ fontWeight: 'normal' }}
                >
                  Services
                </H5>
                { userProfile == 'Administrator' &&
                  <ButtonAdd onClick={() => passData('', 'addService')}>
                    Create new
                  </ButtonAdd>
                }
                {services.length > 0 &&
                  <DivCards>
                    <Cards>
                      {services.map((service, index) => (
                        <Card key={String(index)} style={{ height: '310px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <H6>
                              {service.nome}
                            </H6>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
                              {loaginService && <Loading isLoading={loaginService} small={true} />}
                              <TextActive
                                color={service.ativo ? '#373737' : '#EBEBEB'}
                              >
                                Active
                              </TextActive>
                            </div>
                          </div>
                          <Margin margin={2} />
                          <Text14 font='SFRegular' style={{ height: '96px' }}>
                            {service.descricao}
                          </Text14>
                          <Margin margin={2} />
                          <DivDataServices>
                            {/*
                            <div>
                              <Text11 color='#A9A9A9' style={{ textTransform: 'uppercase' }}>
                                Price
                              </Text11>
                              <Margin margin={1} />

                              {service.precoUnitario === null &&
                                <H6>
                                  - €
                                </H6>
                              }        
                              {service.precoUnitario !== null &&
                                <H6>
                                  {service.precoUnitario}€
                                </H6>
                              }
                              
                            </div>
                            */}
                            <div>
                              <Text11 color='#A9A9A9' style={{ textTransform: 'uppercase' }}>
                                Duration
                              </Text11>
                              <Margin margin={1} />
                              <H6>
                                {service.duracao}min
                              </H6>
                            </div>
                            <div>
                              <Text11 color='#A9A9A9' style={{ textTransform: 'uppercase', marginLeft: '40px' }}>
                                  Interval
                              </Text11 >
                                <Margin margin={1} />
                              <H6 style={{ marginLeft: '40px' }}>
                                  {service.intervalo}min
                              </H6>
                            </div>
                          </DivDataServices>
                          <Margin margin={3} />
                          <div >
                            <ButtonServiceView onClick={() => seeService(service)}  style={{ display: 'inline', marginRight: '10px'}}>
                              Management
                            </ButtonServiceView>
                            <ButtonAllReser onClick={() => passData(service, 'editService')} style={{ display: 'inline', marginRight: '10px', width: '82px', boxSizing: 'border-box' }}>
                              Edit
                            </ButtonAllReser>   
                          </div>             
                        </Card>
                      ))}
                    </Cards>
                  </DivCards>
                }
              </>
            }                
          </Container>
          { isOpen == true &&
            <Modal
              isOpen={isOpen}
              {...dataModal}
              toogleModal={toogleModal}
              setTicketOffice={setTicketOffice}
              ticketOffice={ticketOffice}
              idBilheteira={idBilheteira}
              user={user}
              setUser={setUser}
              setUserProfile={setUserProfile}
              userProfile={userProfile}
              homeServices={services}  
              setHomeServices={setServices}            
              BACKEND_DOMAIN={BACKEND_DOMAIN}
            />
          }
        </Wrapper>
      }
    </Section>
  );
}


/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const DivCards = styled.div`
  display:flex;
  flex-flow:column;
  margin-bottom: 32px;
  :last-child {
    margin-bottom:0;
  }
`;
const DivTextCardReservation = styled.div`
  display:flex;
  flex-flow:column;
`;
const LabelReservation = styled(Text12)`
  margin-bottom:24px;
  font-weight: normal;
`;
const Cards = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-top:16px;
  margin-left: -10px;
  margin-right: -10px;
  box-sizing: border-box;
`;
const Card = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  width: ${({ reservations }) => reservations ? 274 : 372}px;
  height: 100%;
  flex-flow: column;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;
const Toggle = styled(Switch)`
  .react-switch-bg{
    background-color:#fff !important;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
  }
`;
const TextActive = styled(Text11)`
  margin-left: calc(4px + (10 - 4) * ((100vw - 375px) / (1920 - 375)));
  text-transform: uppercase;
`;
const DivDataServices = styled.div`
  display:flex;
  justify-content:left;
  flex-flow: row;
  div {
    display:flex;
    flex-flow: column;
  }
`;
const DivReservation = styled.div`
  margin: -24px -24px 16px -24px;
  padding: 16px 24px 16px 24px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F9F9F9;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const TitleReservation = styled(Text11)`
`;
const DateReservation = styled(Text11)`
`;
const NumberReservation = styled(H3)`
`;
const ButtonAdd = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 16px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #373737;
  transition: all 0.4s ease;
  height:48px;
  box-sizing: border-box;
  max-width: 372px;
  margin: 20px 0 10px;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
  @media screen and (max-width:570px){
    padding: 16px 30px;
    margin-top:8px;
  }
`;
const ButtonAllReser = styled.div`
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;
const ButtonServiceView = styled.div`
  width:162px;
  box-sizing: border-box;
  background: #FFCE20;
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #373737;
  transition: all 0.4s ease;
  :hover{
    background: #F8C100;
    cursor: pointer;
  }
`;