/* eslint-disable */

/**
 * @file Renders modal informing about success of user save input data operation
 */

import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { createGlobalStyle } from 'styled-components';

import {
  H5,
  Text14,
  Margin,
} from '../../atoms';


/**
 * Manages rendering of ModalDelete
 * 
 * @component ModalDelete
 *
 * @param ...
 * ...
 *
 * CALLED BY:
 * - management.js  
 * 
 * BUGS:
 * - ...
 * 
 */

export default function ModalDelete(props) {

  const {
    isOpen,
    name,
    token,
    databaseSlugKey,
    databaseNameKey,
    deleteTemplate,
    toggleModal,
  } = props;


  return (   
    <DivModal isOpen={isOpen}>

      {console.log("MODAL DELETE")} 
      {console.log(databaseSlugKey)}

      <Content>
        <Container>
          <H5> Delete </H5>
          <Margin margin={4} />
          <Text14 font='SFRegular' style={{ fontWeight: 'normal' }}>Are you sure you want to delete the template <b>'{name}'</b>?</Text14>
        </Container>
        <Margin margin={5} />
        <DivButtons>
          <ButtonCancel onClick={() => toggleModal('delete')}>Cancel</ButtonCancel>
          <ButtonDelete onClick={() => deleteTemplate(token, name, databaseSlugKey, databaseNameKey)}>Delete</ButtonDelete>
        </DivButtons>
      </Content>
      {isOpen && <Global />}
    </DivModal>
  )
}

/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const Global = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;
const DivModal = styled.div`
  background: rgba(78, 93, 107, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  opacity:1;
  z-index:999;
`;

const Content = styled.div`
  position:relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 32px 40px;
  display: flex;
  flex-flow: column;
  width: 372px;
  max-height: 95%;
  overflow-y: auto;
  @media screen and (max-width:570px){
    width:350px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivNotification = styled.div`
  display:flex;
  flex-flow:row;
  min-height:200px;
  align-items:center;
  justify-content:center;
`;
const Icon = styled.span`
  margin-right:8px;
`;
const DivButtons = styled.div`
 display: flex;
 flex-flow:row;
 width:100%;
`;
const ButtonDelete = styled.div`
 margin-left: 20px;
 background: #FFCE20;
 border-radius: 2px;
 padding: 8px 24px;
 display: flex;
 align-items: center;
 justify-content: center;
 font-family: SFRegular;
 font-weight: 500;
 font-size: 12px;
 line-height: 24px;
 color: #373737;
 transition: all 0.4s ease;
 width: calc(50% - 10px);
 :hover{
   background: #F8C100;
   cursor: pointer;
 }
`;
const ButtonCancel = styled.div`
 background: #EBEBEB;
 border-radius: 2px;
 padding: 8px 24px;
 display: flex;
 align-items: center;
 justify-content: center;
 font-family: SFRegular;
 font-weight: 500;
 font-size: 12px;
 line-height: 24px;
 color: #373737;
 transition: all 0.4s ease;
 width: calc(50% - 10px);
 :hover{
   background: #A9A9A9;
   cursor: pointer;
 }
`;
