/**
 * @file Defines Text10 style
 */

import styled, { css } from "styled-components";

const Text10 = styled.div`
  font-family: ${({ font }) => font ? font : 'SFSemiBold'};
  font-size: 10px;
  line-height:${({ height }) => height ? 24 : 16}px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : '#373737'};
  column-count:${({ withCols }) => withCols ? 2 : 1};

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  @media screen and (max-width: 768px) {
    column-count:1;
  }
`;

export default Text10;